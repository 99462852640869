import Axios from "axios";
import router from "../router";

import { SESSION_STORAGE_KEY as JWT_KEY } from "@/services/jwt.service.js";
import { LOGIN_ROUTE } from "@/router/names";

const baseURL = `${location.origin}/campus/api/v1`;

const Api = Axios.create({
  baseURL,
  timeout: 12000
});

Api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem(JWT_KEY)}`;
  return config;
});

Api.interceptors.response.use(
  response => response.data,
  async err => {
    const { status, config } = (err && err.response) || {};
    if (status === 401 && !config.url.endsWith("/login")) {
      await router.push({ name: LOGIN_ROUTE });
    }

    throw err;
  }
);

export { Api };
