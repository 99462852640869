<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Основная информация</v-tab>
      <v-tab>Идентификаторы</v-tab>
      <v-tab>
        Управление доступом
      </v-tab>
      <v-tab>
        Личный кабинет
      </v-tab>
      <v-tab>
        События СКУД
      </v-tab>
      <v-tab>
        Метки
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="py-4">
        <general-information
          v-if="Object.keys(user).length"
          :user="user"
          :category-list="categoryList"
          :user-management="userManagement"
        />
      </v-tab-item>

      <v-tab-item class="pa-4">
        <identifiers-information
          v-if="Object.keys(user).length"
          :identifiers="user.identifiers"
          :operations="operations"
          @update="loadData"
        />
      </v-tab-item>

      <v-tab-item class="py-4">
        <access-information
          v-if="Object.keys(user).length"
          :user="user"
          :category-list="categoryList"
        />
      </v-tab-item>

      <v-tab-item class="py-4">
        <user-lk v-if="Object.keys(user).length" :user="user" />
      </v-tab-item>

      <v-tab-item class="py-4">
        <access-event-information :userId="user.id" />
      </v-tab-item>

      <v-tab-item class="py-4">
        <user-tags v-if="Object.keys(user).length" :user="user" />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import {
  getAcsEvent,
  getUserById,
  getUserIdentifierOperation,
  getUserManagement
} from "../../services/user.service";

import { getCategoryList } from "../../services/category.service";
import { session } from "@/services/session.service";

import Page from "../../components/Page";
import GeneralInformation from "../../components/user-management/UserInformation/GeneralInformation";
import IdentifiersInformation from "../../components/user-management/IdentifiersInformation/IdentifiersInformation";
import AccessInformation from "@/components/user-management/AccessInformation/AccessInformation";
import AccessEventInformation from "@/components/user-management/AccessEventInformation";
import UserTags from "../../components/user-management/UserInformation/UserTags";
import UserLk from "../../components/user-management/UserLK/UserLk";

export default {
  name: "UserEdit",
  components: {
    UserLk,
    UserTags,
    AccessEventInformation,
    AccessInformation,
    IdentifiersInformation,
    GeneralInformation,
    Page
  },

  data: () => ({
    tab: null,

    events: {},
    user: {},

    userManagement: [],
    categoryList: [],
    operations: [],

    session: session
  }),

  computed: {
    eventsExist() {
      return this.events.content ? !this.events.content.length : true;
    }
  },

  created() {
    this.getUserManagement();
    this.tab = Number(this.$route.query.tab) || 0;
  },

  async mounted() {
    await this.loadData();
    await this.getCategoryList();
  },

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });
    },

    "$route.params.id": {
      async handler() {
        await this.loadData();
      }
    }
  },

  methods: {
    async loadData() {
      const { userId } = this.$route.params;

      try {
        this.user = await getUserById(userId);
        this.operations = await getUserIdentifierOperation(userId);

        this.events = await getAcsEvent(userId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getUserManagement() {
      try {
        this.userManagement = await getUserManagement(
          this.$route.params.userId
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getCategoryList() {
      this.categoryList = [
        ...(await getCategoryList("PPS")),
        ...(await getCategoryList("STAFF")),
        ...(await getCategoryList("ENTRANT"))
      ];

      this.categoryList.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
    }
  }
};
</script>
