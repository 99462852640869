<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Подразделение</v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedGroup"
              :items="availableGroups"
              label="Группа"
              item-text="description"
              return-object
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="selected"
              :items="categories || categoryList"
              item-text="name"
              item-value="id"
              :label="label"
              hint="Внимание! При переводе пользователя в категорию, не относящуюся к Вашей модерации, управлять им Вы уже не сможете."
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row v-if="userGroupEntrant">
          <v-col cols="6">
            <v-select
              v-model="selectedCourse"
              dense
              :items="courseList"
              label="Курс"
              class="mt-7"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="pupilGroup"
              dense
              :items="groupListByCourse"
              :item-text="el => (el.liter ? el.liter : '-')"
              return-object
              label="Группа"
              class="mt-7"
              :disabled="!selectedCourse"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="update">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import {
  updateCategory,
  updatePupilGroup,
  getUserCategories
} from "@/services/user.service";
import {
  getCategoryByType,
  getPupilGroupList
} from "../../services/category.service";
import { getGroups } from "../../services/group.service";
import { goToUser } from "@/utils/router";
import { getUserById } from "../../services/user.service";
import { session } from "@/services/session.service";

export default {
  name: "DepartmentsInformation",
  props: {
    categoryList: { type: Array, default: () => [] },
    user: { type: Object, required: true }
  },

  data: () => ({
    categories: [],
    userCategories: [],
    selectedCategories: [],

    selectedCourse: null,
    pupilGroupList: [],
    pupilGroup: null,

    availableGroups: [],
    groups: [],
    selectedGroup: { name: "" }
  }),

  async mounted() {
    await this.getAvailableGroups();
    await this.getUserCategories();
  },

  computed: {
    selected: {
      get() {
        const mainCategory = this.userCategories.map(c => c.category.name);
        const userGroup = this.categories.find(
          el => el.name == mainCategory[0]
        );

        return userGroup ? userGroup.name : null;
      },

      set(value) {
        this.selectedCategories = [{ main: true, category: value }];
      }
    },

    courseList() {
      return Array.from(new Set(this.pupilGroupList.map(el => el.course)));
    },

    groupListByCourse() {
      return this.pupilGroupList.filter(i => i.course === this.selectedCourse);
    },

    userGroupEntrant() {
      return this.selectedGroup.name === "ENTRANT";
    },

    label() {
      return this.userGroupEntrant ? "Факультет" : "Подразделение";
    }
  },

  watch: {
    async selectedGroup() {
      await this.getCategoryByType();
    },

    selectedCategories() {
      if (this.userGroupEntrant) {
        this.getPupilGroupList();
      }
    },

    selectedCourse: {
      handler() {
        this.pupilGroup = this.groupListByCourse.find(
          el => el.id === this.pupilGroup?.id
        );
      }
    }
  },

  methods: {
    async getAvailableGroups() {
      this.groups = await getGroups();

      this.availableGroups = Array.from(
        new Set(session.user.management.map(el => el.category.type))
      ).map(el => (el = this.groups.find(g => g.name == el)));

      this.selectedGroup = this.groups.find(
        g => g.name == this.user.mainGroup.name
      );
    },

    async getPupilGroupList() {
      try {
        this.pupilGroupList = await getPupilGroupList({
          categoryId: this.selectedCategories[0].category.id
        });

        this.pupilGroup = this.user.pupilGroup;
        this.selectedCourse = this.user.pupilGroup?.course;
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getCategoryByType() {
      try {
        this.categories = await getCategoryByType(this.selectedGroup.name);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getUserCategories() {
      try {
        this.userCategories = await getUserCategories(this.user.id);
        this.selected = this.userCategories[0].category;
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async update() {
      const { userId } = this.$route.params;
      try {
        await updateCategory(userId, this.selectedCategories).then(() =>
          this.$notify({
            text: "Данные успешно обновлены",
            type: "success"
          })
        );

        if (this.userGroupEntrant) {
          await updatePupilGroup(userId, this.pupilGroup);
        }

        if (
          this.userCategories[0].category.type !==
          this.selectedCategories[0].category.type
        ) {
          const user = await getUserById(this.user.id);
          goToUser(user);
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
