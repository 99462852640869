<template>
  <v-navigation-drawer app v-model="show">
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Кампус
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <template v-for="[key, routes] in Object.entries(routes)">
        <template v-if="routes.length">
          <v-subheader :key="key">{{ key }}</v-subheader>
          <template v-for="route in routes">
            <v-list-item
              v-if="
                route.meta && route.meta.linked && isAllow('routes', route.name)
              "
              :key="route.name"
              link
              :to="{ name: route.name, params: { group: route.meta.type } }"
            >
              <v-list-item-icon v-if="route.meta.icon">
                <v-icon>{{ route.meta.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="route.meta.desc">
                  {{ route.meta.desc }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { groupBy, filter } from "lodash";

import { routes } from "../router";
import { isAllow } from "@/services/session.service";

export default {
  name: "AppSidebar",

  props: {
    value: { type: Boolean }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      }
    },

    routes() {
      return groupBy(
        filter(
          routes,
          route =>
            route.meta && route.meta.linked && isAllow("routes", route.name)
        ),
        route => route.meta.group
      );
    }
  },

  methods: {
    isAllow
  }
};
</script>

<style scoped></style>
