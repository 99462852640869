<template>
  <v-card outlined>
    <v-card-title>Изменение категории</v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="model.name"
              label="Название"
              autofocus
              required
              :rules="rules"
            />
          </v-col>
          <v-col cols="4">
            <v-select v-model="model.type" :items="types" label="Категория" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="update">Обновить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { updateCategory } from "@/services/category.service";

export default {
  name: "EditCategory",
  props: {
    value: { type: Boolean },
    category: { type: Object, required: true }
  },

  data() {
    return {
      valid: false,
      rules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 1) || "Минимальное число символов: 2"
      ],
      types: ["STAFF", "ENTRANT"],

      model: { name: "", type: "" }
    };
  },

  created() {
    this.model = { ...this.category };
  },

  methods: {
    async update() {
      try {
        await updateCategory(this.model).then(() =>
          this.$notify({
            text: "Данные успешно обновлены",
            type: "success"
          })
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
