<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="15"
    item-key="id"
  >
    <template v-slot:header.default="{ header }">
      <div class="d-flex align-center">
        <v-simple-checkbox
          @input="onAllDefault"
          :value="defaultCount === items.length"
          :indeterminate="isIndeterminate(defaultCount)"
          :disabled="disabled"
          :ripple="false"
        ></v-simple-checkbox>
        {{ header.text }}
      </div>
    </template>
    <template v-slot:header.granted="{ header }">
      <div class="d-flex align-center">
        <v-simple-checkbox
          @input="onAllGranted"
          :value="grantedCount === items.length"
          :indeterminate="isIndeterminate(grantedCount)"
          :disabled="disabled"
          :ripple="false"
        ></v-simple-checkbox>
        {{ header.text }}
      </div>
    </template>
    <template v-slot:header.denied="{ header }">
      <div class="d-flex align-center">
        <v-simple-checkbox
          @input="onAllDenied"
          :value="deniedCount === items.length"
          :indeterminate="isIndeterminate(deniedCount)"
          :disabled="disabled"
          :ripple="false"
        ></v-simple-checkbox>
        {{ header.text }}
      </div>
    </template>

    <template v-slot:item.default="{ item }">
      <v-simple-checkbox
        :ripple="false"
        :value="!getRule(item)"
        :disabled="disabled"
        @click="!!getRule(item) && setAccess(item, null)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.granted="{ item }">
      <v-simple-checkbox
        :ripple="false"
        :value="granted(item)"
        :disabled="disabled"
        @click="!granted(item) && setAccess(item, true)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.denied="{ item }">
      <v-simple-checkbox
        :ripple="false"
        :value="denied(item)"
        :disabled="disabled"
        @click="!denied(item) && setAccess(item, false)"
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    value: Array,
    keys: Array,
    disabled: Boolean
  },

  data() {
    return {
      headers: [
        { text: "Ключ", value: "name" },
        { text: "Идентификатор", value: "code" },
        { text: "По умолчанию", value: "default", sortable: false },
        { text: "Разрешен", value: "granted", sortable: false },
        { text: "Запрещён", value: "denied", sortable: false }
      ],

      loading: false
    };
  },

  computed: {
    items() {
      return this.keys;
    },

    defaultCount() {
      return this.items.filter(el => !this.getRule(el)).length;
    },

    grantedCount() {
      return this.items.filter(el => this.granted(el)).length;
    },

    deniedCount() {
      return this.items.filter(el => this.denied(el)).length;
    }
  },

  methods: {
    onAllDefault() {
      this.setAccess(
        this.items.filter(el => this.getRule(el)),
        null
      );
    },

    onAllGranted() {
      this.setAccess(this.items, true);
    },

    onAllDenied() {
      this.setAccess(this.items, false);
    },

    isIndeterminate(count) {
      return count > 0 && count < this.items.length;
    },

    getRule(item) {
      return this.value.find(rule => rule.keyId === item.id);
    },

    granted(item) {
      const rule = this.getRule(item);
      return !!rule && rule.accessType;
    },

    denied(item) {
      const rule = this.getRule(item);
      return !!rule && !rule.accessType;
    },

    setAccess(items, accessType) {
      this.$emit(
        "set-access",
        [items].flat().map(el => el.id),
        accessType
      );
    }
  }
};
</script>
