var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"680"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":!!_vm.rule,"text":!_vm.rule,"color":"primary","disabled":_vm.disabled}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"left":!_vm.rule}},[_vm._v(_vm._s(_vm.rule ? "mdi-pencil" : "mdi-plus"))]),_vm._v(" "+_vm._s(_vm.rule ? "" : "Создать")+" ")],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',[_c('v-radio-group',{attrs:{"mandatory":"","label":"Точка доступа"},model:{value:(_vm.params.accessPointId),callback:function ($$v) {_vm.$set(_vm.params, "accessPointId", $$v)},expression:"params.accessPointId"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"mt-2 mr-3"},_vm._l((_vm.accessPoints.slice(
                0,
                Math.ceil(_vm.accessPoints.length / 2)
              )),function(point){return _c('v-radio',{key:point.id,attrs:{"label":point.name,"value":point.id}})}),1),_c('div',{staticClass:"mt-2"},_vm._l((_vm.accessPoints.slice(
                Math.ceil(_vm.accessPoints.length / 2)
              )),function(point){return _c('v-radio',{key:point.id,attrs:{"label":point.name,"value":point.id}})}),1)])]),_c('v-radio-group',{attrs:{"mandatory":"","label":"Срок действия"},model:{value:(_vm.validity),callback:function ($$v) {_vm.validity=$$v},expression:"validity"}},[_c('v-radio',{attrs:{"label":"Бессрочно","value":"infinity"}}),_c('v-radio',{attrs:{"label":"С указанием длительности","value":"expire"}}),_c('v-radio',{attrs:{"label":"Учебный год","value":"study_year"}})],1),(_vm.isWithExpire)?_c('div',{staticClass:"d-flex align-center"},[_c('v-date-picker',{staticClass:"mr-8",attrs:{"no-title":""},model:{value:(_vm.params.beginDate),callback:function ($$v) {_vm.$set(_vm.params, "beginDate", $$v)},expression:"params.beginDate"}}),_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.params.endDate),callback:function ($$v) {_vm.$set(_vm.params, "endDate", $$v)},expression:"params.endDate"}})],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","type":"error"},on:{"click":function($event){_vm.show = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.params.accessPointId},on:{"click":_vm.createRule}},[_vm._v(" "+_vm._s(!_vm.rule ? "Создать" : "Обновить")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }