import { render, staticRenderFns } from "./UsersGroupList.vue?vue&type=template&id=5c75a732&scoped=true&"
import script from "./UsersGroupList.vue?vue&type=script&lang=js&"
export * from "./UsersGroupList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c75a732",
  null
  
)

export default component.exports