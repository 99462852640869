<template>
  <page>
    <add-new-user />
  </page>
</template>

<script>
import Page from "../../components/Page";
import AddNewUser from "../../components/user-management/AdditionUser/AddNewUser";
export default {
  name: "UserAddition",
  components: { AddNewUser, Page }
};
</script>
