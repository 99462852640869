<template>
  <div>
    <v-row>
      <v-col cols="auto" class="ma-4">
        <user-avatar
          :user="user"
          @update-image="updateUserPhoto"
          @update-user="updateUser"
        />
      </v-col>

      <v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="userModel.surname" label="Фамилия" />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="userModel.name" label="Имя" />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="userModel.patronymic" label="Отчество" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="userModel.qrAccessType"
              :items="qrTypes"
              label="Доступ по QR-коду"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAvatar from "@/components/user-management/UserInformation/UserAvatar";
import { updateUserPhoto } from "@/services/user.service";
export default {
  name: "PersonalInfoForm",
  components: { UserAvatar },
  model: {
    event: "update",
    prop: "user"
  },

  props: {
    user: { type: Object, required: true }
  },

  data() {
    return {
      qrTypes: [
        { text: "По умолчанию", value: "default" },
        { text: "Отклонен", value: "denied" },
        { text: "Разрешен", value: "allowed" }
      ]
    };
  },

  computed: {
    userModel: {
      get() {
        return this.user;
      },

      set(newModel) {
        this.$emit("update", newModel);
      }
    }
  },

  methods: {
    updateUserPhoto(photo) {
      return updateUserPhoto(this.user.id, { photo });
    },

    updateUser(user) {
      this.$emit("update-user", user);
    }
  }
};
</script>
