<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Пропуска</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <pass-list />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PassList from "../../components/pass/PassList.vue";

export default {
  components: { PassList },

  data() {
    return {
      tab: null
    };
  }
};
</script>
