import { Api } from "./api.service";

export function createIdentifierStorage(code) {
  return Api.post("/identifier-store/identifier", { key: code });
}

export function createMultipleIdentifiersStorage(arrCodes) {
  return Api.post(
    "/identifier-store/identifiers",
    arrCodes.map(code => ({ key: code }))
  );
}

export function getListIdentifiersStorage({ search, date, page, pageSize }) {
  return Api.get("/identifier-store/identifiers", {
    params: {
      search: search || undefined,
      date: date || undefined,
      page,
      pageSize
    }
  });
}

export function setArchival(id, archival) {
  return Api.put(`/identifier-store/${id}/identifiers`, null, {
    params: { archival: archival }
  });
}

export function deleteIdentifierById(id) {
  return Api.delete(`/identifier-store/${id}/identifier`);
}
