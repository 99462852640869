<template>
  <div>
    <v-col>
      <v-row>
        <v-col>
          <period @get-date="getDate" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <period-time @get-time="getTime" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="selectControllerId"
            :items="controllers"
            item-text="name"
            item-value="id"
            label="Контроллер"
            clearable
            dense
          />
        </v-col>

        <v-col>
          <category-type @get-category="getCategory" />
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer />

        <v-btn @click="sendParams" text left class="mt-4" color="primary">
          Загрузить данные
        </v-btn>
      </v-card-actions>
    </v-col>
  </div>
</template>

<script>
import Period from "./Period";
import PeriodTime from "./PeriodTime";
import CategoryType from "./CategoryType";
import { getControllers } from "../../../services/device.service";

export default {
  name: "DisciplineToolbar",
  components: { PeriodTime, Period, CategoryType },
  data: () => ({
    controllers: [],

    selectControllerId: null,

    date: {},
    time: {},
    category: {},
    params: {}
  }),

  created() {
    this.getControllers();
  },

  methods: {
    async getControllers() {
      try {
        this.controllers = await getControllers();

        this.filterControllers();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    filterControllers() {
      this.controllers = this.controllers.filter(
        controller => controller.type !== 2 && controller.name
      );
    },

    generateParams() {
      this.params = {
        ...this.date,
        ...this.time,
        ...this.category,
        controllerId: this.selectControllerId
      };
    },

    getDate(date) {
      this.date = date;
    },

    getTime(time) {
      this.time = time;
    },

    getCategory(categoryParams) {
      this.category = categoryParams;
    },

    sendParams() {
      this.generateParams();
      this.$emit("load", this.params);
    }
  }
};
</script>
