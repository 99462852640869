<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Основная информация</v-tab>
      <v-tab>Дисциплина</v-tab>
      <v-tab>События СКУД</v-tab>
      <v-tab>Статистика СКУД</v-tab>
      <v-tab>Пропуски</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <controller-operations />
      </v-tab-item>

      <v-tab-item>
        <controller-discipline />
      </v-tab-item>

      <v-tab-item>
        <controller-events />
      </v-tab-item>

      <v-tab-item>
        <controller-statistics />
      </v-tab-item>

      <v-tab-item>
        <lost-period-list />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import Page from "@/components/Page";
import ControllerOperations from "@/components/devices/DeviceInformation/ControllerOperations";
import ControllerEvents from "@/components/devices/DeviceEvents/ControllerEvents";
import ControllerDiscipline from "../../components/devices/DeviceDiscipline/ControllerDiscipline";
import ControllerStatistics from "@/components/devices/DeviceStatistics/ControllerStatistics";
import LostPeriodList from "../../components/devices/ControllerLostPeriod/LostPeriodList";

export default {
  name: "ControllerList",

  components: {
    LostPeriodList,
    ControllerStatistics,
    ControllerDiscipline,
    ControllerEvents,
    ControllerOperations,
    Page
  },

  data() {
    return {
      tab: null
    };
  },

  created() {
    this.tab = Number(this.$route.query.tab) || 0;
  },

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });
    }
  }
};
</script>
