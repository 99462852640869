import { NEWS_ROUTE } from "./names";
import News from "../views/news/News";

export const newsRoutes = [
  {
    path: "/news",
    name: NEWS_ROUTE,
    component: News,
    meta: {
      type: "news",
      group: "Новости и голосования",
      title: "Новости",
      icon: "mdi-newspaper",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Новости",
          disabled: true,
          link: true,
          to: { name: NEWS_ROUTE }
        }
      ]
    }
  }
];
