<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Основная информация</v-tab>
      <v-tab>Управление доступом</v-tab>
      <v-tab>События СКУД</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="py-4">
        <general-information :pass="pass" :management="management" />
      </v-tab-item>

      <v-tab-item class="py-4">
        <access-information
          v-if="Object.keys(user).length"
          :user="user"
          :category-list="management"
        />
      </v-tab-item>

      <v-tab-item class="py-4">
        <access-event-information :userId="pass.userId" />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import Page from "../../components/Page";
import { getPassById } from "../../services/pass.service";
import GeneralInformation from "../../components/pass/GeneralInformation.vue";
import { currentUser } from "../../services/auth.service";
import AccessEventInformation from "@/components/user-management/AccessEventInformation";
import AccessInformation from "@/components/user-management/AccessInformation/AccessInformation";
import { getUserById } from "../../services/user.service";

export default {
  name: "PassEdit",
  components: {
    GeneralInformation,
    Page,
    AccessEventInformation,
    AccessInformation
  },

  data: () => ({
    tab: null,

    pass: {},
    user: {},
    management: []
  }),

  created() {
    this.tab = Number(this.$route.query.tab) || 0;
  },

  async mounted() {
    await this.loadData();
    await this.getManagementByCurrentUser();
    await this.getUser();
  },

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });
    },

    "$route.params.id": {
      async handler() {
        await this.loadData();
      }
    }
  },

  methods: {
    currentUser,
    async loadData() {
      const { passId } = this.$route.params;

      try {
        this.pass = await getPassById(passId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getManagementByCurrentUser() {
      const currentUser = await this.currentUser();
      this.management = currentUser.management.map(o => o.category);
    },

    async getUser() {
      try {
        this.user = await getUserById(this.pass.userId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
