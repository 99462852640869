var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-dialog',{attrs:{"max-width":"480"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Создать")])],1)]}}]),model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}},[_c('pass-editor',{attrs:{"message":_vm.message},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1)],1),_c('v-divider'),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-select',{staticClass:"mr-4",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.currentUserManagement,"dense":"","hide-details":"","clearable":"","item-value":"id","item-text":"name","placeholder":"Назначение"},model:{value:(_vm.params.categoryId),callback:function ($$v) {_vm.$set(_vm.params, "categoryId", $$v)},expression:"params.categoryId"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-key":"id"},on:{"click:row":_vm.editPass},scopedSlots:_vm._u([{key:"item.surname,name,patronymic",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(((item.surname) + " " + (item.name) + " " + (item.patronymic || ""))))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.active)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-open-variant-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-outline ")])]}}],null,true)},[(item.active)?_c('span',[_vm._v("Активный")]):_c('span',[_vm._v("Неактивный")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.checkDate(item.dateEnd))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-check-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert-outline ")])]}}],null,true)},[(_vm.checkDate(item.dateEnd))?_c('span',[_vm._v("Действительный")]):_c('span',[_vm._v("Просрочен")])])],1)]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDateTime(item.dateStart))+" ")])]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDateTime(item.dateEnd))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyData(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-link ")])],1)]}}],null,true)},[_c('span',[_vm._v("Скопировать")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }