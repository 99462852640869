<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Данные для приложения</v-card-title>

      <v-divider />

      <v-card-text>
        <v-textarea auto-grow label="Данные для входа" :value="authData" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text color="black" @click="copyAuthData()">Копировать</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    user: { type: Object, required: true }
  },

  computed: {
    authData() {
      return (
        `${this.user.surname} ${this.user.name} ${this.user.patronymic ||
          ""}, ${this.user.description || ""}` +
        "\n" +
        "Логин: " +
        `${this.user.login || ""}` +
        "\n" +
        "Пароль: " +
        `${this.user.password || "Пароль задан пользователем"}`
      );
    }
  },

  methods: {
    copyAuthData() {
      navigator.clipboard.writeText(this.authData);
    }
  }
};
</script>
