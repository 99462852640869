import UserList from "../views/user-management/UserList";
import UserSearch from "../views/user-management/UserSearch";
import StaffList from "../views/user-management/StaffList";

import {
  PPS_EDIT_ROUTE,
  PPS_ROUTE,
  STAFF_EDIT_ROUTE,
  STAFF_ROUTE,
  USERS_EDIT_ROUTE,
  USERS_SEARCH_ROUTE,
  USERS_ROUTE,
  USERS_ADDITION_ROUTE
} from "./names";
import PpsList from "../views/user-management/PpsList";
import UserEdit from "../views/user-management/UserEdit";
import UserAddition from "../views/user-management/UserAddition";

export const userManagementRoutes = [
  {
    path: "/users/search",
    name: USERS_SEARCH_ROUTE,
    component: UserSearch,
    meta: {
      group: "Управление пользователями",
      title: "Поиск",
      description: "Поиск пользователей кампусной системы",
      icon: "mdi-magnify",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Поиск",
          disabled: true,
          link: true,
          to: { name: USERS_SEARCH_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/addition",
    name: USERS_ADDITION_ROUTE,
    component: UserAddition,
    meta: {
      group: "Управление пользователями",
      title: "Добавить пользователя",
      description: "Добавить пользователей кампусной системы",
      icon: "mdi-plus",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Добавление",
          disabled: true,
          link: true,
          to: { name: USERS_ADDITION_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/staff",
    name: STAFF_ROUTE,
    component: StaffList,
    meta: {
      type: "staff",
      group: "Управление пользователями",
      title: "Сотрудники",
      description: "Управление сотрудниками кампусной системы",
      icon: "mdi-account",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Сотрудники",
          disabled: true,
          link: true,
          to: { name: STAFF_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/entrant",
    name: USERS_ROUTE,
    component: UserList,
    meta: {
      type: "entrant",
      group: "Управление пользователями",
      title: "Студенты",
      description: "Управление контингентом кампусной системы",
      icon: "mdi-account-group",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Студенты",
          disabled: true,
          link: true,
          to: { name: USERS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/pps",
    name: PPS_ROUTE,
    component: PpsList,
    meta: {
      type: "pps",
      group: "Управление пользователями",
      title: "ППС",
      description: "Профессорско-преподавательский состав",
      icon: "mdi-school",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "ППС",
          disabled: true,
          link: true,
          to: { name: PPS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/entrant/:userId/edit",
    name: USERS_EDIT_ROUTE,
    component: UserEdit,
    meta: {
      type: "entrant",
      title: "Управление студентами",
      description: "",
      linked: false,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Студенты",
          exact: true,
          link: true,
          to: { name: USERS_ROUTE }
        },
        {
          text: "Управление пользователем",
          disabled: false,
          link: true,
          to: { name: USERS_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/staff/:userId/edit",
    name: STAFF_EDIT_ROUTE,
    component: UserEdit,
    meta: {
      type: "staff",
      title: "Управление сотрудниками",
      description: "",
      linked: false,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Сотрудники",
          exact: true,
          link: true,
          to: { name: STAFF_ROUTE }
        },
        {
          text: "Управление пользователем",
          link: true,
          to: { name: STAFF_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/users/pps/:userId/edit",
    name: PPS_EDIT_ROUTE,
    component: UserEdit,
    meta: {
      type: "pps",
      title: "Управление ППС",
      description: "",
      linked: false,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "ППС",
          exact: true,
          link: true,
          to: { name: PPS_ROUTE }
        },
        {
          text: "Управление пользователем",
          link: true,
          to: { name: PPS_EDIT_ROUTE }
        }
      ]
    }
  }
];
