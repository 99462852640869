import { Api } from "./api.service";
import { orderBy } from "lodash";

function get() {
  return Api.get(`/events`).then(list =>
    orderBy(list, ["beginDate", "endDate"], "desc")
  );
}

function getById(id) {
  return Api.get(`/events/${id}`);
}

function getRequestsById(id) {
  return Api.get(`/events/${id}/requests`).then(list =>
    orderBy(list, ["date"], "desc")
  );
}

function save(event) {
  if (event.id) {
    return Api.put(`/events/${event.id}`, event);
  } else {
    return Api.post(`/events`, event);
  }
}

function createInvite(eventId, request) {
  return Api.post(`/events/${eventId}/invites`, {
    email: request.user.email
  });
}

function inviteIsWaiting(invite) {
  return !invite.sentAt && !inviteIsFailed(invite);
}

function inviteIsFailed(invite) {
  return invite.sendTryCount >= 5;
}

export {
  get,
  getById,
  getRequestsById,
  save,
  createInvite,
  inviteIsWaiting,
  inviteIsFailed
};
