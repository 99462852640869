<template>
  <v-card outlined>
    <v-card-title>Метки пользователя</v-card-title>

    <v-divider />

    <v-toolbar dense flat>
      <edit-user-tag :tags="allTags" @update="updateUserTag" />
    </v-toolbar>

    <v-divider />

    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th class="col-1">Активный</th>

            <th>Имя метки</th>

            <th>Срок истечения</th>

            <th>Действия</th>
          </tr>
        </thead>

        <tbody>
          <template v-if="user.tags.length">
            <tr v-for="tag in user.tags" :key="tag.tagId">
              <td>
                <v-icon
                  :color="
                    !tag.expiresAt || equalTwoDates(tag.expiresAt)
                      ? 'green'
                      : 'grey'
                  "
                >
                  {{
                    !tag.expiresAt || equalTwoDates(tag.expiresAt)
                      ? "mdi-check-circle"
                      : "mdi-close-circle"
                  }}
                </v-icon>
              </td>

              <td>{{ getTagName(tag) }}</td>

              <td>{{ tag.expiresAt || "Бессрочно" }}</td>

              <td>
                <edit-user-tag
                  :tags="allTags"
                  :tag="tag"
                  @update="updateUserTag"
                />

                <v-btn icon @click="deleteTag(tag.tagId)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>

          <tr v-else>
            <td colspan="3">
              <p>Меток нет</p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  deleteUserTag,
  getUserTags,
  updateUserTag
} from "../../../services/user.service";
import { getTags } from "../../../services/tags.service";
import EditUserTag from "../EditUserTag";
export default {
  name: "UserTags",
  components: { EditUserTag },

  props: {
    user: { required: true, type: Object }
  },

  data() {
    return {
      allTags: []
    };
  },

  created() {
    this.getAllTags();
  },

  methods: {
    async getAllTags() {
      try {
        this.allTags = await getTags();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getUserTags() {
      try {
        this.user.tags = await getUserTags(this.$route.params.userId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async deleteTag(tagId) {
      try {
        await deleteUserTag(this.user.id, tagId);

        await this.getUserTags();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateUserTag(tagInfo) {
      try {
        await updateUserTag(this.user.id, {
          tagId: tagInfo.id || tagInfo.tagId,
          expiresAt: tagInfo.expiresAt
        });

        await this.getUserTags();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getTagName(currentTag) {
      const tag = this.allTags.find(tag => tag.id === currentTag.tagId);
      return tag ? tag.name : "";
    },

    equalTwoDates(currentDate) {
      const today = new Date();
      const curDate = new Date(currentDate);

      return today < curDate;
    }
  }
};
</script>
