<template>
  <v-row>
    <v-col cols="12">
      <v-switch
        v-model="accessModel.canModerate"
        label="Возможность модерировать контингент"
      />
      <v-switch
        v-model="accessModel.canGivePass"
        label="Возможность выдавать пропуска"
      />
    </v-col>

    <v-col cols="12">
      <v-combobox
        multiple
        clearable
        v-model="accessModel.selected"
        :items="categoryList"
        item-text="name"
        item-value="id"
        :disabled="!accessModel.canModerate && !accessModel.canGivePass"
        label="Выбор категорий модерации"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ModerateForm",

  model: {
    prop: "access",
    event: "update"
  },

  props: {
    categoryList: { type: Array, default: () => [] },
    userManagement: { type: Array, default: () => [] },
    access: {
      type: Object,
      required: true,
      default: () => (
        { canModerate: false, selected: [] },
        { canGivePass: false, selected: [] }
      )
    }
  },

  watch: {
    "access.canModerate": {
      handler(newValue) {
        if (!newValue && !this.access.canGivePass) {
          this.selected = [];
        }
      }
    },

    "access.canGivePass": {
      handler(newValue) {
        if (!newValue && !this.access.canModerate) {
          this.selected = [];
        }
      }
    }
  },

  computed: {
    accessModel: {
      get() {
        return this.access;
      },

      set(newValue) {
        this.$emit("update", newValue);
      }
    }
  }
};
</script>
