<template>
  <v-dialog v-model="show" width="680">
    <template #activator="{ attr, on }">
      <v-btn
        :icon="!!rule"
        :text="!rule"
        v-bind="attr"
        v-on="on"
        color="primary"
        :disabled="disabled"
      >
        <v-icon :left="!rule">{{ rule ? "mdi-pencil" : "mdi-plus" }}</v-icon>
        {{ rule ? "" : "Создать" }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ dialogTitle }}</v-card-title>

      <v-card-text>
        <v-radio-group
          v-model="params.accessPointId"
          mandatory
          label="Точка доступа"
        >
          <div class="d-flex flex-wrap">
            <div class="mt-2 mr-3">
              <v-radio
                v-for="point in accessPoints.slice(
                  0,
                  Math.ceil(accessPoints.length / 2)
                )"
                :key="point.id"
                :label="point.name"
                :value="point.id"
              />
            </div>
            <div class="mt-2">
              <v-radio
                v-for="point in accessPoints.slice(
                  Math.ceil(accessPoints.length / 2)
                )"
                :key="point.id"
                :label="point.name"
                :value="point.id"
              />
            </div>
          </div>
        </v-radio-group>

        <v-radio-group v-model="validity" mandatory label="Срок действия">
          <v-radio label="Бессрочно" value="infinity" />
          <v-radio label="С указанием длительности" value="expire" />
          <v-radio label="Учебный год" value="study_year" />
        </v-radio-group>

        <div v-if="isWithExpire" class="d-flex align-center">
          <v-date-picker v-model="params.beginDate" class="mr-8" no-title />
          <v-date-picker v-model="params.endDate" no-title />
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="show = false" type="error">Отмена</v-btn>
        <v-btn
          text
          color="primary"
          @click="createRule"
          :disabled="!params.accessPointId"
        >
          {{ !rule ? "Создать" : "Обновить" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getAccessPointController } from "../../services/device.service";

export default {
  name: "CreateRule",

  props: {
    rule: Object,
    type: String,
    disabled: Boolean
  },

  data: () => ({
    show: false,

    validity: null,

    accessPoints: [],

    params: {
      beginDate: new Date().toISOString().substring(0, 10),
      endDate: new Date().toISOString().substring(0, 10),
      accessPointId: null
    }
  }),

  created() {
    this.getAccessPoints();
    this.validity = this.type;

    if (this.rule) {
      this.params = this.rule;
    }
  },

  watch: {
    validity: {
      handler(newVal) {
        if (newVal === "infinity") {
          this.params.endDate = null;
          this.params.beginDate = null;
        }

        if (this.show) {
          if (this.validity === "expire") {
            this.params.endDate = new Date().toISOString().substring(0, 10);
            this.params.beginDate = new Date().toISOString().substring(0, 10);
          }
          if (this.validity === "study_year") {
            let endDate = new Date();
            endDate.setMonth(5);
            endDate.setDate(30);
            if (endDate < new Date()) {
              endDate.setFullYear(endDate.getFullYear() + 1);
            }

            this.params.endDate = endDate.toISOString().substring(0, 10);
            this.params.beginDate = new Date().toISOString().substring(0, 10);
          }
        }
      }
    }
  },

  computed: {
    isWithExpire() {
      return this.validity === "expire" || this.validity === "study_year";
    },

    dialogTitle() {
      return this.rule ? "Изменить правило" : "Создать правило";
    }
  },

  methods: {
    createRule() {
      const dates = [this.params.beginDate, this.params.endDate].sort();
      this.params.beginDate = dates[0];
      this.params.endDate = dates[1];

      if (!this.rule) {
        this.$emit("create-rule", this.params);
      } else {
        this.$emit("update-rule", { ...this.params, id: this.rule.id });
      }
      this.show = false;
    },

    async getAccessPoints() {
      try {
        this.accessPoints = await getAccessPointController();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
