<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn icon color="primary" v-on="on" v-bind="attr">
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Изменить</v-card-title>

      <v-card-text>
        <v-select
          v-model="controllerTagsModel"
          :items="allTags"
          item-text="name"
          item-value="id"
          multiple
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="red" @click="close">Отменить</v-btn>

        <v-btn text color="primary" @click="updateControllerTags">
          Обновить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getTags } from "../../services/device.service";

export default {
  name: "ChangeAcsTagsDialog",

  props: {
    allTags: { required: true, type: Array },
    controller: { required: true, type: Object }
  },

  data() {
    return {
      show: false,

      newControllerTags: [],
      controllerTags: []
    };
  },

  computed: {
    controllerTagsModel: {
      get() {
        return this.controllerTags;
      },

      set(newTag) {
        this.newControllerTags = newTag;
      }
    }
  },

  created() {
    this.getControllerTags();
  },

  methods: {
    updateControllerTags() {
      this.$emit("update", this.controller.id, this.newControllerTags);

      this.close();
    },

    async getControllerTags() {
      try {
        this.controllerTags = await getTags(this.controller.id);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    close() {
      this.show = false;
    }
  }
};
</script>
