var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Создать")])],1)]}}]),model:{value:(_vm.editor),callback:function ($$v) {_vm.editor=$$v},expression:"editor"}},[_c('pupil-group-editor',{attrs:{"specialties":_vm.specialties},on:{"close":_vm.close,"save":_vm.save},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","hide-details":"","label":"В архиве"},model:{value:(_vm.params.archival),callback:function ($$v) {_vm.$set(_vm.params, "archival", $$v)},expression:"params.archival"}}),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"sort-by":"course","multi-sort":"","headers":_vm.headers,"items":_vm.groups,"footer-props":{ itemsPerPageOptions: [10, 15, 25] }},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleArchival(item)}}},[(item.archival)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-archive-arrow-up")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-archive-arrow-down")])],1)]}}],null,true)},[(!item.archival)?_c('span',[_vm._v("Архивировать")]):_c('span',[_vm._v("Разархивировать")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }