<template>
  <v-data-table
    dense
    :headers="headers"
    :items="controllers"
    item-key="id"
    show-expand
  >
    <template #item.actions="{item}">
      <controller-dialog
        :controller="item"
        @update="$emit('get-controllers')"
      />

      <v-btn icon @click="$emit('archival', item)">
        <v-icon>{{ archiveIcon }}</v-icon>
      </v-btn>
    </template>

    <template #expanded-item="{headers, item}">
      <td :colspan="headers.length">
        <controller-info class="ma-2" :controller="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import ControllerInfo from "@/components/devices/DeviceInformation/ControllerInfo";
import ControllerDialog from "@/components/devices/DeviceInformation/ControllerDialog";

export default {
  name: "ListTable",
  components: { ControllerInfo, ControllerDialog },

  props: {
    controllers: Array
  },

  data() {
    return {
      headers: [
        {
          text: "Название",
          value: "name"
        },
        {
          text: "Токен",
          value: "token"
        },
        {
          text: "Хост",
          value: "host"
        },
        {
          text: "Версия",
          value: "version"
        },
        {
          text: "Действия",
          value: "actions"
        }
      ]
    };
  },

  computed: {
    archiveIcon() {
      return this.controllers ? "mdi-archive" : "mdi-archive-arrow-up";
    }
  }
};
</script>
