<template>
  <v-app>
    <v-app-bar app dense>
      <v-app-bar-nav-icon @click="showDrawer = !showDrawer" />
      <v-toolbar-title>
        <app-breadcrumbs />
      </v-toolbar-title>

      <v-spacer />

      <help-dialog />

      <logout-dialog />
    </v-app-bar>

    <app-sidebar v-model="showDrawer" />

    <v-main>
      <router-view />
    </v-main>

    <notification-center />
  </v-app>
</template>

<script>
import AppSidebar from "./components/AppSidebar";
import LogoutDialog from "@/components/auth/LogoutDialog";
import HelpDialog from "./components/HelpDialog.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";

import * as jwtService from "@/services/jwt.service";
import * as sessionService from "@/services/session.service";
import { LOGIN_ROUTE } from "@/router/names";
import NotificationCenter from "./components/NotificationCenter";

export default {
  name: "App",
  components: {
    NotificationCenter,
    LogoutDialog,
    AppSidebar,
    HelpDialog,
    AppBreadcrumbs
  },

  data: () => ({
    showDrawer: true
  }),

  async created() {
    jwtService.jwtEnabled() && jwtService.checkEach(50 * 1000);
    if (sessionService.initSession()) return;
    return await this.$router.replace({ name: LOGIN_ROUTE });
  }
};
</script>
