<template>
  <div class="ma-4">
    <v-col>
      <period @update-date="getDate" />

      <v-row>
        <v-select
          v-model="params.pointId"
          :items="accessPoints"
          item-text="name"
          item-value="id"
          placeholder="Точка доступа"
          dense
          clearable
          class="col-6"
        />

        <v-select
          v-model="params.categoryType"
          :items="categoriesTypes"
          item-text="description"
          item-value="name"
          placeholder="Тип категории"
          dense
          class="col-6"
        />
      </v-row>

      <category
        @update-category="getCategory"
        :category-type="params.categoryType"
      />

      <v-row>
        <v-text-field
          v-model="params.minCount"
          label="Минимальное к-во пропусков"
          type="number"
          :rules="rules"
          required
          class="col-12"
        />
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { getAccessPointController } from "../../../services/device.service";
import Period from "./ToolbarComponents/Period";
import Category from "./ToolbarComponents/Category";

export default {
  name: "LostPeriodToolbar",
  components: { Category, Period },

  props: {
    params: { type: Object, required: true }
  },

  data: () => ({
    accessPoints: [],
    categoriesTypes: [
      { name: "staff".toUpperCase(), description: "Сотрудники" },
      { name: "entrant".toUpperCase(), description: "Студенты" }
    ],
    rules: [v => v >= 0 || " Недопустимое число"]
  }),

  created() {
    this.getAccessPoint();
  },

  methods: {
    async getAccessPoint() {
      try {
        this.accessPoints = await getAccessPointController();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getDate(date) {
      const { begin, end } = date;

      this.params.begin = begin;
      this.params.end = end;
    },

    getCategory(category) {
      const { categoryId, course, groupName } = category;

      this.params.categoryId = categoryId;
      this.params.course = course;
      this.params.groupName = groupName;
    }
  }
};
</script>
