import {
  AlignmentType,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
import { TextService } from "./text.service";

export class TableService {
  constructor(exam) {
    this.exam = exam;

    this.textService = new TextService();

    this.columnsWidth = [505, 2515, 1255, 1005, 1100, 1455, 1555, 1305];
  }

  createTable() {
    return new Table({
      alignment: AlignmentType.CENTER,

      columnWidths: this.columnsWidth,

      rows: [
        this.createTableHeader(),

        new TableRow({
          children: [
            this.createTableCell(this.textService.createText("цифрой")),

            this.createTableCell(this.textService.createText("прописью"))
          ]
        }),

        new TableRow({
          tableHeader: true,
          children: [...this.createColumnsCounter()]
        }),

        ...this.createTableContent()
      ]
    });
  }

  createTableHeader() {
    return new TableRow({
      children: [
        this.createTableCell(
          this.textService.createText(
            `№
                  п/п`
          ),
          2
        ),

        this.createTableCell(
          this.textService.createText("Фамилия и инициалы"),
          2
        ),

        this.createTableCell(
          this.textService.createText(`Номер
                  зачётной
                  книжки`),
          2
        ),

        new TableCell({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,

              children: [
                this.textService.createText(
                  `Отметка 
                    о сдаче
                    зачета /
                    зачета с
                    оценкой`
                ),

                this.textService.createAnotherText("(прописью)", 1, 18)
              ]
            })
          ],
          rowSpan: 2
        }),

        this.createTableCell(
          this.textService.createText(`Подпись препода-
                  вателя`),
          2
        ),

        this.createTableCell(
          this.textService.createText(`Оценка (экзамен /
                  курсовая работа /
                  практика)`),
          null,
          2
        ),

        this.createTableCell(
          this.textService.createText(`Подпись экзамена-
                  тора`),
          2
        )
      ]
    });
  }

  createColumnsCounter() {
    let num = 1;
    const cellCount = [];

    for (num; num <= 8; num++) {
      cellCount.push(num).toString();
    }

    return cellCount.map(
      cell =>
        new TableCell({
          width: {
            size: this.columnsWidth[cell - 1],
            type: WidthType.DXA
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [this.textService.createText(cell)]
            })
          ]
        })
    );
  }

  createTableContent() {
    let num = 1;
    return this.exam.items.map(
      item =>
        new TableRow({
          children: [
            this.createTableCell(
              this.textService.createText((num++).toString() + ".")
            ),

            this.createTableCell(
              this.textService.createText(
                `${item.family} ${item.name.substring(0, 1)}. ${
                  item.surname ? item.surname.substring(0, 1) + "." : ""
                }`
              )
            ),

            this.createTableCell(
              item.booknumber
                ? this.textService.createText(item.booknumber.toString())
                : ""
            ),

            this.createTableCell(
              this.textService.createText(
                this.exam.type === "credit" && item.mark ? item.mark.name : ""
              )
            ),

            this.createTableCell(this.textService.createText("")),

            this.createTableCell(this.textService.createText("")),

            this.createTableCell(
              this.textService.createText(
                this.exam.type === "exam" && item.mark ? item.mark.name : ""
              )
            ),

            this.createTableCell(this.textService.createText(""))
          ]
        })
    );
  }

  createTableCell(cellName, rowSpan, columnSpan) {
    return new TableCell({
      rowSpan: rowSpan,

      verticalAlign: VerticalAlign.CENTER,

      columnSpan: columnSpan,

      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [cellName]
        })
      ]
    });
  }
}
