<template>
  <page>
    <list-table group="pps" />
  </page>
</template>

<script>
import Page from "../../components/Page";
import ListTable from "../../components/group-management/ListTable";

export default {
  name: "StaffGroupList",
  components: { ListTable, Page }
};
</script>

<style scoped></style>
