<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-dialog v-model="editor" max-width="480">
        <template #activator="{ on, attr }">
          <v-btn text color="primary" v-bind="attr" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            <span>Создать</span>
          </v-btn>
        </template>
        <key-editor v-model="item" @close="close" @save="save"></key-editor>
      </v-dialog>
    </v-toolbar>

    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      @click:row="open"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click.stop="toggleArchival(item)">
          <v-icon v-if="item.archival" color="success"
            >mdi-archive-arrow-up</v-icon
          >
          <v-icon v-else color="error">mdi-archive-arrow-down-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import KeyEditor from "@/components/keys/KeyEditor.vue";
import {
  getKeys,
  createKey,
  updateKey,
  setArchival
} from "@/services/keys.service";
import { withNotifications } from "@/utils/notifications";

export default {
  components: { KeyEditor },

  data() {
    return {
      headers: [
        { text: "Название", value: "name" },
        { text: "Идентификатор", value: "code" },
        { text: "Описание", value: "description" },
        { text: "Цвет", value: "color" },
        { text: "Действия", value: "actions" }
      ],
      items: [],
      item: {},

      loading: false,
      editor: false
    };
  },

  created() {
    this.load();
  },

  watch: {
    editor(opened) {
      if (!opened) this.item = {};
    }
  },

  methods: {
    async load() {
      this.loading = true;
      await withNotifications(async () => {
        this.items = await getKeys();
      });
      this.loading = false;
    },

    save(key) {
      withNotifications(async () => {
        if (key.id) {
          await updateKey(key);
        } else {
          await createKey(key);
        }
        this.load();
      });
    },

    toggleArchival(item) {
      withNotifications(async () => {
        await setArchival(item, !item.archival);
        this.load();
      });
    },

    open(item) {
      this.item = item;
      this.editor = true;
    },

    close() {
      this.editor = false;
    }
  }
};
</script>
