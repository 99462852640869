<template>
  <v-card>
    <v-card-title>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="onClose()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <event-editor-form
        v-model="event"
        @submit.prevent="onSubmit"
        fast-fail
        ref="form"
      >
        <template #default>
          <v-btn type="submit" block depressed class="mt-2">Сохранить</v-btn>
        </template>
      </event-editor-form>
    </v-card-text>
  </v-card>
</template>

<script>
import EventEditorForm from "@/components/events/EventEditorForm.vue";
import { clone } from "@/utils/clone";

export default {
  components: { EventEditorForm },

  props: {
    value: Object
  },

  data() {
    return {
      event: {}
    };
  },

  computed: {
    title() {
      return this.event.id ? `Редактирование мероприятия` : `Новое мероприятие`;
    },

    formValid() {
      return this.$refs.form.validate();
    }
  },

  watch: {
    value(v) {
      this.event = clone(v);
    }
  },

  methods: {
    onClose() {
      this.$emit("close");
    },

    onSubmit() {
      if (!this.formValid) {
        return;
      }

      this.$emit("input", this.event);
      this.$emit("submit", this.event);
      this.onClose();
    }
  }
};
</script>
