var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":15,"item-key":"id"},scopedSlots:_vm._u([{key:"header.default",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.defaultCount === _vm.items.length,"indeterminate":_vm.isIndeterminate(_vm.defaultCount),"disabled":_vm.disabled,"ripple":false},on:{"input":_vm.onAllDefault}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.granted",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.grantedCount === _vm.items.length,"indeterminate":_vm.isIndeterminate(_vm.grantedCount),"disabled":_vm.disabled,"ripple":false},on:{"input":_vm.onAllGranted}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.denied",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.deniedCount === _vm.items.length,"indeterminate":_vm.isIndeterminate(_vm.deniedCount),"disabled":_vm.disabled,"ripple":false},on:{"input":_vm.onAllDenied}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":!_vm.getRule(item),"disabled":_vm.disabled},on:{"click":function($event){!!_vm.getRule(item) && _vm.setAccess(item, null)}}})]}},{key:"item.granted",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.granted(item),"disabled":_vm.disabled},on:{"click":function($event){!_vm.granted(item) && _vm.setAccess(item, true)}}})]}},{key:"item.denied",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.denied(item),"disabled":_vm.disabled},on:{"click":function($event){!_vm.denied(item) && _vm.setAccess(item, false)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }