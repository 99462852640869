import {
  KEYS_ROUTE,
  TAGS_ROUTE,
  PASS_ROUTE,
  PASS_EDIT_ROUTE,
  EVENTS_ROUTE,
  EVENT_ROUTE,
  IDENTIFIERS_STORAGE_ROUTE
} from "./names";
import TagsList from "../views/TagsList";
import Keys from "../views/keys/Keys";
import Pass from "../views/pass/Pass";
import PassEdit from "../views/pass/PassEdit";
import Events from "../views/events/Events";
import Event from "../views/events/Event";
import IdentifiersStorage from "../views/pass/IdentifiersStorage.vue";

export const accessRoutes = [
  {
    path: "/storage",
    name: IDENTIFIERS_STORAGE_ROUTE,
    component: IdentifiersStorage,
    meta: {
      type: "tags",
      group: "Управление доступом",
      title: "Банк индентификаторов",
      description: "Набор допустимых идентификаторов банка",
      icon: "mdi-bank",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Банк индентификаторов",
          disabled: true,
          link: true,
          to: { name: IDENTIFIERS_STORAGE_ROUTE }
        }
      ]
    }
  },

  {
    path: "/tags",
    name: TAGS_ROUTE,
    component: TagsList,
    meta: {
      type: "tags",
      group: "Управление доступом",
      title: "Метки",
      description: "Метки пользователей и контроллеров",
      icon: "mdi-tag",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Метки",
          disabled: true,
          link: true,
          to: { name: TAGS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/keys",
    name: KEYS_ROUTE,
    component: Keys,
    meta: {
      type: "tags",
      group: "Управление доступом",
      title: "Ключи",
      description: "Ключи от помещений и доступ к ним",
      icon: "mdi-key",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Ключи",
          disabled: true,
          link: true,
          to: { name: KEYS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/pass",
    name: PASS_ROUTE,
    component: Pass,
    meta: {
      type: "tags",
      group: "Управление доступом",
      title: "Пропуска",
      description: "Созданные пользователем пропуска",
      icon: "mdi-lock-clock",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Пропуска",
          disabled: true,
          link: true,
          to: { name: PASS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/pass/:passId/edit",
    name: PASS_EDIT_ROUTE,
    component: PassEdit,
    meta: {
      type: "tags",
      title: "Управление доступом",
      description: "",
      linked: false,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Пропуска",
          link: true,
          exact: true,
          to: { name: PASS_ROUTE }
        },
        {
          text: "Управление пропуском",
          link: true,
          to: { name: PASS_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/events",
    name: EVENTS_ROUTE,
    component: Events,
    meta: {
      type: "tags",
      group: "Управление доступом",
      title: "Мероприятия",
      description: "Мероприятия",
      icon: "mdi-calendar",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Мероприятия",
          disabled: true,
          link: true,
          to: { name: EVENTS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/events/:eventId",
    name: EVENT_ROUTE,
    component: Event,
    props: true,
    meta: {
      type: "tags",
      title: "Управление доступом",
      description: "",
      linked: false,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Мероприятия",
          link: true,
          exact: true,
          to: { name: EVENTS_ROUTE }
        },
        {
          text: "Управление мероприятием",
          link: true,
          to: { name: EVENT_ROUTE },
          disabled: true
        }
      ]
    }
  }
];
