<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model.trim="passModel.surname"
          :rules="surnameRules"
          label="Фамилия"
          autofocus
          required
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model.trim="passModel.name"
          :rules="nameRules"
          label="Имя"
          required
        />
      </v-col>

      <v-col cols="4">
        <v-text-field v-model="passModel.patronymic" label="Отчество" />
      </v-col>

      <v-col cols="12">
        <v-textarea
          rows="1"
          label="Описание"
          v-model="passModel.description"
          :rules="descriptionRules"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="passModel.category"
          label="Назначение"
          :items="management"
          :rules="categoryRules"
          item-text="name"
          return-object
          dense
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <p class="mb-6">Начало</p>
      </v-col>
      <v-col cols="6">
        <p class="mb-6">Конец</p>
      </v-col>
    </v-row>

    <div class="row">
      <v-menu :close-on-content-click="false" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateBegin.date"
            v-bind="attrs"
            v-on="on"
            prepend-icon="mdi-calendar"
            label="Дата"
            readonly
            dense
            class="col-3"
          />
        </template>

        <v-date-picker v-model="dateBegin.date" />
      </v-menu>

      <v-menu
        :close-on-content-click="false"
        offset-y
        min-width="auto"
        v-model="menuTimeBegin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Время"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            dense
            class="col-3"
            v-model="dateBegin.time"
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <v-time-picker v-model="dateBegin.time" format="24hr" />
      </v-menu>

      <v-menu :close-on-content-click="false" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateEnd.date"
            v-bind="attrs"
            v-on="on"
            prepend-icon="mdi-calendar"
            label="Дата"
            readonly
            dense
            class="col-3"
            :rules="minDay"
          />
        </template>

        <v-date-picker v-model="dateEnd.date" />
      </v-menu>

      <v-menu
        :close-on-content-click="false"
        offset-y
        min-width="auto"
        v-model="menuTimeEnd"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Время"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            dense
            class="col-3"
            v-model="dateEnd.time"
            v-bind="attrs"
            v-on="on"
            :rules="minTime"
          />
        </template>

        <v-time-picker v-model="dateEnd.time" format="24hr" />
      </v-menu>
    </div>

    <v-row>
      <v-col cols="3">
        <v-switch
          v-model="passModel.active"
          :label="labelActive"
          dense
          hide-details
        ></v-switch>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "PassInfoForm",
  model: {
    event: "update",
    prop: "pass"
  },

  props: {
    pass: { type: Object },
    management: { type: Array }
  },

  data() {
    return {
      valid: true,
      surnameRules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 2) || "Фамилия должна быть длинее 2 символов"
      ],
      nameRules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 2) || "Имя должно быть длинее 2 символов"
      ],
      categoryRules: [v => !!v || "Это обязательное поле"],
      descriptionRules: [v => !!v || "Это обязательное поле"],
      minDay: [
        () =>
          new Date(`${this.dateBegin.date}`) <=
            new Date(`${this.dateEnd.date}`) ||
          "Дата окончания не может быть раньше даты начала"
      ],
      minTime: [
        () =>
          new Date(`${this.dateBegin.date} ${this.dateBegin.time}`) <=
            new Date(`${this.dateEnd.date} ${this.dateEnd.time}`) ||
          "Время окончания не может быть раньше времени начала"
      ],

      dateBegin: {
        date: null,
        time: null
      },
      dateEnd: {
        date: null,
        time: null
      },
      menuDateBegin: false,
      menuTimeBegin: false,
      menuDateEnd: false,
      menuTimeEnd: false
    };
  },

  computed: {
    passModel: {
      get() {
        return this.pass;
      },

      set(newModel) {
        if (this.$refs.form.validate()) {
          this.$emit("update", newModel);
        }
      }
    },

    labelActive() {
      return this.passModel.active ? "Активный" : "Неактивный";
    }
  },

  watch: {
    passModel: {
      handler() {
        this.dateBegin = this.dateStrToModel(this.passModel.dateStart);
        this.dateEnd = this.dateStrToModel(this.passModel.dateEnd);
      },
      immediate: true
    },

    dateEnd: {
      handler() {
        this.passModel.dateEnd = new Date(
          `${this.dateEnd.date} ${this.dateEnd.time}`
        );
      },
      deep: true
    },

    dateBegin: {
      handler() {
        this.passModel.dateStart = new Date(
          `${this.dateBegin.date} ${this.dateBegin.time}`
        );
      },
      deep: true
    }
  },

  methods: {
    dateStrToModel(str) {
      const date = str ? new Date(str) : new Date();
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      return {
        date: [y, m, d].join("-"),
        time: date.toLocaleString().slice(11, 17)
      };
    }
  }
};
</script>
