import { Api } from "./api.service";
import * as jwtService from "./jwt.service";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
};

function login(username, password) {
  if (jwtService.jwtEnabled()) {
    return jwtService.login(username, password);
  } else {
    return Api.post(`/auth?login=${username}&pwd=${password}`, {}, { headers });
  }
}

function logout() {
  if (jwtService.jwtEnabled()) {
    return jwtService.logout();
  } else {
    return Api.post(`/logout`);
  }
}

function currentUser() {
  return Api.get(`/current-user`);
}

export { login, logout, currentUser };
