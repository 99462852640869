<template>
  <v-menu ref="menu" :close-on-content-click="false" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        v-bind="attrs"
        v-on="on"
        :label="label"
        :clearable="clearable"
        prepend-icon="mdi-calendar"
        readonly
      />
    </template>

    <v-date-picker v-model="date" :type="type" />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => null
    },
    type: {
      type: String,
      default: () => "date"
    },
    label: {
      type: String,
      default: () => null
    },
    clearable: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      date: null
    };
  },

  watch: {
    value: {
      handler(v) {
        this.date = v;
      },
      immediate: true
    },

    date: {
      handler(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
