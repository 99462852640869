function saveObject(blob, fileName) {
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");

  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}
export { saveObject };
