<template>
  <v-card outlined>
    <lost-period-toolbar :params="params" />

    <v-divider />

    <v-data-table
      :headers="headers"
      :items="lostPeriod.content"
      item-key="id"
      :loading="loading"
      :options.sync="eventsOptions"
      :server-items-length="lostPeriod && lostPeriod.totalElements"
      :footer-props="{ itemsPerPageOptions: [100, 250, 500] }"
      dense
    >
      <template #item.fio="{item}">
        <p class="ma-0">
          <router-link
            class="black--text"
            :to="{
              name: `${
                params.categoryType === 'STAFF'
                  ? 'STAFF_EDIT_ROUTE'
                  : params.categoryType === 'ENTRANT'
                  ? 'USERS_EDIT_ROUTE'
                  : 'PPS_EDIT_ROUTE'
              }`,
              params: {
                group: params.categoryType,
                userId: item.userId
              }
            }"
            >{{ item.fio }}</router-link
          >
        </p>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import LostPeriodToolbar from "./LostPeriodToolbar";
import { getLostPeriod } from "../../../services/device.service";
export default {
  name: "LostPeriodList",
  components: { LostPeriodToolbar },

  data: () => ({
    lostPeriod: [],
    headers: [
      { text: "ФИО", value: "fio" },
      { text: "Описание", value: "description" },
      { text: "Начальная дата", value: "startDate" },
      { text: "Контроллер", value: "pointName" },
      { text: "К-во пропусков", value: "count" }
    ],

    params: {
      begin: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10),
      end: new Date().toISOString().substring(0, 10),
      pointId: null,
      categoryType: "STAFF",
      categoryId: -1,
      course: -1,
      groupName: "",
      minCount: 5
    },
    eventsOptions: {},

    loading: false
  }),

  watch: {
    eventsOptions: {
      handler() {
        const { page, itemsPerPage } = this.eventsOptions;
        this.params.page = page - 1;
        this.params.size = itemsPerPage;

        this.getLostPeriod(this.params);
      }
    },

    params: {
      handler(newParams) {
        this.getLostPeriod(newParams);
      },
      deep: true
    }
  },

  methods: {
    async getLostPeriod() {
      try {
        this.loading = true;

        this.lostPeriod = await getLostPeriod(this.params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
