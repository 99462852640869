import { Api } from "./api.service";
import qs from "qs";

export function getNews(params) {
  const query = qs.stringify(params);
  return Api.get(`/news${query && "?" + query}`);
}

export function deleteNews(id) {
  return Api.delete(`/news/${id}`);
}

export function updateNews(params) {
  return Api.put("/news", params);
}

export function createNews(params) {
  return Api.post("/news", params);
}

export function createVotingForNews(id, params) {
  return Api.post(`/news/${id}/voting`, params);
}

export function updateVoting(voting) {
  return Api.put(`/voting/${voting.id}`, voting);
}

export function deleteVoting(votingId) {
  return Api.delete(`/voting/${votingId}`);
}

export function createVotingVariant(votingId, variant) {
  return Api.post(`/voting/${votingId}/voting-variant`, variant);
}

export function updateVotingVariant(variant) {
  return Api.put(`/voting-variant/${variant.id}`, variant);
}

export function deleteVotingVariant(variant) {
  return Api.delete(`/voting-variant/${variant.id}`);
}
