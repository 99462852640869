export const IDENTIFIERS = {
  0: {
    name: "Карта банка",
    color: "green",
    icon: "mdi-credit-card"
  },

  1: {
    name: "Прочее",
    color: "blue-grey lighten-2",
    icon: "mdi-card"
  },

  3: {
    name: "Карта учереждения",
    color: "red darken-4",
    icon: "mdi-card-text"
  }
};

export function getIdentifierName(id) {
  return (IDENTIFIERS[id] || { name: "Н/Д" }).name;
}

export function getIdentifierColor(id) {
  return (IDENTIFIERS[id] || { color: "gray" }).color;
}

export function getIdentifierIcon(id) {
  return (IDENTIFIERS[id] || { icon: "mdi-card" }).icon;
}
