<script>
import EventEditorForm from "@/components/events/EventEditorForm.vue";
import * as EventsService from "@/services/events.service.js";

export default {
  components: {
    EventEditorForm
  },

  props: {
    event: Object
  },

  computed: {
    formValid() {
      return this.$refs.form.validate();
    }
  },

  methods: {
    async onSave() {
      if (!this.formValid) {
        return;
      }

      await this.save(this.event);
    },

    async save(item) {
      try {
        await EventsService.save(item);
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>

<template>
  <v-card outlined>
    <v-card-title>Мероприятие</v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <event-editor-form v-model="event" ref="form"></event-editor-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="onSave">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>
