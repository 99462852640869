<script>
import EventRequestDialog from "@/components/events/EventRequestDialog";
import * as EventsService from "@/services/events.service.js";
import { goToUser } from "@/utils/router";
import { formatDateTime } from "@/utils/date-format";

export default {
  components: {
    EventRequestDialog
  },

  props: {
    event: Object,
    eventId: [Number, String]
  },

  data() {
    return {
      requests: [],

      request: null,

      filters: {
        status: {
          label: "Статус заявки",
          value: null,
          options: [
            { label: "Без приглашений", value: "empty" },
            { label: "Приглашение ожидает отправки", value: "wait" },
            { label: "Приглашение отправлено", value: "sent" },
            { label: "Приглашение прочитано", value: "read" },
            { label: "Приглашение не удалось отправить", value: "fail" }
          ]
        }
      }
    };
  },

  computed: {
    requestsFiltered() {
      return this.requests.filter(el => {
        switch (this.filters.status.value) {
          case null:
            return true;
          case "empty":
            return !el.invites.length;
          case "fail":
            return (
              el.invites.length &&
              el.invites.every(el => EventsService.inviteIsFailed(el))
            );
          case "wait":
            return el.invites.find(el => EventsService.inviteIsWaiting(el));
          case "sent":
            return el.invites.find(el => el.sentAt);
          case "read":
            return el.invites.find(el => el.readAt);
        }
      });
    },

    requestsSelected() {
      return this.requestsFiltered.filter(el => el.selected);
    },

    selectedCount() {
      return this.requestsSelected.length;
    },

    indeterminate() {
      return !!(
        this.selectedCount && this.selectedCount < this.requestsFiltered.length
      );
    }
  },

  watch: {
    requestsFiltered(newVal, oldVal) {
      if (newVal?.length !== oldVal?.length) {
        this.requests.forEach(el => (el.selected = false));
      }
    }
  },

  created() {
    this.get();
  },

  methods: {
    async onInviteSend() {
      const p = this.requestsSelected.map(el => {
        return EventsService.createInvite(this.eventId, el);
      });
      await Promise.all(p);
      this.get();
    },

    onToggleAll(v) {
      this.requests.forEach(el => (el.selected = v));
    },

    onRequestDialog(request) {
      this.request = request;
    },

    formatUser(user) {
      return ["surname", "name", "patronymic"]
        .map(el => user[el])
        .filter(Boolean)
        .join(" ");
    },

    async get() {
      try {
        this.requests = await EventsService.getRequestsById(this.eventId).then(
          list => {
            list.forEach(el => (el.selected = false));
            return list;
          }
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async sendInvite(request) {
      try {
        throw request;
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    inviteIsWaiting: EventsService.inviteIsWaiting,
    goToUser,
    formatDateTime
  }
};
</script>

<template>
  <v-card outlined>
    <div class="d-flex align-center pa-3">
      <v-select
        v-for="filter in filters"
        v-model="filter.value"
        :items="filter.options"
        :label="filter.label"
        :key="filter.label"
        item-text="label"
        item-value="value"
        clearable
      ></v-select>
      <v-spacer></v-spacer>
      <div :title="`${selectedCount} выбрано`">
        <v-btn
          @click="onInviteSend"
          :disabled="selectedCount === 0"
          color="primary"
        >
          Пригласить
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>

    <event-request-dialog v-model="request"></event-request-dialog>

    <v-simple-table outlined dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="20px">
              <v-simple-checkbox
                @input="onToggleAll"
                :value="selectedCount === requestsFiltered.length"
                :indeterminate="indeterminate"
                :ripple="false"
              ></v-simple-checkbox>
            </th>
            <th width="20px">
              №
            </th>
            <th class="text-left">
              ФИО
            </th>
            <th class="text-left">
              Эл. почта
            </th>
            <th class="text-left">
              Дата создания
            </th>
            <th class="text-right">
              Ожидает отправки
            </th>
            <th class="text-right">
              Отправлено приглашений
            </th>
            <th class="text-right">
              Прочитано приглашений
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(request, i) in requestsFiltered"
            :key="request.id"
            @click="onRequestDialog(request)"
            style="cursor: pointer"
          >
            <td>
              <v-simple-checkbox
                v-model="request.selected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td>
              {{ i + 1 }}
            </td>
            <td>
              <a @click.stop="goToUser(request.user)">
                {{ formatUser(request.user) }}
              </a>
            </td>
            <td>
              {{ request.user.email }}
            </td>
            <td>
              {{ formatDateTime(request.date) }}
            </td>
            <td class="text-right">
              {{ request.invites.filter(el => inviteIsWaiting(el)).length }}
            </td>
            <td class="text-right">
              {{ request.invites.filter(el => el.sentAt).length }}
            </td>
            <td class="text-right">
              {{ request.invites.filter(el => el.readAt).length }}
            </td>
          </tr>

          <tr v-if="!requestsFiltered.length" class="text-center grey--text">
            <td colspan="7">Заявок не найдено</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
