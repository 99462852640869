<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-card outlined>
          <v-card-title>
            Сотрудники
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr v-for="staff in staffStat" :key="staff.id">
                  <td>
                    {{ staff.name }}
                  </td>

                  <td>
                    {{ staff.count }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card outlined>
          <v-card-title>
            Студенты
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr v-for="student in studentsStat" :key="student.id">
                  <td>
                    {{ student.name }}
                  </td>

                  <td>
                    {{ student.count }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card outlined>
          <v-card-title>
            Идентификаторы
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr v-for="identifier in identifierStat" :key="identifier.type">
                  <td>
                    {{ getIdentifierName(identifier.type) }}
                  </td>

                  <td>
                    {{ identifier.count }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getIdentifierName } from "../../constants/identifiers.constants";
import {
  getCategoryStat,
  getIdentifiersStat
} from "../../services/stat.service";

export default {
  name: "CategoryStatistics",

  data() {
    return {
      studentsStat: [],
      identifierStat: [],
      staffStat: [],
      acsName: []
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    getIdentifierName,

    async loadData() {
      try {
        this.staffStat = await getCategoryStat("staff");
        this.studentsStat = await getCategoryStat("entrant");
        this.identifierStat = await getIdentifiersStat();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
