<template>
  <div class="row">
    <div class="col-6">
      <v-text-field
        v-model.trim="userModel.surname"
        :rules="surnameRules"
        label="Фамилия"
        autofocus
        required
      />
    </div>

    <div class="col-6">
      <v-text-field
        v-model.trim="userModel.name"
        :rules="nameRules"
        label="Имя"
        required
      />
    </div>

    <div class="col-12">
      <v-text-field v-model="userModel.patronymic" label="Отчество" />
    </div>

    <employment-choose
      :group="group"
      @update="getCategoryList"
      class="col-12 mt-1"
    />
  </div>
</template>

<script>
import EmploymentChoose from "./EmploymentChoose";
export default {
  name: "AdditionForm",
  components: { EmploymentChoose },
  props: {
    value: { required: true, type: Object },
    group: { type: String, default: () => "" }
  },

  data() {
    return {
      surnameRules: [v => !!v || "Это обязательное поле"],

      nameRules: [v => !!v || "Это обязательное поле"],

      userModel: { ...this.value }
    };
  },

  watch: {
    userModel: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true
    }
  },

  methods: {
    getCategoryList(employment) {
      this.$emit("update", employment);
    }
  }
};
</script>
