<template>
  <v-toolbar dense flat>
    <v-row>
      <v-col cols="6" class="d-flex align-center">
        <news-editor @update="$emit('update')" />

        <v-switch
          label="В архиве"
          hide-details
          v-model="params.archival"
          class="ma-0 ml-8"
        />
      </v-col>

      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedCategory"
              :items="categories"
              item-text="name"
              item-value="id"
              placeholder="Группа / Подразделение"
              clearable
              dense
              hide-details
              @click:clear="clearSelectOption(['course'])"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="params.course"
              :items="courses"
              item-value="id"
              item-text="name"
              :disabled="disabledCourses"
              placeholder="Курс"
              clearable
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import {
  getCategoryList,
  getCoursesList
} from "../../services/category.service";
import NewsEditor from "./NewsEditor";

export default {
  name: "NewsToolbar",
  components: { NewsEditor },
  props: {
    value: Object
  },

  data() {
    return {
      courses: [],
      categories: [],

      selectedCategory: null
    };
  },

  computed: {
    params: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      }
    },

    disabledCourses() {
      return this.getCategoryType(this.selectedCategory) !== "ENTRANT";
    }
  },

  watch: {
    selectedCategory: {
      handler(value) {
        this.params.categoryId = value;

        if (this.getCategoryType(value) === "ENTRANT") {
          this.getCourses();
        } else {
          this.params.course = null;
        }
      }
    }
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    async getCourses() {
      try {
        this.courses = await getCoursesList(this.selectedCategory);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getCategories() {
      try {
        const departments = await getCategoryList("staff");
        const faculties = await getCategoryList("entrant");

        this.categories = [...departments, ...faculties];
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    clearSelectOption(fields = []) {
      fields.forEach(field => (this.params[field] = null));
    },

    getCategoryType(categoryId) {
      const category = this.categories.find(c => c.id === categoryId);

      if (category) {
        return category.type;
      }
    }
  }
};
</script>
