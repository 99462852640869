<template>
  <v-dialog v-model="show" persistent max-width="480">
    <template #activator="{on, attr}">
      <v-btn text v-bind="attr" v-on="on">Выйти</v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Выйти из панели управления?
      </v-card-title>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="show = false">Остаться</v-btn>
        <v-btn @click="logout" color="error" text>Да, выйти</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { logout } from "@/services/jwt.service";
import { LOGIN_ROUTE } from "@/router/names";

export default {
  name: "LogoutDialog",

  data: () => ({
    show: false,
    loading: false
  }),

  methods: {
    logout() {
      this.loading = true;

      logout()
        .then(() => {
          this.show = false;
          this.$router.push({ name: LOGIN_ROUTE });
        })
        .catch(e => {
          this.$notify({
            text: e.response ? e.response.data.message : e,
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
