<template>
  <page>
    <exam-sheet />
  </page>
</template>

<script>
import Page from "../../components/Page";
import ExamSheet from "../../components/exam/ExamSheet";

export default {
  name: "Exam",
  components: { ExamSheet, Page }
};
</script>
