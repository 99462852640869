import { EXAM_ROUTE } from "./names";
import Exam from "../views/exam/Exam";

export const examRoutes = [
  {
    path: "/exam",
    name: EXAM_ROUTE,
    component: Exam,
    meta: {
      type: "exam",
      group: "Учебная деятельность",
      title: "Ведомости",
      icon: "mdi-file-document-multiple",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Ведомости",
          disabled: true,
          link: true,
          to: { name: EXAM_ROUTE }
        }
      ]
    }
  }
];
