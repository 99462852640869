import { isEmpty } from "lodash";
import {
  PPS_ROUTE,
  STAFF_GROUPS_ROUTE,
  PPS_GROUPS_ROUTE,
  STAFF_ROUTE,
  USERS_EDIT_ROUTE,
  USERS_SEARCH_ROUTE,
  STAFF_EDIT_ROUTE,
  USERS_GROUPS_ROUTE,
  USERS_ROUTE,
  CONTROLLERS_ROUTE,
  NEWS_ROUTE,
  EXAM_ROUTE,
  INFO_STAFF_EDIT_ROUTE,
  INFO_USERS_EDIT_ROUTE,
  PPS_EDIT_ROUTE,
  PASS_EDIT_ROUTE,
  TAGS_ROUTE,
  IDENTIFIERS_STORAGE_ROUTE,
  USERS_ADDITION_ROUTE,
  KEYS_ROUTE,
  PASS_ROUTE,
  EVENTS_ROUTE,
  SPECIALITY_GROUPS_ROUTE,
  INFO_SPECIALITY_EDIT_ROUTE,
  INFO_PPS_EDIT_ROUTE,
  EVENT_ROUTE
} from "../router/names";

export function createAbility() {
  const rules = {};

  const allow = (target, rule, access = true) => {
    if (isEmpty(rules[target])) {
      rules[target] = {
        abilities: [{ rule, access }]
      };
    } else {
      rules[target].abilities.push({ rule, access });
    }
  };

  const deny = (target, rule) => allow(target, rule, false);

  const isAllow = (target, rule) => {
    const targetRule = rules[target];

    if (isEmpty(targetRule)) {
      return false;
    } else {
      const foundRule = targetRule.abilities.find(ab => ab.rule === rule);

      if (foundRule) {
        return foundRule.access;
      } else {
        return false;
      }
    }
  };

  return { rules, allow, deny, isAllow };
}

export function defineAccessFor(user) {
  const { allow, deny, isAllow, rules } = createAbility();
  const groups = user.groups || [];

  groups.forEach(group => {
    const hasEntrant = user.management.find(m => m.category.type === "ENTRANT");
    const hasStaff = user.management.find(m => m.category.type === "STAFF");
    const hasPPS = user.management.find(m => m.category.type === "PPS");

    switch (group.toString().toUpperCase()) {
      case "ADMIN":
        if (hasEntrant) {
          allow("routes", USERS_ROUTE);
          allow("routes", USERS_GROUPS_ROUTE);
          allow("routes", SPECIALITY_GROUPS_ROUTE);
          allow("routes", USERS_EDIT_ROUTE);
        }

        if (hasStaff) {
          allow("routes", STAFF_ROUTE);
          allow("routes", STAFF_GROUPS_ROUTE);
          allow("routes", PPS_GROUPS_ROUTE);
          allow("routes", STAFF_EDIT_ROUTE);
        }

        if (hasPPS) {
          allow("routes", PPS_ROUTE);
          allow("routes", PPS_EDIT_ROUTE);
        }

        allow("routes", USERS_SEARCH_ROUTE);
        allow("routes", USERS_ADDITION_ROUTE);
        allow("routes", CONTROLLERS_ROUTE);
        allow("routes", INFO_STAFF_EDIT_ROUTE);
        allow("routes", INFO_PPS_EDIT_ROUTE);
        allow("routes", INFO_USERS_EDIT_ROUTE);
        allow("routes", INFO_SPECIALITY_EDIT_ROUTE);
        allow("routes", KEYS_ROUTE);
        allow("routes", PASS_ROUTE);
        allow("routes", PASS_EDIT_ROUTE);
        allow("routes", EVENTS_ROUTE);
        allow("routes", EVENT_ROUTE);
        allow("routes", NEWS_ROUTE);
        allow("routes", EXAM_ROUTE);
        allow("routes", TAGS_ROUTE);
        allow("tabs", "access_control");
        allow("elem", "download_data");
        break;

      case "MANAGER":
        if (hasEntrant) {
          allow("routes", USERS_ROUTE);
          allow("routes", USERS_EDIT_ROUTE);
        }

        if (hasStaff) {
          allow("routes", STAFF_ROUTE);
          allow("routes", STAFF_EDIT_ROUTE);
        }

        if (hasPPS) {
          allow("routes", PPS_ROUTE);
          allow("routes", PPS_EDIT_ROUTE);
        }

        allow("routes", "home");
        allow("routes", NEWS_ROUTE);
        allow("routes", EXAM_ROUTE);
        allow("routes", USERS_SEARCH_ROUTE);
        allow("routes", INFO_STAFF_EDIT_ROUTE);
        allow("routes", INFO_PPS_EDIT_ROUTE);
        allow("routes", INFO_USERS_EDIT_ROUTE);

        // deny
        deny("tabs", "access_control");
        break;

      case "PASS_MANAGER":
        allow("routes", "home");
        allow("routes", PASS_ROUTE);
        allow("routes", PASS_EDIT_ROUTE);
        allow("routes", EVENTS_ROUTE);
        allow("routes", EVENT_ROUTE);

        break;

      case "GROUP_IDENTIFIER_MANAGER":
        allow("routes", IDENTIFIERS_STORAGE_ROUTE);
        break;
    }
  });

  return { rules, isAllow };
}
