<template>
  <v-toolbar dense flat>
    <v-select
      class="mr-4"
      style="max-width: 320px"
      v-model="tableOptions.categoryId"
      :items="categorySortedList"
      dense
      hide-details
      clearable
      item-value="id"
      item-text="name"
      :placeholder="group === 'entrant' ? 'Факультет' : 'Подразделение'"
      @click:clear="clearTableOptionsFiled(['pupilGroupId', 'pupilCourse'])"
    />

    <v-select
      v-if="group === 'entrant'"
      class="mr-4"
      style="max-width: 320px"
      v-model="tableOptions.pupilCourse"
      :items="courses"
      dense
      hide-details
      clearable
      item-value="id"
      item-text="name"
      placeholder="Курс / Класс"
      :disabled="!tableOptions.categoryId"
      @click:clear="clearTableOptionsFiled(['pupilGroupId'])"
    />

    <v-select
      v-if="group === 'entrant'"
      style="max-width: 320px"
      v-model="tableOptions.pupilGroupId"
      :items="groupNames"
      :item-text="el => (el.liter ? el.liter : '-')"
      item-value="id"
      return-
      dense
      hide-details
      clearable
      placeholder="Группа / Литер"
      :disabled="!tableOptions.pupilCourse"
      class="mr-4"
    />

    <v-select
      v-model="tableOptions.validated"
      :items="validItems"
      clearable
      dense
      hide-details
      style="max-width: 300px"
      placeholder="Валидация"
      class="mr-4"
    />

    <v-select
      v-model="tableOptions.archival"
      :items="archiveItems"
      clearable
      dense
      hide-details
      style="max-width: 300px"
      placeholder="Архив"
    />
  </v-toolbar>
</template>

<script>
import { getManagement } from "@/services/session.service";

export default {
  props: {
    group: {
      type: String,
      default() {
        return "null";
      }
    },
    tableOptions: {
      type: Object
    },
    categories: {
      type: Array
    },
    courses: {
      type: Array
    },
    groupNames: {
      type: Array
    }
  },
  data: () => ({
    validItems: [
      { text: "Требуют валидации", value: false },
      { text: "Валидные", value: true }
    ],
    archiveItems: [
      { text: "Не в архиве", value: false },
      { text: "В архиве", value: true }
    ]
  }),
  methods: {
    getManagement,
    clearTableOptionsFiled(fields = []) {
      fields.forEach(field => (this.tableOptions[field] = null));
    }
  },
  computed: {
    categorySortedList() {
      return (getManagement(this.group) || this.categories).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
  }
};
</script>
