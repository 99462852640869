<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <create-category-dialog :group="group" @update="loadCategories" />
      <v-spacer />

      <v-text-field
        style="max-width: 320px"
        hide-details
        v-model="search"
        single-line
        prepend-icon="mdi-magnify"
        placeholder="Поиск..."
      />

      <v-btn icon @click="loadCategories"><v-icon>mdi-reload</v-icon></v-btn>
    </v-toolbar>

    <v-divider />

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="categories"
        :search="search"
        :options.sync="categoryOptions"
        :footer-props="{ itemsPerPageOptions: [5, 10, 20] }"
        @click:row="editCategory"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CreateCategoryDialog from "./CreateCategoryDialog";
import { getCategoryList } from "../../services/category.service";

export default {
  name: "ListTable",
  components: { CreateCategoryDialog },
  props: {
    group: { type: String, required: true }
  },

  data: () => ({
    search: "",

    edit: false,

    categories: [],
    headers: [{ text: "Название", value: "name" }],

    accessRules: [],
    selectedCategory: {},
    categoryOptions: {}
  }),

  watch: {
    categoryOptions: {
      handler() {
        this.loadCategories();
      },
      immediate: true
    }
  },

  methods: {
    async loadCategories() {
      try {
        this.categories = await getCategoryList(this.group);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async editCategory(item) {
      const type = item.type.toLowerCase();

      const names = {
        staff: "INFO_STAFF_EDIT_ROUTE",
        pps: "INFO_PPS_EDIT_ROUTE"
      };

      const name = names[type] || "INFO_USERS_EDIT_ROUTE";

      await this.$router.push({
        name,
        params: { group: item.type.toLowerCase(), category: item }
      });
    }
  }
};
</script>
