<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="340">
    <template #activator="{on, attr}">
      <v-btn
        :icon="!!tagModel.tagId"
        text
        color="primary"
        v-on="on"
        v-bind="attr"
      >
        <v-icon :left="!tagModel.tagId">{{
          !!tagModel.tagId ? "mdi-pencil" : "mdi-plus"
        }}</v-icon>
        <span v-if="!tagModel.tagId"> Добавить </span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ !!tagModel.tagId ? "Изменить" : "Добавить" }}
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-if="tagModel.tagId"
          v-model="tagName"
          label="Название"
          readonly
        />

        <v-combobox
          v-else
          v-model="tagModel"
          :items="tags"
          item-value="id"
          item-text="name"
          label="Название"
          dense
          class="pt-3"
        />

        <v-radio-group v-model="validity" mandatory label="Срок действия">
          <v-radio label="Бессрочно" value="infinity" />
          <v-radio label="С указанием длительности" value="expire" />
        </v-radio-group>

        <v-date-picker
          v-if="validity === 'expire'"
          v-model="tagModel.expiresAt"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" text color="red">Отмена</v-btn>

        <v-btn @click="updateUserTag" text color="primary">
          Обновить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditUserTag",

  props: {
    tag: { type: Object, default: () => ({}) },
    tags: { type: Array, default: () => [] }
  },

  data() {
    return {
      show: false,

      validity: null,
      tagModel: {
        tagId: null,
        expiresAt: null,
        id: null,
        name: "",
        ...this.tag
      }
    };
  },

  computed: {
    tagName() {
      const tag = this.tags.find(tag => tag.id === this.tagModel.tagId);
      return tag ? tag.name : "";
    }
  },

  methods: {
    updateUserTag() {
      const expiresAt =
        this.validity === "expire" ? this.tagModel.expiresAt : null;
      this.$emit("update", {
        ...this.tagModel,
        expiresAt
      });

      this.close();
    },

    close() {
      this.show = false;
    }
  }
};
</script>
