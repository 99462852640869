var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('create-speciality-dialog',{on:{"update":_vm.loadSpecialties}}),_c('v-switch',{staticClass:"ml-4",attrs:{"dense":"","hide-details":"","label":"В архиве"},model:{value:(_vm.params.archival),callback:function ($$v) {_vm.$set(_vm.params, "archival", $$v)},expression:"params.archival"}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"hide-details":"","single-line":"","prepend-icon":"mdi-magnify","placeholder":"Поиск..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.loadSpecialties}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1),_c('v-divider'),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-select',{staticClass:"mr-4",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.categories,"dense":"","hide-details":"","clearable":"","item-value":"id","item-text":"name","placeholder":"Факультет"},model:{value:(_vm.params.categoryId),callback:function ($$v) {_vm.$set(_vm.params, "categoryId", $$v)},expression:"params.categoryId"}})],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.specialties,"search":_vm.search,"sort-by":"direction","multi-sort":"","options":_vm.specialityOptions,"footer-props":{ itemsPerPageOptions: [15, 25, 50] }},on:{"update:options":function($event){_vm.specialityOptions=$event},"click:row":_vm.editSpeciality},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.category.name))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.formatDurationToString(item.duration)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleArchival(item)}}},[(item.archival)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("mdi-archive-arrow-up")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-archive-arrow-down")])],1)]}}],null,true)},[(!item.archival)?_c('span',[_vm._v("Архивировать")]):_c('span',[_vm._v("Разархивировать")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }