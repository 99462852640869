<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Данные для Телеграм бота</v-card-title>

      <v-divider />

      <v-card-text>
        <v-textarea
          auto-grow
          :loading="tokenLoading"
          :label="authDataLabel"
          :value="authData"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text color="black" @click="getToken()">Обновить</v-btn>
        <v-btn text color="black" @click="copyAuthData()">Копировать</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { getUserBotToken } from "@/services/user.service.js";
import { withNotifications } from "@/utils/notifications.js";

export default {
  props: {
    user: { type: Object, required: true }
  },

  data() {
    return {
      token: null,
      tokenLoading: false,
      timestamp: -1,
      interval: -1
    };
  },

  computed: {
    tokenExpireAt() {
      return this.token
        ? new Date(this.token.timeCreate).getTime() + 5 * 60 * 1000
        : 0;
    },

    tokenTimeLeft() {
      return this.tokenExpireAt - this.timestamp;
    },

    authData() {
      return (
        `${this.user.surname} ${this.user.name} ${this.user.patronymic ||
          ""}, ${this.user.description || ""}` +
        "\n" +
        "\n" +
        `Телеграм бот кампусной системы:` +
        "\n" +
        `https://t.me/*?start=${this.token?.token}` +
        "\n" +
        "\n" +
        "Ссылка действительна ограниченное время"
      );
    },

    authDataLabel() {
      return `Данные для входа (действительны ${Math.floor(
        this.tokenTimeLeft / 1000
      )} сек.)`;
    }
  },

  watch: {
    token() {
      this.timestamp = new Date().getTime();
    },
    tokenTimeLeft: {
      immediate: true,
      handler(timeLeft) {
        timeLeft < 2 * 60 * 1000 && this.getToken();
      }
    }
  },

  created() {
    this.interval = setInterval(() => {
      this.timestamp = new Date().getTime();
    }, 1 * 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    getToken() {
      return withNotifications(async () => {
        try {
          this.tokenLoading = true;
          this.token = await getUserBotToken(this.user.id);
        } finally {
          this.tokenLoading = false;
        }
      });
    },

    copyAuthData() {
      navigator.clipboard.writeText(this.authData);
    }
  }
};
</script>
