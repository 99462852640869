<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Предоставление данных</v-card-title>

      <v-divider />

      <v-card-text>
        <v-textarea label="Информация о модераторе" :value="data" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text color="black" @click="copyData()">Копировать</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    user: { type: Object },
    accessManagement: { type: Object, default: () => {} }
  },

  computed: {
    passData() {
      return this.accessManagement.canGivePass ? "выдавать пропуска" : "";
    },
    manageData() {
      return this.accessManagement.canModerate ? "модерировать контингент" : "";
    },
    access() {
      return new Array(this.passData, this.manageData);
    },
    data() {
      return (
        `${this.user.surname} ${this.user.name} ${this.user.patronymic ||
          ""}, ${this.user.description || ""}` +
        "\n" +
        "\n" +
        `Разрешено: ${this.access.filter(el => el != "").join(", ")}` +
        "\n" +
        "Доступные категории: " +
        `${this.accessManagement.selected.map(c => c.name).join(", ")}`
      );
    }
  },

  methods: {
    copyData() {
      navigator.clipboard.writeText(this.data);
    }
  }
};
</script>
