<template>
  <v-card-title>
    <v-select
      v-model="selectedState"
      label="Статус ведомости"
      :items="states"
      item-value="name"
      item-text="description"
      clearable
    />
  </v-card-title>
</template>

<script>
export default {
  name: "ExamSheetTitle",
  props: {
    states: { type: Array, required: true }
  },

  data: () => ({
    selectedState: null
  }),

  watch: {
    selectedState: {
      handler(newState) {
        this.$emit("get-state", newState);
      }
    }
  }
};
</script>
