<template>
  <v-row>
    <v-col cols="12" xl="6">
      <v-card outlined>
        <v-card-title>Правила доступа</v-card-title>

        <v-divider />

        <create-rule @create-rule="createRule" @update-rule="loadData" />

        <v-divider />

        <v-card-text>
          <access-rule-table
            :rules="accessRules"
            :category-id="category.id"
            @update="loadData"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col>
      <v-card outlined>
        <v-card-title>Доступ к ключам</v-card-title>
        <keys-access
          v-model="keysSelected"
          :keys="keys"
          :disabled="false"
          @toggle="toggleKey"
          @toggle-all="toggleAllKeys"
        ></keys-access>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AccessRuleTable from "../rules/AccessRuleTable";
import { sortBy, find } from "lodash";
import {
  createCategoryAccessRules,
  getCategoryAccessRules
} from "../../services/category.service";
import CreateRule from "../rules/CreateRule";
import KeysAccess from "../keys/KeysAccess.vue";
import { withNotifications } from "@/utils/notifications.js";
import {
  getKeys,
  addToCategory,
  addAllToCategory,
  removeFromCategory,
  removeAllFromCategory
} from "@/services/keys.service";

export default {
  name: "CategoryRules",
  components: { CreateRule, AccessRuleTable, KeysAccess },

  props: {
    category: { required: true, type: Object }
  },

  data: () => ({
    accessRules: [],
    keys: [],
    keysSelected: []
  }),

  created() {
    this.loadData();
    this.loadKeys();
  },

  methods: {
    loadData() {
      withNotifications(async () => {
        const rules = await getCategoryAccessRules(this.category.id);
        this.accessRules = sortBy(rules, "id");
      });
    },

    async loadKeys() {
      withNotifications(async () => {
        const keys = await getKeys();
        this.keysSelected = keys.filter(k =>
          find(k.categories, { id: this.category.id })
        );
        this.keys = keys;
      });
    },

    createRule(params) {
      withNotifications(async () => {
        await createCategoryAccessRules(this.category.id, params);
        this.loadData();
      });
    },

    async toggleKey({ key, value }) {
      await withNotifications(async () => {
        if (value) {
          await addToCategory(key.id, this.category.id);
        } else {
          await removeFromCategory(key.id, this.category.id);
        }
      });
      this.loadKeys();
    },

    async toggleAllKeys(value) {
      await withNotifications(async () => {
        if (value) {
          await addAllToCategory(this.category.id);
        } else {
          await removeAllFromCategory(this.category.id);
        }
      });
      this.loadKeys();
    }
  }
};
</script>
