<script>
import EventInfo from "@/components/events/EventInfo.vue";
import EventRequestLink from "@/components/events/EventRequestLink.vue";
import EventAccess from "@/components/events/EventAccess.vue";
import EventRequests from "@/components/events/EventRequests.vue";
import * as EventsService from "@/services/events.service.js";

export default {
  components: {
    EventInfo,
    EventRequestLink,
    EventAccess,
    EventRequests
  },

  props: {
    eventId: [String, Number]
  },

  data() {
    return {
      tab: null,
      tabs: ["Основная информация", "Управление доступом", "Заявки на участие"],

      event: {}
    };
  },

  created() {
    this.get();
  },

  methods: {
    async onUpdate() {
      this.get();
    },

    async get() {
      this.event = await EventsService.getById(this.eventId);
    }
  }
};
</script>

<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab v-for="tab in tabs" :key="tab">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-4">
      <v-tab-item>
        <v-row>
          <v-col cols="12" lg="6">
            <event-info :event="event" @update="onUpdate"></event-info>
          </v-col>
          <v-col cols="12" lg="6">
            <event-request-link :event="event"></event-request-link>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <event-access :event="event"></event-access>
      </v-tab-item>

      <v-tab-item>
        <event-requests :event="event" :eventId="eventId"></event-requests>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
