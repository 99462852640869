<template>
  <div>
    <v-dialog v-model="show" persistent width="360px">
      <v-card :loading="loading">
        <v-card-title>Авторизация</v-card-title>

        <v-card-text>
          <v-form @submit.prevent="auth" v-model="valid">
            <v-text-field
              v-model="username"
              color="primary"
              :rules="formRules"
              label="Имя пользователя"
            />
            <v-text-field
              v-model="password"
              type="password"
              color="primary"
              :rules="formRules"
              label="Пароль"
            />

            <v-alert v-if="error && !loading" dense text type="error">
              Неверное имя пользователя или пароль!
            </v-alert>

            <v-btn text color="primary" block type="submit" :disabled="!valid">
              Войти в панель управления
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { currentUser, login, logout } from "../services/auth.service";
import { session, setPermission, setUser } from "@/services/session.service";

export default {
  name: "Login",

  data: () => ({
    show: true,

    username: "",
    password: "",

    valid: false,

    loading: false,
    error: false,

    formRules: [v => !!v || "Это обязательное поле"],

    from: null
  }),

  async mounted() {
    await logout();
    session.close();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => (vm.from = from));
  },

  methods: {
    async auth() {
      session.close();

      if (!(this.username && this.password)) return;

      try {
        this.loading = true;
        this.error = false;

        await login(this.username, this.password);
        const user = await currentUser();

        setUser(user);
        setPermission(user);

        await this.$router.push({ name: "home" });
        location.reload();
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
