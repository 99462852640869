import { TextRun } from "docx";

export class TextService {
  createText(text, breakText) {
    return new TextRun({
      text: text,
      font: "Times New Roman",
      break: breakText,
      size: 24
    });
  }

  createAnotherText(text, breakText, sizeText, boldText) {
    return new TextRun({
      text: text,
      bold: boldText,
      font: "Times New Roman",
      break: breakText,
      size: sizeText
    });
  }

  examDateFormatted(date) {
    return new Date(date).toLocaleString("ru-RU", {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric"
    });
  }

  examTypeText(type) {
    return type === "exam" ? "экзамен" : "зачёт";
  }

  calculateSemester(exam) {
    const semesters = {
      "1": [1, 2],
      "2": [3, 4],
      "3": [5, 6],
      "4": [7, 8],
      "5": [9, 10]
    };

    let course = exam.courseTitle.substring(0, 1);

    let currentSemester = Object.entries(semesters).filter(
      item => item[0] === course
    )[0];

    let currentNewYear = `${new Date().getFullYear()}-01-01`;

    return exam.date < currentNewYear
      ? currentSemester[1][0]
      : currentSemester[1][1];
  }

  calculateStudyYear(exam) {
    let currentSeptember = `${new Date().getFullYear()}-09-01`;

    return exam.date < currentSeptember
      ? `${new Date().getFullYear() - 1}/${new Date().getFullYear()}`
      : `${new Date().getFullYear()}/${new Date().getFullYear() + 1}`;
  }

  marksString(key, value, examType) {
    if (examType === "exam") {
      return `${
        key === "отлично"
          ? "\tИз них получивших «отлично»:"
          : key === "не являлся(лась)"
          ? `\tЧисло студентов, не явившихся на экзамен (зачет):`
          : `\t\t\tПолучивших «${key}»:`
      } ${value}`;
    } else {
      return `${
        key === "зачтено"
          ? "\tИз них получивших «зачтено»:"
          : key === "не являлся(лась)"
          ? `\tЧисло студентов, не явившихся на экзамен (зачет):`
          : `\t\t\tПолучивших «${key}»:`
      } ${value}`;
    }
  }
}
