<script>
import { copyToClipboard } from "../../utils/clipboard";

export default {
  props: {
    event: Object
  },

  computed: {
    text() {
      const { id, name, beginDate, endDate } = this.event;

      const strings = [
        `${name} ${beginDate || ""} - ${endDate || ""}`,
        `Оформите заявку на участие по ссылке: ${location.origin}/campus/events?eventId=${id}`
      ];
      return strings.join("\n");
    }
  },

  methods: {
    async onCopy() {
      copyToClipboard(this.text);
    }
  }
};
</script>

<template>
  <v-card outlined>
    <v-card-title>Предоставление данных</v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-textarea label="Страница оформления заявок" :value="text"></v-textarea>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="onCopy">Копировать</v-btn>
    </v-card-actions>
  </v-card>
</template>
