<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-dialog v-model="editor" max-width="480">
        <template #activator="{ on, attr }">
          <v-btn text color="primary" v-bind="attr" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            <span>Создать</span>
          </v-btn>
        </template>
        <pass-editor
          v-model="item"
          @close="close"
          @save="save"
          :message="message"
        ></pass-editor>
      </v-dialog>
    </v-toolbar>
    <v-divider />
    <v-toolbar dense flat>
      <v-select
        class="mr-4"
        style="max-width: 300px"
        v-model="params.categoryId"
        :items="currentUserManagement"
        dense
        hide-details
        clearable
        item-value="id"
        item-text="name"
        placeholder="Назначение"
      />
    </v-toolbar>
    <v-divider />

    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      @click:row="editPass"
    >
      <template #item.surname,name,patronymic="{ item }">
        <td>{{ `${item.surname} ${item.name} ${item.patronymic || ""}` }}</td>
      </template>

      <template #item.status="{ item }">
        <td>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="item.active"
                v-bind="attrs"
                v-on="on"
                color="success"
              >
                mdi-lock-open-variant-outline
              </v-icon>
              <v-icon v-else v-bind="attrs" v-on="on" color="error">
                mdi-lock-outline
              </v-icon>
            </template>
            <span v-if="item.active">Активный</span>
            <span v-else>Неактивный</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="checkDate(item.dateEnd)"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                mdi-clock-check-outline
              </v-icon>
              <v-icon v-else color="error" v-bind="attrs" v-on="on">
                mdi-clock-alert-outline
              </v-icon>
            </template>
            <span v-if="checkDate(item.dateEnd)">Действительный</span>
            <span v-else>Просрочен</span>
          </v-tooltip>
        </td>
      </template>

      <template #item.start="{ item }">
        <td>
          {{ formatDateTime(item.dateStart) }}
        </td>
      </template>

      <template #item.end="{ item }">
        <td>
          {{ formatDateTime(item.dateEnd) }}
        </td>
      </template>

      <template #item.actions="{item}">
        <td>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon @click.stop="copyData(item)">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-link
                </v-icon>
              </v-btn>
            </template>
            <span>Скопировать</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PassEditor from "./PassEditor.vue";
import {
  getPass,
  createPass,
  updatePass,
  getPassById
} from "../../services/pass.service";
import { withNotifications } from "@/utils/notifications";
import { formatDate, formatDateTime } from "@/utils/date-format";
import { PASS_EDIT_ROUTE } from "@/router/names";
import { currentUser } from "../../services/auth.service";
import qs from "querystring";

export default {
  components: { PassEditor },
  data() {
    return {
      headers: [
        { text: "ИД", value: "id" },
        { text: "ФИО", value: "surname,name,patronymic" },
        { text: "Назначение", value: "category.name" },
        { text: "Начало", value: "start" },
        { text: "Конец", value: "end" },
        { text: "Статус", value: "status" },
        { text: "Действия", value: "actions" }
      ],
      items: [],
      item: {},

      loading: false,
      editor: false,
      message: "",

      currentUserManagement: [],

      params: {
        search: "",
        categoryId: ""
      }
    };
  },

  async mounted() {
    await this.load();
    await this.getManagementByCurrentUser();
  },

  watch: {
    editor: {
      async handler(v) {
        if (!v) {
          this.item = {};
        }
      }
    },

    params: {
      async handler() {
        await this.load();
      },
      deep: true
    },

    "params.categoryId": {
      async handler(value) {
        const query = qs.stringify({
          ...this.$route.query,
          categoryId: value
        });

        await this.$router.replace(
          `${this.$route.path}${query && "?" + query}`
        );
      }
    }
  },

  methods: {
    createPass,
    updatePass,
    getPassById,
    formatDate,
    formatDateTime,
    currentUser,

    copyData(item) {
      this.message = `Посетитель: ${item.surname} ${
        item.name
      } ${item.patronymic || ""}
Назначение: ${item.category.name}
Описание: ${item.description}
Срок действия пропуска: с ${formatDateTime(item.dateStart)} по ${formatDateTime(
        item.dateEnd
      )} 
      
Ссылка на личный кабинет: ${window.location.origin}/lk?uid=${item.uid}`;

      navigator.clipboard.writeText(this.message);
    },

    checkDate(dateEnd) {
      return new Date(dateEnd) > Date.now() ? true : false;
    },

    async getManagementByCurrentUser() {
      const currentUser = await this.currentUser();
      this.currentUserManagement = currentUser.management.map(o => o.category);
    },

    async load() {
      this.loading = true;
      await withNotifications(async () => {
        this.items = await getPass(this.params);
      });
      this.loading = false;
    },

    async editPass(item) {
      await this.$router.push({
        name: PASS_EDIT_ROUTE,
        params: { passId: item.id }
      });
    },

    save(pass) {
      withNotifications(async () => {
        if (pass.id) {
          await updatePass(pass);
        } else {
          await createPass(pass);
        }
        this.load();
      });
    },

    open(item) {
      this.item = item;
      this.editor = true;
    },

    close() {
      this.editor = false;
    }
  }
};
</script>
