<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <template v-if="!globalSearch">
        <create-user-dialog :group="group" @created="loadUsers" />
        <create-multiple-users :group="group" @created="loadUsers" />
        <v-btn
          v-if="this.session.permission.isAllow('elem', 'download_data')"
          text
          color="primary"
          @click="
            downloadData(
              pagination,
              tableOptions,
              users.totalElements,
              search,
              group,
              categories,
              pupilGroups
            )
          "
        >
          <v-icon left>mdi-cloud-download</v-icon> Выгрузить пароли
        </v-btn>
      </template>

      <v-spacer />

      <v-text-field
        v-model="search"
        style="max-width: 320px"
        hide-details
        clearable
        single-line
        prepend-icon="mdi-magnify"
        placeholder="Поиск по контингенту..."
        :autofocus="globalSearch"
      />

      <v-btn icon @click="loadUsers"><v-icon>mdi-reload</v-icon></v-btn>
    </v-toolbar>

    <v-divider />

    <filter-user-parameters
      v-if="!globalSearch"
      :tableOptions="tableOptions"
      :group="group"
      :categories="categories"
      :courses="courseList"
      :groupNames="groupListByCourse"
    />

    <v-divider />

    <v-data-table
      dense
      :headers.sync="headers"
      :items="users.content"
      :item-class="itemClass"
      :sort-by.sync="tableOptions.sort"
      :sort-desc.sync="tableOptions.descending"
      :options.sync="pagination"
      :server-items-length="users && users.totalElements"
      :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
      @click:row="editUser"
    >
      <template #item.surname,name,patronymic="{ item }">
        <td>{{ item.surname }} {{ item.name }} {{ item.patronymic }}</td>
      </template>

      <template #item.active="{ item }">
        <v-simple-checkbox
          :id="item.id + '-active'"
          :value="item.active"
          disabled
        />
      </template>

      <template #item.validPhoto="{ item }">
        <v-icon :color="item.validPhoto ? 'green' : 'red'">mdi-camera</v-icon>
      </template>

      <template #item.identifiers="{ item }">
        <div class="flex">
          <v-tooltip
            bottom
            v-for="identifier in item.identifiers"
            :key="identifier.id"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="getIdentifierColor(identifier.type)"
              >
                {{ getIdentifierIcon(identifier.type) }}
              </v-icon>
            </template>

            {{ getIdentifierName(identifier.type) }}
          </v-tooltip>
        </div>
      </template>

      <template #item.tags="{ item }">
        <span v-for="obj in item.tags" :key="obj.tagId">
          {{ upperFirst(allTags.find(tag => tag.id === obj.tagId).name) }}
        </span>
      </template>

      <template #item.archival="{ item }">
        <span v-if="item.archival">в архиве</span>
        <span v-else>не в архиве</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  getIdentifierColor,
  getIdentifierIcon,
  getIdentifierName
} from "../../constants/identifiers.constants";
import { debounce, upperFirst } from "lodash";
import CreateUserDialog from "./AdditionUser/CreateUserDialog";

import { findUser, getCategoryList } from "../../services/user.service";
import { getPupilGroupList } from "../../services/category.service";
import { getManagement } from "@/services/session.service";
import { STAFF_EDIT_ROUTE, USERS_EDIT_ROUTE } from "@/router/names";
import CreateMultipleUsers from "./AdditionUser/CreateMultipleUsers";
import qs from "querystring";
import { PPS_EDIT_ROUTE } from "../../router/names";
import { getTags } from "../../services/tags.service";

import FilterUserParameters from "./FilterUserParameters.vue";
import { session } from "../../services/session.service";
import { downloadData } from "../../services/dataExport.service";

export default {
  name: "ListTable",
  components: {
    CreateMultipleUsers,
    CreateUserDialog,
    FilterUserParameters
  },

  props: {
    globalSearch: {
      type: Boolean,
      default() {
        return false;
      }
    },
    group: {
      type: String,
      default() {
        return "null";
      }
    }
  },

  data: () => ({
    headers: [
      {
        text: "#",
        align: "start",
        value: "index"
      },
      {
        text: "ИД",
        align: "start",
        value: "id"
      },
      {
        text: "ФИО",
        align: "start",
        sortable: true,
        value: "surname,name,patronymic"
      },
      {
        text: "Описание",
        align: "start",
        sortable: true,
        value: "description"
      },
      {
        text: "Активный",
        align: "start",
        sortable: true,
        value: "active"
      },
      {
        text: "Валидация",
        align: "start",
        sortable: true,
        value: "validPhoto"
      },
      {
        text: "Идентификаторы",
        align: "start",
        sortable: false,
        value: "identifiers"
      },
      {
        text: "Метки",
        align: "start",
        sortable: false,
        value: "tags"
      },
      {
        text: "Архив",
        align: "start",
        sortable: false,
        value: "archival"
      }
    ],
    categories: [],
    courses: [],
    userGroup: [
      { name: "Студенты", value: "ENTRANT" },
      { name: "Сотрудники", value: "STAFF" },
      { name: "ППС", value: "PPS" }
    ],
    validItems: [
      { text: "Требуют валидации", value: false },
      { text: "Валидные", value: true }
    ],
    session: session,

    tableOptions: {
      sort: "archival,surname,name,patronymic",
      descending: false,
      page: 0,
      totalDesserts: 0,
      categoryId: "",
      pupilCourse: "",
      pupilGroupId: "",
      validated: null,
      archival: null
    },

    search: "",
    pagination: {},
    users: { content: [] },
    allTags: [],
    pupilGroups: []
  }),

  computed: {
    loadUsersDebounced() {
      return debounce(this.loadUsers, 500, {
        leading: true,
        maxWait: 1000
      });
    },

    havePupilGroup() {
      return this.group.toUpperCase() == "ENTRANT" ? true : null;
    },

    courseList() {
      return Array.from(new Set(this.pupilGroups.map(el => el.course))).sort(
        (a, b) => a - b
      );
    },

    groupListByCourse() {
      return this.pupilGroups.filter(
        i => i.course === this.tableOptions.pupilCourse
      );
    }
  },

  watch: {
    tableOptions: {
      async handler() {
        await this.loadUsers();
      },
      deep: true
    },

    pagination: {
      async handler(newPagination) {
        const { page, itemsPerPage } = newPagination;
        this.tableOptions.page = page - 1;
        this.tableOptions.size = itemsPerPage;

        await this.loadUsersDebounced();
      },
      deep: true
    },

    "tableOptions.categoryId": {
      async handler(value) {
        await this.loadPupilGroups();
        // if (!this.$route.query.course) {
        //   this.clearTableOptionsFiled(["pupilCourse", "pupilGroupId"]);
        // }

        const query = qs.stringify({
          ...this.$route.query,
          categoryId: value
        });

        if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
          await this.$router.replace(
            `${this.$route.path}${query && "?" + query}`
          );
        }
      }
    },

    "tableOptions.pupilCourse": {
      async handler(value) {
        // if (this.$route.query.pupilGroupId) {
        //   this.clearTableOptionsFiled(["pupilGroupId"]);
        // }

        const query = qs.stringify({
          ...this.$route.query,
          pupilCourse: value
        });

        if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
          await this.$router.replace(
            `${this.$route.path}${query && "?" + query}`
          );
        }
      }
    },

    "tableOptions.pupilGroupId": {
      async handler(value) {
        const query = qs.stringify({
          ...this.$route.query,
          pupilGroupId: value
        });

        if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
          await this.$router.replace(
            `${this.$route.path}${query && "?" + query}`
          );
        }
      }
    },

    "tableOptions.validated": {
      async handler(value) {
        const query = qs.stringify({
          ...this.$route.query,
          validated: value
        });

        if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
          await this.$router.replace(
            `${this.$route.path}${query && "?" + query}`
          );
        }
      }
    },

    "tableOptions.archival": {
      async handler(value) {
        const query = qs.stringify({
          ...this.$route.query,
          archival: value
        });

        if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
          await this.$router.replace(
            `${this.$route.path}${query && "?" + query}`
          );
        }
      }
    },

    async search() {
      const query = qs.stringify({
        ...this.$route.query,
        search: (this.search || "").trim()
      });
      if (query.localeCompare(qs.stringify(this.$route.query)) != 0) {
        await this.$router.replace(
          `${this.$route.path}${query && "?" + query}`
        );
      }
      this.pagination = { ...this.pagination, page: 1 };
    }
  },

  created() {
    try {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (Object.keys(query).length) {
        this.search = query.search;

        this.tableOptions = {
          ...this.tableOptions,
          categoryId: +query.categoryId || null,
          pupilCourse: +query.pupilCourse || null,
          pupilGroupId: +query.pupilGroupId || null,
          validated:
            query.validated == "true"
              ? true
              : query.validated == "false"
              ? false
              : null,
          archival:
            query.archival == "true"
              ? true
              : query.validated == "false"
              ? false
              : null
        };
      }

      this.getAllTags();
    } catch (e) {
      this.$notify({
        text: e.response ? e.response.data.message : e,
        type: "error"
      });
    }
  },

  mounted() {
    this.loadCategories();
  },

  methods: {
    getIdentifierName,
    getIdentifierIcon,
    getManagement,
    getIdentifierColor,
    upperFirst,
    downloadData,

    async loadUsers() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      const {
        categoryId,
        pupilCourse,
        pupilGroupId,
        validated,
        archival
      } = this.tableOptions;

      const userGroup =
        (this.group !== "null" && this.group.toUpperCase()) || this.group;

      this.users = await findUser({
        page: page - 1,
        size: itemsPerPage,
        sortBy: sortBy.join(","),
        descending: sortDesc,
        archival,
        havePupilGroup: userGroup === "ENTRANT" ? true : null,
        categoryId,
        pupilCourse,
        pupilGroupId,
        search: this.search,
        userGroup,
        validated
      });

      this.users.content = this.users.content.map((user, index) => ({
        index: index + 1 + this.users.number * this.users.size,
        ...user
      }));
    },

    async loadCategories() {
      if (this.globalSearch) return;
      try {
        this.categories = await getCategoryList(this.group);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async loadPupilGroups() {
      try {
        if (this.tableOptions.categoryId) {
          this.pupilGroups = await getPupilGroupList({
            categoryId: this.tableOptions.categoryId
          });
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getAllTags() {
      try {
        this.allTags = await getTags();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async editUser(item) {
      const [group] = item.groups.map(g => g.toLowerCase());
      const routeName =
        group === "staff"
          ? STAFF_EDIT_ROUTE
          : group === "entrant"
          ? USERS_EDIT_ROUTE
          : PPS_EDIT_ROUTE;

      await this.$router.push({
        name: routeName,
        params: { userId: item.id }
      });
    },

    clearTableOptionsFiled(fields = []) {
      fields.forEach(field => (this.tableOptions[field] = null));
    },

    itemClass(item) {
      const classes = {
        archival: item.archival
      };

      return Object.keys(classes)
        .filter(el => classes[el])
        .join(" ");
    }
  }
};
</script>

<style>
tr.archival {
  background-color: #f5f5f5;
}
</style>
