import { app } from "../main";

export async function withNotifications(cb) {
  try {
    await cb();
  } catch (e) {
    app.$notify({
      text: e.response ? e.response.data.message : e,
      type: "error"
    });
  }
}
