<template>
  <v-card outlined>
    <discipline-toolbar @load="getParams" />

    <v-divider />

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="discipline.content"
        :options.sync="tableOptions"
        :server-items-length="discipline && discipline.totalElements"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
        dense
      >
        <template #item.fio="{item}">
          <p class="ma-0">
            <router-link
              class="black--text"
              :to="{
                name: `${
                  params.categoryType === 'STAFF'
                    ? 'STAFF_EDIT_ROUTE'
                    : params.categoryType === 'ENTRANT'
                    ? 'USERS_EDIT_ROUTE'
                    : 'PPS_EDIT_ROUTE'
                }`,
                params: {
                  group: params.categoryType,
                  userId: item.userId
                }
              }"
              >{{ item.fio }}</router-link
            >
          </p>

          <p class="ma-0 pb-2 grey--text" style="font-size: 0.8rem">
            {{ `(${item.description})` }}
          </p>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import DisciplineToolbar from "./DisciplineToolbar";
import { getDiscipline } from "../../../services/device.service";

export default {
  name: "ControllerDiscipline",
  components: { DisciplineToolbar },

  data: () => ({
    discipline: {},
    tableOptions: {},
    params: {
      categoryType: "STAFF"
    },

    headers: [
      { text: "ФИО", value: "fio" },
      { text: "Дата", value: "date" },
      { text: "Первый проход", value: "first" },
      { text: "Последний проход", value: "last" },
      { text: "Контроллер", value: "controllerName" }
    ]
  }),

  watch: {
    tableOptions: {
      handler() {
        this.pageParams();

        this.loadDiscipline(this.params);
      }
    }
  },

  methods: {
    async loadDiscipline(params) {
      try {
        this.discipline = await getDiscipline(params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getParams(params) {
      this.params = params;
      this.pageParams();

      this.loadDiscipline(this.params);
    },

    pageParams() {
      const { page, itemsPerPage } = this.tableOptions;

      this.params.page = page - 1;
      this.params.size = itemsPerPage;
    }
  }
};
</script>
