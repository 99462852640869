<template>
  <page>
    <list-table :group="$route.meta.type" />
  </page>
</template>

<script>
import ListTable from "../../components/user-management/ListTable";
import Page from "../../components/Page";

export default {
  name: "PpsList",
  components: { ListTable, Page }
};
</script>
