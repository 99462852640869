import { ProxyStorage } from "@/factories/ProxyStorage";
import { defineAccessFor } from "@/permissions/ability";

const session = new ProxyStorage({ name: "CAMPUS_SESSION" });

function initSession() {
  try {
    const storage = sessionStorage.getItem("CAMPUS_SESSION");
    const { user } = JSON.parse(storage);

    if (!user) return false;
    session.user = user;

    setPermission(user);

    return true;
  } catch {
    return false;
  }
}

function setUser(user) {
  session.user = user;
}

function setPermission(user) {
  session.permission = defineAccessFor(user);
}

function hasSession() {
  return Boolean(session && session.user);
}

function isAllow(target, value) {
  const fun = session.permission.isAllow;

  if (!fun) return () => false;

  return fun(target, value);
}

function getManagement(type = "") {
  if (hasSession() && session.user) {
    return session.user.management
      .map(c => c.category)
      .filter(c => c.type.toLowerCase() === type.toLowerCase());
  } else {
    return null;
  }
}

export {
  session,
  setUser,
  setPermission,
  hasSession,
  getManagement,
  initSession,
  isAllow
};
