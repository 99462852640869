<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-plus</v-icon> Добавить
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <addition-form
            v-model="userModel"
            :group="group"
            @update="getCategoryList"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="resetForm">Очистить</v-btn>
        <v-spacer />
        <v-btn color="red" text @click="close">
          Отмена
        </v-btn>
        <v-btn
          :disabled="!valid || loading"
          type="submit"
          color="primary"
          :loading="loading"
          text
          @click="createUser"
        >
          Создать пользователя
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createUser } from "../../../services/user.service";
import { serialize } from "../../../services/serialize.service";
import AdditionForm from "./AdditionForm";

export default {
  name: "CreateUserDialog",
  components: { AdditionForm },
  props: {
    group: { type: String, required: true }
  },

  data: () => ({
    show: false,
    valid: false,
    loading: false,

    categoryList: [],
    coursesList: [],
    groupsList: [],

    userModel: {
      surname: "",
      name: "",
      patronymic: "",
      categoryId: null
    }
  }),

  computed: {
    title() {
      const title = {
        staff: "Новый сотрудник",
        entrant: "Новый студент"
      };

      return title[this.group];
    }
  },

  methods: {
    async createUser() {
      try {
        this.loading = true;

        const serializedForm = serialize(this.userModel);

        let newUser = await createUser(
          serializedForm.categoryId,
          serializedForm
        );

        await this.$router.push({
          name: `${
            newUser.mainGroup.name === "ENTRANT"
              ? "USERS"
              : newUser.mainGroup.name
          }_EDIT_ROUTE`,
          params: { userId: newUser.id }
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    getCategoryList(departmentsAndGroups) {
      this.userModel.categoryId = departmentsAndGroups.categoryId;
      this.userModel.pupilGroup = departmentsAndGroups.pupilGroup;
    },

    resetForm() {
      this.$refs.form.reset();
    },

    close() {
      this.resetForm();
      this.show = false;
      this.loading = false;
    }
  }
};
</script>
