<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-plus</v-icon> Добавить несколько
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Добавить несколько индефикаторов банка</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="code"
                label="Список идентификаторов"
                :rules="[v => !!v || 'Это обязательное поле']"
                :placeholder="`Идентификаторы...`"
                outlined
                autofocus
                clearable
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <div :style="{ minHeight: '1.8em', fontSize: '1em' }">{{ result }}</div>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="resetForm">Очистить</v-btn>
        <v-spacer />
        <v-btn @click="close" text color="red">Отмена</v-btn>
        <v-btn
          @click="createMultipleIdentifiersStorage"
          text
          color="primary"
          :disabled="!valid"
        >
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createMultipleIdentifiersStorage } from "../../services/identifier-storage.service";

export default {
  name: "CreateMultipleIdentifiersDialog",

  data: () => ({
    show: false,
    valid: false,
    loading: false,

    result: "",
    code: ""
  }),

  props: {
    update: {
      type: Boolean,
      default: () => false
    }
  },

  methods: {
    async createMultipleIdentifiersStorage() {
      try {
        this.loading = true;

        let codes = this.code
          .split(/[;,\n\s\t]+/)
          .map(c => c.trim())
          .filter(code => /^[0-9]+$/.test(code));

        if (codes.length == 0) {
          throw Error("Нет правильно заполненных идентификаторов");
        }

        let res = (await createMultipleIdentifiersStorage(codes)) || [];

        this.result = `Добавлено идентификаторов: ${res.length}`;

        this.$emit("input", !this.update);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.$refs.form.reset();
      this.result = "";
    },

    close() {
      this.resetForm();
      this.show = false;
      this.result = "";
      this.loading = false;
    }
  }
};
</script>
