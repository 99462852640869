<template>
  <div>
    <v-select
      v-model="categoryOptions.categoryType"
      :items="categoriesTypes"
      item-text="name"
      item-value="type"
      placeholder="Категория"
      dense
      hide-details
    />

    <v-select
      v-model="categoryOptions.categoryId"
      :items="categories"
      item-text="name"
      item-value="id"
      :disabled="!categoryOptions.categoryType"
      :placeholder="
        categoryOptions.categoryType === 'STAFF' ? 'Подразделение' : 'Факультет'
      "
      clearable
      dense
      hide-details
      @click:clear="clearFields(['course', 'groupName'])"
    />

    <v-select
      v-if="categoryOptions.categoryType === 'ENTRANT'"
      v-model="categoryOptions.course"
      :items="courses"
      item-value="id"
      item-text="name"
      :disabled="disabledCourses"
      placeholder="Курс / Класс"
      clearable
      dense
      hide-details
      @click:clear="clearFields(['groupName'])"
    />

    <v-select
      v-if="categoryOptions.categoryType === 'ENTRANT'"
      v-model="categoryOptions.groupName"
      :items="groupsNames"
      item-value="id"
      item-text="name"
      :disabled="!categoryOptions.course"
      placeholder="Группа / Литер"
      clearable
      dense
      hide-details
    />
  </div>
</template>

<script>
import {
  getCategoryList,
  getCoursesList
} from "../../../services/category.service";
import { getGroupsList } from "../../../services/user.service";

export default {
  name: "CategoryType",

  data: () => ({
    courses: [],
    categories: [],
    categoriesTypes: [
      { name: "Сотрудники", type: "staff".toUpperCase() },
      { name: "Студенты", type: "entrant".toUpperCase() }
    ],
    groupsNames: [],

    categoryOptions: {
      categoryType: "STAFF",
      categoryId: null,
      course: null,
      groupName: ""
    }
  }),

  computed: {
    disabledCourses() {
      return (
        this.getCategoryType(this.categoryOptions.categoryId) !== "ENTRANT"
      );
    }
  },

  created() {
    this.$emit("get-category", this.categoryOptions);
    this.getCategories();
  },

  watch: {
    categoryOptions: {
      handler() {
        this.$emit("get-category", this.categoryOptions);
      },
      deep: true
    },

    "categoryOptions.categoryType": {
      handler() {
        this.getCategories();
      }
    },

    "categoryOptions.categoryId": {
      handler() {
        this.clearFields(["course", "groupName"]);
        this.getCourses();
      }
    },

    "categoryOptions.course": {
      handler() {
        this.clearFields(["groupName"]);
        this.loadGroups();
      }
    }
  },

  methods: {
    async getCourses() {
      try {
        if (this.categoryOptions.categoryId) {
          this.courses = await getCoursesList(this.categoryOptions.categoryId);
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getCategories() {
      try {
        if (this.categoryOptions.categoryType) {
          this.categories = await getCategoryList(
            this.categoryOptions.categoryType
          );
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getCategoryType(categoryId) {
      const category = this.categories.find(c => c.id === categoryId);

      if (category) {
        return category.type;
      }
    },

    async loadGroups() {
      try {
        if (this.categoryOptions.categoryId && this.categoryOptions.course) {
          this.groupsNames = await getGroupsList(
            this.categoryOptions.categoryId,
            this.categoryOptions.course
          );
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    clearFields(fields = []) {
      fields.forEach(field => (this.categoryOptions[field] = null));
    }
  }
};
</script>
