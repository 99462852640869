<script>
import IdentifiersStorageList from "../../components/pass/IdentifiersStorageList.vue";

export default {
  components: { IdentifiersStorageList },

  data() {
    return {
      tab: null
    };
  }
};
</script>

<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Идентификаторы</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <IdentifiersStorageList />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
