<template>
  <v-dialog v-model="show" persistent width="720">
    <template #activator="{on}">
      <v-btn v-on="on" @click="createVoting" text color="primary">
        <v-icon left>{{ activatorButtonIcon }}</v-icon>
        {{ activatorButtonTitle }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Изменение голосования</v-card-title>

      <v-card-text>
        <v-form>
          <v-textarea
            v-model="votingModel.title"
            rows="2"
            no-resize
            clearable
            label="Текст голосования"
          />

          <div class="d-flex align-center mb-4">
            <v-checkbox
              hide-details
              v-model="votingModel.multiple"
              label="Множественный выбор"
              class="mr-4"
            />

            <v-checkbox
              hide-details
              v-model="votingModel.view"
              label="Анонимный опрос"
            />
          </div>

          <v-menu
            v-model="showDatepicker"
            ref="menu"
            :close-on-content-click="false"
            :return-value.sync="votingModel.endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="votingModel.endDate"
                label="Дата окончания голосования"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="votingModel.endDate"
              no-title
              scrollable
              @change="$refs.menu.save(votingModel.endDate)"
              class="pb-2"
            />
          </v-menu>

          <template>
            <p class="caption">Варианты ответов</p>
            <v-text-field
              v-for="(variant, index) in votingModel.variants"
              v-model="variant.text"
              readonly
              :key="index"
              dense
              persistent-hint
              :hint="variant.archival ? 'В архиве' : null"
            >
              <template #prepend>
                <v-btn icon text disabled>
                  {{ index + 1 }}
                </v-btn>
              </template>

              <template #append-outer>
                <v-btn
                  @click="toggleVariant(variant, index)"
                  text
                  icon
                  :color="variant.archival ? 'success' : 'error'"
                >
                  <v-icon>
                    {{ variant.archival ? "mdi-refresh" : "mdi-delete" }}
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-text-field
              v-model="variantText"
              class="mt-4"
              label="Новый вариант"
            >
              <template #append-outer>
                <v-btn
                  :disabled="!variantText"
                  @click="addVariant"
                  text
                  color="primary"
                >
                  Добавить
                </v-btn>
              </template>
            </v-text-field>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" text>Отмена</v-btn>
        <v-btn text color="primary" @click="updateVoting">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  createVotingForNews,
  createVotingVariant,
  deleteVotingVariant,
  updateVoting,
  updateVotingVariant
} from "../../services/news.service";

export default {
  name: "VotingEditor",

  props: {
    voting: Object,
    newsId: Number
  },

  data() {
    return {
      show: false,

      variantText: "",

      showDatepicker: false,

      votingModel: {
        archival: false,
        endDate: null,
        multiple: false,
        title: "",
        variants: [],
        view: true
      }
    };
  },

  computed: {
    activatorButtonTitle() {
      return this.voting ? "Изменить голосование" : "Создать голосование";
    },

    activatorButtonIcon() {
      return this.voting ? "mdi-pencil" : "mdi-plus";
    },

    editorTitle() {
      return this.voting ? "Изменение голосования" : "Создание голосования";
    }
  },

  mounted() {
    if (this.voting) {
      Vue.set(this, "votingModel", { ...this.votingModel, ...this.voting });
    }
  },

  methods: {
    close() {
      this.show = false;
    },

    async createVoting() {
      if (this.voting) return;

      try {
        const voting = await createVotingForNews(this.newsId, this.votingModel);
        this.votingModel = voting;
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message.message : e,
          type: "error"
        });
      }
    },

    async addVariant() {
      try {
        const variant = await createVotingVariant(this.votingModel.id, {
          text: this.variantText
        });

        this.votingModel.variants.push(variant);
        this.variantText = "";
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message.message : e,
          type: "error"
        });
      }
    },

    async toggleVariant(variant, index) {
      try {
        let result = null;

        if (variant.archival) {
          result = await updateVotingVariant({ ...variant, archival: false });
        } else {
          result = await deleteVotingVariant(variant);
        }

        this.votingModel.variants[index].archival = result.archival;
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message.message : e,
          type: "error"
        });
      }
    },

    async updateVoting() {
      try {
        this.voting
          ? await updateVoting(this.votingModel)
          : await createVotingForNews(this.newsId, this.votingModel);
        this.close();
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
