<template>
  <div>
    <div>{{ formatDateTime(date) }}</div>
    <a @click="goToUser(user)" class="mr-2">
      {{ `${user.name} ${user.surname}` }}
    </a>
    <span class="text-caption grey--text">({{ user.description }})</span>
  </div>
</template>

<script>
import * as dateFormat from "@/utils/date-format";
import { goToUser } from "@/utils/router";

export default {
  props: {
    user: Object,
    date: String
  },

  methods: {
    ...dateFormat,
    goToUser
  }
};
</script>
