<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Создатель</v-card-title>

      <v-divider />

      <v-card-text v-if="creator">
        {{
          `${creator.name} ${creator.surname} ${creator.patronymic || ""}, ${
            creator.description
          }`
        }}
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "PassCreatorInformation",

  props: {
    creator: { type: Object }
  }
};
</script>
