<template>
  <div>
    <v-divider class="mb-4" />

    <div class="d-flex align-center justify-space-between">
      <h5>Голосование</h5>
      <v-switch
        v-model="voting.archival"
        label="В архиве"
        dense
        class="ma-0 ml-4"
        hide-details
        @change="toggleVotingArchive"
      />
    </div>

    <v-chip-group dark>
      <v-chip small disabled v-if="voting.multiple">
        Множественный выбор
      </v-chip>
      <v-chip small disabled v-if="voting.view">Анонимное</v-chip>
    </v-chip-group>

    <h3 class="mb-4">{{ voting.title || "Голосование без темы" }}</h3>

    <div v-for="variant in voting.variants" :key="variant.id" class="mb-2">
      <p v-if="variant.archival" class="red--text ma-0 text-caption">
        В архиве
      </p>

      <v-progress-linear
        :value="variant.percent"
        height="16"
        rounded
        :color="variant.winner ? 'success lighten-1' : 'grey lighten-1'"
      >
        {{ `${variant.percent.toFixed(2)} %` }}
      </v-progress-linear>

      <div class="d-flex align-center justify-space-between">
        <h4>{{ variant.text || "Вариант без названия" }}</h4>

        <p class="caption">{{ `К-во голосов: ${variant.count}` }}</p>
      </div>
    </div>

    <p class="caption">{{ votingEnd }}</p>
  </div>
</template>

<script>
import { deleteVoting, updateVoting } from "../../services/news.service";

export default {
  name: "Voting",
  props: {
    voting: { required: true, type: Object }
  },

  computed: {
    votingEnd() {
      const date = this.voting.endDate;
      return date
        ? `Дата окончания: ${this.formatDate(date)} ${this.formatTime(date)}`
        : "Без даты окончания";
    }
  },

  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return dateObj.toLocaleDateString("ru-RU", options);
    },

    formatTime(date) {
      const dateObj = new Date(date);
      const options = { hour: "2-digit", minute: "2-digit" };
      return dateObj.toLocaleTimeString("ru-RU", options);
    },

    toggleVotingArchive() {
      return this.voting.archival
        ? this.sendVotingToArchive()
        : this.removeVotingFromArchive();
    },

    async sendVotingToArchive() {
      try {
        await deleteVoting(this.voting.id);
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async removeVotingFromArchive() {
      try {
        await updateVoting(this.voting);
        this.voting.archival = false;

        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
