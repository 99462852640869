<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(controllers, name) in dataset" :key="name" cols="12">
        <v-card outlined>
          <v-card-title>{{ name }}</v-card-title>

          <v-simple-table>
            <thead>
              <tr>
                <th>#</th>
                <th>Название</th>
                <th>Активен?</th>
                <th>Шлюзы</th>
                <th>Обновлено</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(controller, index) in controllers"
                :key="controller.id"
              >
                <td>{{ index + 1 }}</td>
                <td
                  @click="copyHost(controller.host)"
                  style="cursor: pointer"
                  class="col-4"
                >
                  {{ controller.name }}
                </td>
                <td class="col-1">
                  <v-badge
                    :color="controller.active ? 'green' : '#808080'"
                    dot
                  />
                </td>
                <td class="col-3">
                  <v-tooltip
                    v-for="gateway in controller.gateways"
                    :key="gateway.id"
                    bottom
                  >
                    <template #activator="{on, attr}">
                      <v-icon
                        v-if="gateway.type === 'door'"
                        v-bind="attr"
                        v-on="on"
                        :color="
                          gateway.active && controller.active
                            ? 'green'
                            : '#808080'
                        "
                      >
                        mdi-door
                      </v-icon>
                      <v-icon
                        v-else
                        v-bind="attr"
                        v-on="on"
                        :color="
                          gateway.active && controller.active
                            ? 'green'
                            : '#808080'
                        "
                      >
                        mdi-turnstile
                      </v-icon>
                    </template>
                    {{ gateway.name }}
                  </v-tooltip>
                </td>
                <td>
                  {{
                    new Date(controller.modification).toLocaleString("ru-RU")
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { groupBy, sortBy } from "lodash";
import { getControllers } from "../../services/device.service";

export default {
  name: "ControllersStatistics",

  data: () => ({
    dataset: {},

    timer: null
  }),

  mounted() {
    this.getControllers();

    this.timer = setInterval(this.getControllers, 5 * 1000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    async getControllers() {
      try {
        const controllers = await getControllers();

        this.dataset = groupBy(
          sortBy(
            controllers.map(controller => ({
              ...controller,
              gateways:
                controller.gateways.length != 0
                  ? sortBy(controller.gateways, ["name"])
                  : []
            })),
            controller => [
              controller.accessPoint?.name || controller.typeName,
              controller.name
            ]
          ).reverse(),
          controller => controller.accessPoint?.name || controller.typeName
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    copyHost(host) {
      navigator.clipboard.writeText(host).then(
        () => {
          this.$notify({
            text: "Хост скопирован!",
            type: "success"
          });
        },
        () => {
          this.$notify({
            text: "Хост не может быть скопирован",
            type: "error"
          });
        }
      );
    }
  }
};
</script>
