<template>
  <v-tooltip bottom>
    <template #activator="{on, attr}">
      <v-icon v-bind="attr" v-on="on" :color="colors[type]">
        {{ icons[type] }}
      </v-icon>
    </template>

    {{ labels[type] }}
  </v-tooltip>
</template>

<script>
export default {
  name: "OperationType",

  props: {
    type: String
  },

  data: () => ({
    icons: {
      add: "mdi-plus-circle",
      update: "mdi-refresh-circle",
      delete: "mdi-delete-circle"
    },

    colors: {
      add: "green",
      update: "primary",
      delete: "red"
    },

    labels: {
      add: "Добавление",
      update: "Обновление",
      delete: "Удаление"
    }
  })
};
</script>

<style scoped></style>
