<template>
  <v-card outlined>
    <news-toolbar v-model="params" @update="getNews" />

    <v-divider />

    <v-data-table
      :headers="headers"
      :items="news.content"
      item-key="id"
      :options.sync="tableOptions"
      :server-items-length="news && news.totalElements"
      :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
      hide-default-header
    >
      <template #item.text="{item}">
        <news-card :news="item" @update="getNews" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import NewsToolbar from "./NewsToolbar";
import NewsCard from "./NewsCard";
import { getNews } from "../../services/news.service";

export default {
  name: "NewsList",
  components: { NewsToolbar, NewsCard },
  data() {
    return {
      news: [],
      headers: [{ text: "", value: "text" }],

      tableOptions: {},
      params: {
        archival: false,
        categoryId: null,
        course: null
      }
    };
  },

  watch: {
    tableOptions: {
      handler() {
        this.getNews();
      }
    },

    params: {
      handler() {
        this.getNews();
      },
      deep: true
    }
  },

  mounted() {
    this.getNews();
  },

  methods: {
    async getNews() {
      const { page, itemsPerPage } = this.tableOptions;
      const { archival, course, categoryId } = this.params;

      const params = {
        page: page - 1,
        size: itemsPerPage,
        archival,
        categoryId,
        course
      };

      try {
        this.news = await getNews(params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
