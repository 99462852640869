<template>
  <page>
    <list-table :globalSearch="true"></list-table>
  </page>
</template>

<script>
import Page from "../../components/Page";
import ListTable from "../../components/user-management/ListTable";

export default {
  name: "UserSearch",
  components: { ListTable, Page }
};
</script>

<style scoped></style>
