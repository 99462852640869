<template>
  <v-dialog v-model="show" max-width="640">
    <template #activator="{on, attr}">
      <v-btn v-on="on" v-bind="attr" text color="primary">
        <v-icon left>mdi-plus</v-icon> Добавить несколько
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <employment-choose :group="group" @update="getEmployment" />

          <v-textarea
            v-model="users"
            label="Список пользователей"
            :rules="[v => !!v || 'Это обязательное поле']"
            :placeholder="`Фамилия Имя Отчество \nФамилия Имя Отчество`"
            outlined
            autofocus
            clearable
            required
            @click:clear="clearUsers"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="red" text @click="close">
          Отмена
        </v-btn>
        <v-btn
          :disabled="disableCreateUsers"
          type="submit"
          color="primary"
          text
          @click="createUsers"
        >
          Добавить {{ getRows }} пользователей
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmploymentChoose from "./EmploymentChoose";
import { createUsers } from "../../../services/user.service";
export default {
  name: "CreateMultipleUsers",
  components: { EmploymentChoose },
  props: {
    group: { type: String, required: true }
  },

  data: () => ({
    show: false,
    valid: false,

    users: "",

    rows: 0,

    employment: {}
  }),

  computed: {
    title() {
      const title = {
        staff: "Новые сотрудники",
        entrant: "Новые студенты"
      };

      return title[this.group];
    },

    getRows() {
      return this.users ? this.users.trim().split(/\n/).length : 0;
    },

    disableCreateUsers() {
      return !this.valid;
    }
  },

  methods: {
    async createUsers() {
      const { pupilGroup, categoryId } = this.employment;

      try {
        const users = [];
        const rows = this.users.trim().split(/\n/);

        rows.forEach(element => {
          const row = element.trim().split(/[\s,;\t]+/);

          if (row.length) {
            const [surname, name, patronymic] = row;

            users.push({
              name,
              surname,
              patronymic,
              groups: [this.group.toUpperCase()],
              pupilGroup: pupilGroup
            });
          }
        });

        await createUsers(categoryId, users);
        this.close();

        this.$emit("created");
      } catch (e) {
        this.error = true;
        this.textError = e.response.data;
      }
    },

    getEmployment(employment) {
      this.employment = employment;
    },

    clearUsers() {
      this.users = "";
      this.error = false;
    },

    close() {
      this.show = false;
      this.error = false;
      this.$refs.form.reset();
    }
  }
};
</script>
