<template>
  <div>
    <v-card outlined>
      <v-card-title>Создать нового пользователя</v-card-title>

      <v-card-text>
        <v-select
          v-model="group"
          :items="groupsList"
          item-text="shortName"
          item-value="name"
          label="Группа"
          class="my-3"
          dense
        />

        <v-form ref="form" v-model="valid">
          <addition-form
            v-model="userModel"
            @input="getCreatingUser"
            :group="group"
            @update="getCategory"
          />
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid || loading"
          type="submit"
          color="primary"
          :loading="loading"
          text
          @click="createUser"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>

    <founded-users-list-table :creating-user="creatingUser" />
  </div>
</template>

<script>
import AdditionForm from "./AdditionForm";
import { serialize } from "../../../services/serialize.service";
import { getGroups } from "../../../services/group.service";
import { createUser } from "../../../services/user.service";
import FoundedUsersListTable from "./FoundedUsersListTable";
export default {
  name: "AddNewUser",
  components: { FoundedUsersListTable, AdditionForm },

  data() {
    return {
      userModel: {
        surname: "",
        name: "",
        patronymic: "",
        categoryId: null
      },
      creatingUser: {},

      group: "",

      groupsList: [],

      valid: false,
      loading: false
    };
  },

  created() {
    this.getGroupsList();
  },

  methods: {
    async getGroupsList() {
      try {
        this.groupsList = await getGroups();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async createUser() {
      try {
        this.loading = true;

        Object.assign(this.userModel, this.employment);
        let serialized = this.serializedForm();
        let newUser = await createUser(serialized.categoryId, serialized);

        await this.editUser(newUser);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    serializedForm() {
      return serialize(this.userModel);
    },

    getCategory(employment) {
      this.employment = employment;
    },

    getCreatingUser(newUser) {
      this.creatingUser = { ...newUser };
    },

    async editUser(item) {
      const [group] = item.groups.map(g => g.toUpperCase());

      await this.$router.push({
        name: `${group === "ENTRANT" ? "USERS" : group}_EDIT_ROUTE`,
        params: { userId: item.id }
      });
    }
  }
};
</script>
