<template>
  <v-row>
    <v-select
      v-model="category.categoryId"
      :items="categories"
      item-text="name"
      item-value="id"
      :placeholder="categoryType === 'ENTRANT' ? 'Факультет' : 'Подразделение'"
      :disabled="categoryType === '' || !categoryType"
      dense
      clearable
      hide-details
      class="col"
    />

    <v-select
      v-if="categoryType === 'ENTRANT'"
      v-model="category.course"
      :items="courses"
      item-text="name"
      item-value="id"
      placeholder="Курс / Класс"
      :disabled="category.categoryId < 0 || !category.categoryId"
      dense
      clearable
      hide-details
      class="col"
    />

    <v-select
      v-if="categoryType === 'ENTRANT'"
      v-model="category.groupName"
      :items="groupsNames"
      dense
      hide-details
      clearable
      item-value="id"
      item-text="name"
      placeholder="Группа / Литер"
      :disabled="category.course < 0 || !category.course"
      class="col"
    />
  </v-row>
</template>

<script>
import {
  getCategoryList,
  getCoursesList
} from "../../../../services/category.service";
import { getGroupsList } from "../../../../services/user.service";

export default {
  name: "Category",
  props: {
    categoryType: { required: true }
  },

  data: () => ({
    category: {
      categoryId: -1,
      course: -1,
      groupName: ""
    },

    categories: [],
    courses: [],
    groupsNames: []
  }),

  watch: {
    category: {
      handler(newCategory) {
        this.$emit("update-category", newCategory);
      },
      deep: true
    },

    categoryType: {
      handler(newVal) {
        if (newVal) {
          this.loadCategories(newVal);
        }
      }
    },

    "category.categoryId": {
      handler(newVal) {
        if (newVal) {
          this.getCourses(newVal);
        }
      }
    },

    "category.course": {
      handler(newVal) {
        if (newVal) {
          this.loadGroups(newVal);
        }
      }
    }
  },

  methods: {
    async loadCategories(categoryType) {
      this.categories = await getCategoryList(categoryType);
    },

    async getCourses(categoryId) {
      try {
        this.courses = await getCoursesList(categoryId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async loadGroups(course) {
      try {
        if (this.category.categoryId && course) {
          this.groupsNames = await getGroupsList(
            this.category.categoryId,
            course
          );
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
