import { sortBy } from "lodash";
import { Api } from "./api.service";

export function getKeys() {
  return Api.get("/access/keys").then(keys => sortBy(keys, "name"));
}

export function createKey(key) {
  return Api.post("/access/keys", key);
}

export function updateKey(key) {
  return Api.put(`/access/keys/${key.id}`, key);
}

export function setArchival(key, archival) {
  return Api.put(`/access/keys/${key.id}/archival`, null, {
    params: { archival }
  });
}

export function addToCategory(keyId, categoryId) {
  return Api.post(`/access/keys/${keyId}/categories/${categoryId}`);
}

export function addAllToCategory(categoryId) {
  return Api.put(`/access/key-access/categories/${categoryId}`, null, {
    params: { accessType: true }
  });
}

export function removeFromCategory(keyId, categoryId) {
  return Api.delete(`/access/keys/${keyId}/categories/${categoryId}`);
}

export function removeAllFromCategory(categoryId) {
  return Api.put(`/access/key-access/categories/${categoryId}`, null, {
    params: { accessType: false }
  });
}

export function getKeyAccessRules(userId) {
  return Api.get(`/access/key-access/users/${userId}`);
}

export function updateKeyAccessRules(keyAccessRules) {
  return Api.put(`/access/key-access`, keyAccessRules);
}

export function deleteKeyAccessRules(keyAccessRules) {
  return Api.delete(`/access/key-access`, { data: keyAccessRules });
}

export function getKeysJournal({ page, size, begin, end }) {
  return Api.get(`/access/keys/journal`, {
    params: { page, size, begin, end }
  });
}
