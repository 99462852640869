<template>
  <v-container fluid>
    <slot />
  </v-container>
</template>

<script>
export default {
  name: "Page"
};
</script>

<style scoped></style>
