var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[(!_vm.globalSearch)?[_c('create-user-dialog',{attrs:{"group":_vm.group},on:{"created":_vm.loadUsers}}),_c('create-multiple-users',{attrs:{"group":_vm.group},on:{"created":_vm.loadUsers}}),(this.session.permission.isAllow('elem', 'download_data'))?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.downloadData(
            _vm.pagination,
            _vm.tableOptions,
            _vm.users.totalElements,
            _vm.search,
            _vm.group,
            _vm.categories,
            _vm.pupilGroups
          )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-download")]),_vm._v(" Выгрузить пароли ")],1):_vm._e()]:_vm._e(),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"hide-details":"","clearable":"","single-line":"","prepend-icon":"mdi-magnify","placeholder":"Поиск по контингенту...","autofocus":_vm.globalSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.loadUsers}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],2),_c('v-divider'),(!_vm.globalSearch)?_c('filter-user-parameters',{attrs:{"tableOptions":_vm.tableOptions,"group":_vm.group,"categories":_vm.categories,"courses":_vm.courseList,"groupNames":_vm.groupListByCourse}}):_vm._e(),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.users.content,"item-class":_vm.itemClass,"sort-by":_vm.tableOptions.sort,"sort-desc":_vm.tableOptions.descending,"options":_vm.pagination,"server-items-length":_vm.users && _vm.users.totalElements,"footer-props":{ itemsPerPageOptions: [25, 50, 100] }},on:{"update:headers":function($event){_vm.headers=$event},"update:sortBy":function($event){return _vm.$set(_vm.tableOptions, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.tableOptions, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.tableOptions, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.tableOptions, "descending", $event)},"update:options":function($event){_vm.pagination=$event},"click:row":_vm.editUser},scopedSlots:_vm._u([{key:"item.surname,name,patronymic",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.surname)+" "+_vm._s(item.name)+" "+_vm._s(item.patronymic))])]}},{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"id":item.id + '-active',"value":item.active,"disabled":""}})]}},{key:"item.validPhoto",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.validPhoto ? 'green' : 'red'}},[_vm._v("mdi-camera")])]}},{key:"item.identifiers",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"flex"},_vm._l((item.identifiers),function(identifier){return _c('v-tooltip',{key:identifier.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.getIdentifierColor(identifier.type)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getIdentifierIcon(identifier.type))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getIdentifierName(identifier.type))+" ")])}),1)]}},{key:"item.tags",fn:function(ref){
          var item = ref.item;
return _vm._l((item.tags),function(obj){return _c('span',{key:obj.tagId},[_vm._v(" "+_vm._s(_vm.upperFirst(_vm.allTags.find(function (tag) { return tag.id === obj.tagId; }).name))+" ")])})}},{key:"item.archival",fn:function(ref){
          var item = ref.item;
return [(item.archival)?_c('span',[_vm._v("в архиве")]):_c('span',[_vm._v("не в архиве")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }