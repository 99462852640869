<template>
  <v-dialog v-model="show" max-width="640" persistent>
    <template #activator="{on, attr}">
      <v-btn text color="red" v-on="on" v-bind="attr">
        <v-icon>mdi-delete-forever</v-icon>
        Удалить
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Удаление пользователя
      </v-card-title>

      <v-card-text class="pt-4 pb-0">
        <v-alert outlined type="error" prominent border="left">
          Пользователь будет удалён навсегда. Необходимо подтвердить выбор.
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="red" @click="closeDialog">Отмена</v-btn>
        <v-btn text color="primary" @click="deleteUser">Удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletingUserDialog",

  data() {
    return {
      show: false
    };
  },

  methods: {
    closeDialog() {
      return (this.show = false);
    },

    deleteUser() {
      this.$emit("delete-user-forever");

      this.closeDialog();
    }
  }
};
</script>
