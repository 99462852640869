function generateLayout(content, styles) {
  return `
  <html>
    <head>
      <title>winName</title>
      <style>${styles}</style>
    </head>
    <body>${content}</body>
  </html>
  `;
}

function generateStyles(options) {
  return `
    @page {
      margin: 0;
    }

    body {
      font-family: Arial, sans-serif, serif;
      font-weight: ${options.fontWeight};
      margin: 0;
      padding: 2%;
      font-size: ${options.fontSize};
    }
    
    p {
      margin: 0;
    }
    
    @media print {
      .sticker { page-break-before: always; }
    }
  `;
}

function generateContent(data) {
  let content = "";

  if (Array.isArray(data)) {
    data.forEach(line => (content += `<p>${line}</p>`));
  } else if (typeof data === "object") {
    Object.entries(data).forEach(
      ([key, value]) => (content += `<p>${key}: ${value}</p>`)
    );
  } else {
    content = data;
  }

  return `<div class="sticker">${content}</div>`;
}

export function printSticker(data, options) {
  const defaultOptions = Object.assign(
    {
      fontSize: "14vmin",
      fontWeight: 600
    },
    options
  );

  const styles = generateStyles(defaultOptions);
  const content = generateContent(data);
  const layout = generateLayout(content, styles);

  const print = window.open("", "winName", `width=${800},height=${600}`);
  print.document.write(layout);
  print.document.close(); // necessary for IE >= 10

  print.onload = function() {
    // necessary if the div contain images
    setTimeout(() => {
      print.focus(); // necessary for IE >= 10
      print.print();
      print.close();
    }, 300);
  };
}
