<template>
  <v-card>
    <v-card-title>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="model.name"
        label="Название"
        autofocus
      ></v-text-field>
      <v-text-field v-model="model.code" label="Идентификатор"></v-text-field>
      <v-text-field v-model="model.description" label="Описание"></v-text-field>
      <v-text-field v-model="model.color" label="Цвет"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn depressed block @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object
  },

  data() {
    return {
      model: {}
    };
  },

  computed: {
    title() {
      return this.model.id ? "Редактирование ключа" : "Создание ключа";
    }
  },

  watch: {
    value: {
      handler(v) {
        this.model = { ...v };
      },
      immediate: true
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    save() {
      this.$emit("input", this.model);
      this.$emit("save", this.model);
      this.close();
    }
  }
};
</script>
