<template>
  <v-card outlined>
    <div class="my-0">
      <v-col class="my-0">
        <v-row class="align-center">
          <v-col>
            <CreateMultipleIdentifiersStorageDialog v-model="update" />
          </v-col>

          <v-spacer />

          <v-col>
            <v-text-field
              v-model="options.search"
              style="max-width: 320px"
              hide-details
              clearable
              single-line
              prepend-icon="mdi-magnify"
              placeholder="Поиск..."
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-4 py-0">
            <v-menu
              v-model="menuShow"
              :close-on-content-click="false"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.date"
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="mdi-calendar"
                  label="Дата"
                  readonly
                  clearable
                  dense
                  class="col"
                />
              </template>

              <v-date-picker v-model="options.date" />
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </div>

    <v-divider />

    <v-data-table
      dense
      :headers="headers"
      :items="items.content"
      :server-items-length="items.totalElements"
      :options.sync="pagination"
      item-key="id"
    >
      <template v-slot:item.key="{ item }">
        <span>{{ hexToIdentifier(item.key) }}</span>
      </template>

      <template v-slot:item.campusKey="{ item }">
        <span>{{ hexToInt(item.key.slice(-8)) }}</span>
      </template>

      <template v-slot:item.createDate="{ item }">
        <span>{{ formatDate(item.createDate) }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click.stop="toggleArchival(item)">
          <v-icon v-if="item.archival" color="success"
            >mdi-archive-arrow-up</v-icon
          >
          <v-icon v-else color="error">mdi-archive-arrow-down-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CreateMultipleIdentifiersStorageDialog from "./CreateMultipleIdentifiersStorageDialog.vue";
import { debounce } from "lodash";
import {
  getListIdentifiersStorage,
  setArchival
} from "../../services/identifier-storage.service";
import { formatDate } from "../../utils/date-format";
import { hexToIdentifier, hexToInt } from "../../utils/identifier-format";

export default {
  components: {
    CreateMultipleIdentifiersStorageDialog
  },

  data() {
    return {
      menuShow: false,

      headers: [
        { text: "Идентификатор", value: "key" },
        { text: "Кампус", value: "campusKey" },
        { text: "Дата добавления", value: "createDate" },
        { text: "Действия", value: "actions" }
      ],
      items: {
        content: [],
        totalElements: 0
      },

      options: {
        search: "",
        date: ""
      },
      update: false,
      pagination: {}
    };
  },

  methods: {
    formatDate,
    hexToIdentifier,
    hexToInt,

    async loadIdentifiers() {
      const { page, itemsPerPage } = this.pagination;

      this.items = await getListIdentifiersStorage({
        search: this.options.search,
        date: this.options.date,
        page: page,
        pageSize: itemsPerPage
      });
    },

    async toggleArchival(item) {
      await setArchival(item.id, !item.archival);
      this.loadIdentifiers();
    }
  },

  computed: {
    loadIdentifiersDebounced() {
      return debounce(this.loadIdentifiers, 500, {
        leading: true,
        maxWait: 1000
      });
    }
  },

  watch: {
    pagination: {
      async handler() {
        await this.loadIdentifiersDebounced();
      },
      deep: true
    },

    options: {
      async handler() {
        await this.loadIdentifiersDebounced();
      },
      deep: true
    },

    update() {
      this.loadIdentifiersDebounced();
    }
  },

  mounted() {
    this.loadIdentifiers();
  }
};
</script>
