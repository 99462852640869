<template>
  <div>
    <v-row>
      <v-col cols="auto" class="ma-4">
        <user-avatar :user="user" />

        <v-checkbox
          indeterminate
          v-model="user.validPhoto"
          label="Фото подтверждено"
          color="green"
          class="v-btn--disabled"
        />
      </v-col>

      <v-col class="ma-4">
        <v-row class="pl-3 pr-3 pt-3">
          <h4>Информация о пользователе:</h4>

          <v-spacer />

          <h4 v-if="user.archival" class="red--text">Архивный</h4>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-text-field v-model="user.surname" label="Фамилия" readonly />
          </v-col>

          <v-col cols="3">
            <v-text-field v-model="user.name" label="Имя" readonly />
          </v-col>

          <v-col cols="3">
            <v-text-field v-model="user.patronymic" label="Отчество" readonly />
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="event.description"
              label="Описание"
              readonly
            />
          </v-col>
        </v-row>

        <h4>Информация о событии:</h4>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="event.gatewayName"
              label="Турникет"
              readonly
            />
          </v-col>

          <v-col cols="6" v-if="event.type !== 'qr'">
            <v-text-field
              v-model="event.key"
              label="Идентификатор (ключ)"
              readonly
            >
              <template v-slot:append v-if="isCurrentIdentifierExists">
                <v-tooltip bottom>
                  <template #activator="{on, attrs}">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="getIdentifierColor(currentIdentifier.type)"
                    >
                      {{ getIdentifierIcon(currentIdentifier.type) }}
                    </v-icon>
                  </template>

                  {{ getIdentifierName(currentIdentifier.type) }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAvatar from "../../user-management/UserInformation/UserAvatar";
import { getUserById } from "../../../services/user.service";
import {
  getIdentifierColor,
  getIdentifierIcon,
  getIdentifierName
} from "../../../constants/identifiers.constants";

export default {
  name: "EventsInfo",
  components: { UserAvatar },
  props: {
    event: { required: true }
  },

  data: () => ({
    user: {},
    identifier: {}
  }),

  created() {
    this.getUser();
  },

  computed: {
    currentIdentifier() {
      return this.user.identifiers.find(
        identifier => identifier.key === this.event.key
      );
    },

    isCurrentIdentifierExists() {
      return (
        this.user.identifiers &&
        this.user.identifiers.length &&
        this.event.key &&
        this.currentIdentifier
      );
    }
  },

  methods: {
    getIdentifierIcon,
    getIdentifierColor,
    getIdentifierName,

    async getUser() {
      try {
        this.user = await getUserById(this.event.userId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
