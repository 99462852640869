<template>
  <div class="row mt-2">
    <p class="col-2">Период:</p>

    <v-menu
      v-model="menuBegin"
      :close-on-content-click="false"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date.begin"
          v-bind="attrs"
          v-on="on"
          prepend-icon="mdi-calendar"
          label="С"
          readonly
          clearable
          dense
          class="col"
        />
      </template>

      <v-date-picker v-model="date.begin" />
    </v-menu>

    <v-menu
      v-model="menuEnd"
      :close-on-content-click="false"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date.end"
          v-bind="attrs"
          v-on="on"
          prepend-icon="mdi-calendar"
          label="По"
          readonly
          clearable
          dense
          class="col"
        />
      </template>

      <v-date-picker v-model="date.end" />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Period",
  data: () => ({
    date: {
      begin: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10)
    },

    menuBegin: false,
    menuEnd: false
  }),

  created() {
    this.$emit("get-date", {
      ...this.normalizeDates()
    });
  },

  watch: {
    "date.begin": {
      handler() {
        this.$emit("get-date", {
          ...this.normalizeDates()
        });
      }
    },

    "date.end": {
      handler() {
        this.$emit("get-date", {
          ...this.normalizeDates()
        });
      }
    }
  },

  methods: {
    normalizeDates() {
      const dates = Object.values(this.date).sort();

      return { begin: dates[0], end: dates[1] };
    }
  }
};
</script>
