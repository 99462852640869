import { keys, forEach } from "lodash";

export function ProxyStorage({ name, storage }) {
  const STORAGE_NAME = name || "storage";
  const STORAGE = storage || sessionStorage;

  const session =
    STORAGE_NAME in STORAGE ? JSON.parse(STORAGE.getItem(STORAGE_NAME)) : {};

  const saveSession = () =>
    STORAGE.setItem(STORAGE_NAME, JSON.stringify(session));

  session.close = () => {
    forEach(keys(session), key => {
      key !== "close" && key !== "version" && delete session[key];
    });
    saveSession();
  };

  return new Proxy(session, {
    set(target, property, value) {
      target[property] = value;
      saveSession();
      return true;
    }
  });
}
