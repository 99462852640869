export const TYPES = {
  card: {
    name: "Карта",
    color: "green",
    icon: "mdi-credit-card"
  },

  console: {
    name: "Пульт",
    color: "blue-grey lighten-2",
    icon: "mdi-console"
  },

  reject: {
    name: "Отклонен",
    color: "red darken-4",
    icon: "mdi-cancel"
  },

  qr: {
    name: "QR-код",
    color: "yellow darken-4",
    icon: "mdi-qrcode"
  }
};

export function getTypesName(id) {
  return (TYPES[id] || { name: "Н/Д" }).name;
}

export function getTypesColor(id) {
  return (TYPES[id] || { color: "gray" }).color;
}

export function getTypesIcon(id) {
  return (TYPES[id] || { icon: "mdi-card" }).icon;
}
