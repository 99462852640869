<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Основная информация</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <edit-speciality :speciality="speciality" />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import EditSpeciality from "../../components/group-management/EditSpeciality.vue";
import Page from "../../components/Page";

export default {
  name: "InfoSpeciality",
  components: { Page, EditSpeciality },
  data: () => ({
    category: {},

    tab: null
  }),

  created() {
    this.speciality = this.$route.params.speciality;

    this.tab = Number(this.$route.query.tab) || 0;
  },

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });
    }
  }
};
</script>
