import { Api } from "./api.service";
import qs from "qs";

export function getExamSheet(params) {
  const query = qs.stringify(params);
  return Api.get(`/exam-sheet${query && "?" + query}`);
}

export function getExamSheetStates() {
  return Api.get(`/exam-sheet/states`);
}

export function deleteExamSheet(id) {
  return Api.delete(`/exam-sheet/${id}`);
}

export function updateExamSheet(params) {
  return Api.put(`/exam-sheet`, params);
}
