<template>
  <v-card outlined>
    <div class="">
      <v-col>
        <v-row class="mt-2">
          <p class="col-2">Дата:</p>

          <v-menu
            v-model="menuDateBegin"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date.begin"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="С"
                readonly
                clearable
                dense
                class="col"
              />
            </template>

            <v-date-picker v-model="date.begin" />
          </v-menu>

          <v-menu
            v-model="menuDateEnd"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date.end"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="По"
                readonly
                clearable
                dense
                class="col"
              />
            </template>

            <v-date-picker v-model="date.end" />
          </v-menu>
        </v-row>

        <v-row class="mt-2">
          <p class="col-2">Время:</p>
          <v-menu
            :close-on-content-click="false"
            offset-y
            min-width="auto"
            v-model="menuTimeBegin"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="C"
                prepend-icon="mdi-clock-time-four-outline"
                clearable
                readonly
                dense
                class="col"
                v-model="time.begin"
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-time-picker v-model="time.begin" format="24hr" />
          </v-menu>

          <v-menu
            :close-on-content-click="false"
            offset-y
            min-width="auto"
            v-model="menuTimeEnd"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="По"
                v-model="time.end"
                prepend-icon="mdi-clock-time-four-outline"
                clearable
                dense
                class="col"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-time-picker v-model="time.end" format="24hr" />
          </v-menu>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="selectControllerId"
              :items="controllersItemsSelect"
              item-text="name"
              item-value="id"
              label="Контроллер"
              clearable
            />
          </v-col>

          <v-col>
            <v-select
              v-model="selectEventType"
              :items="eventTypes"
              item-text="text"
              item-value="value"
              label="Тип события"
              clearable
            />
          </v-col>
        </v-row>
      </v-col>
    </div>

    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="events.content"
        :options.sync="eventsOptions"
        :server-items-length="events && events.totalElements"
        :footer-props="{ itemsPerPageOptions: [15, 25, 50] }"
      >
        <template #item.date="{item}">
          {{ dateFormatted(item.date) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getAcsEvent } from "@/services/user.service";
import { getControllers } from "@/services/device.service";

export default {
  name: "AccessEventInformation",

  props: {
    userId: { required: true }
  },

  data() {
    return {
      controllers: [],
      controllersItemsSelect: [],
      selectControllerId: null,

      headers: [
        { text: "Дата", value: "date" },
        { text: "Контроллер", value: "controllerName" },
        { text: "Шлюз", value: "gatewayName" },
        { text: "Событие", value: "eventName" }
      ],

      events: {},
      eventsOptions: {},

      menuDateBegin: false,
      menuDateEnd: false,
      date: {
        begin: new Date().toISOString().substring(0, 10),
        end: new Date().toISOString().substring(0, 10)
      },

      menuTimeBegin: false,
      menuTimeEnd: false,
      time: {
        begin: "00:00",
        end: "23:59"
      },

      selectEventType: null,
      eventTypes: [
        { text: "Вход", value: "1" },
        { text: "Выход", value: "2" },
        { text: "Доступ запрещен", value: "200" }
      ]
    };
  },

  watch: {
    eventsOptions: {
      handler() {
        this.getEvents();
      }
    },

    selectControllerId: {
      handler() {
        this.eventsOptions.page = 1;
        this.getEvents();
      }
    },

    selectEventType: {
      handler() {
        this.eventsOptions.page = 1;
        this.getEvents();
      }
    },

    date: {
      handler() {
        this.eventsOptions.page = 1;
        this.getEvents();
      },
      deep: true
    },

    time: {
      handler() {
        this.eventsOptions.page = 1;
        this.getEvents();
      },
      deep: true
    }
  },

  mounted() {
    this.getControllers();
  },

  methods: {
    async getEvents() {
      try {
        const { page, itemsPerPage } = this.eventsOptions;

        const params = {
          page: page - 1,
          size: itemsPerPage
        };

        if (this.selectControllerId !== null) {
          params.cId = this.selectControllerId;
        }

        if (this.date.begin) params.dateBegin = this.date.begin;
        if (this.date.end) params.dateEnd = this.date.end;

        params.timeBegin = this.time.begin;
        params.timeEnd = this.time.end;

        if (this.selectEventType) {
          params.typeEvent = this.selectEventType;
        }

        this.events = await getAcsEvent(this.userId, params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getControllers() {
      try {
        this.controllers = await getControllers();

        this.controllers.forEach(item => {
          if (item.name !== null) {
            this.controllersItemsSelect.push(item);
          }
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    dateFormatted(date) {
      if (date) {
        return new Date(date).toLocaleString("ru-RU");
      }
    }
  }
};
</script>
