export const formatDateTime = (date, options) =>
  new Date(date).toLocaleString(
    "ru-ru",
    Object.assign({ dateStyle: "short", timeStyle: "short" }, options)
  );

export const formatDate = (date, options) =>
  new Date(date).toLocaleDateString(
    "ru-ru",
    Object.assign({ dateStyle: "medium" }, options)
  );

export const formatTime = (date, options) =>
  new Date(date).toLocaleTimeString(
    "ru-ru",
    Object.assign({ timeStyle: "medium" }, options)
  );

export const formatDateIso = (date, keepOffset = true) => {
  date = new Date(date);

  if (keepOffset) {
    const offset = date.getTimezoneOffset() * 60 * 1000;
    date.setTime(date.getTime() - offset);
  }

  return date.toISOString().substring(0, 10);
};

export const formatDurationToMonth = (years, month) => {
  return years * 12 + Number(month || 0);
};
export const formatDuration = m => {
  const years = Math.trunc(m / 12);
  const month = m % 12;
  return { year: years, month: month };
};

export const formatDurationToString = m => {
  const years = Math.trunc(m / 12);
  const month = m % 12;
  let textYears = "";

  let count = years % 100;
  if (count >= 5 && count <= 20) {
    textYears = "л.";
  } else {
    count = count % 10;
    if (count == 1 || (count >= 2 && count <= 4)) {
      textYears = "г.";
    } else {
      textYears = "л.";
    }
  }

  if (month != 0) {
    return `${years} ${textYears} ${month} м.`;
  } else {
    return `${years} ${textYears}`;
  }
};
