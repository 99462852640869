import Vue from "vue";
import VueRouter from "vue-router";

import { authRoutes } from "./auth.routes";
import { groupManagementRoutes as groupsRoutes } from "./group-management.routes";
import { userManagementRoutes } from "./user-management.routes";
import { controllerRoutes } from "./controller.routes";

import Home from "../views/Home";
import * as sessionService from "@/services/session.service";
import { LOGIN_ROUTE } from "@/router/names";
import { newsRoutes } from "./news.routes";
import { examRoutes } from "./exam.routes";
import { accessRoutes } from "./access.routes";

Vue.use(VueRouter);

export const routes = [
  ...authRoutes,
  ...groupsRoutes,
  ...userManagementRoutes,
  ...controllerRoutes,
  ...newsRoutes,
  ...examRoutes,
  ...accessRoutes,

  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      breadcrumbs: [
        {
          text: "Главная",
          disabled: true,
          link: true,
          to: { name: "home" }
        }
      ]
    }
  },

  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import("../views/Forbidden.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const excludeRoutes = [LOGIN_ROUTE, "forbidden", "home"];

  if (excludeRoutes.includes(to.name)) {
    next();
  } else {
    if (sessionService.hasSession()) {
      // check root access to route
      if (
        sessionService.isAllow("routes", "all") ||
        sessionService.isAllow("routes", to.name)
      ) {
        next();
      } else {
        next({ name: "forbidden" });
      }
    } else {
      next({ name: LOGIN_ROUTE });
    }
  }
});

export default router;
