import {
  STAFF_GROUPS_ROUTE,
  USERS_GROUPS_ROUTE,
  INFO_USERS_EDIT_ROUTE,
  INFO_STAFF_EDIT_ROUTE,
  SPECIALITY_GROUPS_ROUTE,
  INFO_SPECIALITY_EDIT_ROUTE,
  PPS_GROUPS_ROUTE,
  INFO_PPS_EDIT_ROUTE
} from "./names";

import StaffGroupList from "../views/group-management/StaffGroupList";
import PpsGroupList from "../views/group-management/PpsGroupList";
import UsersGroupList from "../views/group-management/UsersGroupList";
import InfoGroup from "../views/group-management/InfoGroup";
import SpecialityGroupList from "../views/group-management/SpecialityGroupList";
import InfoSpeciality from "../views/group-management/InfoSpeciality";

export const groupManagementRoutes = [
  {
    path: "/groups/staff",
    name: STAFF_GROUPS_ROUTE,
    component: StaffGroupList,
    meta: {
      group: "Управление категориями",
      title: "Сотрудники",
      description: "Управление подразделениями кампусной системы",
      icon: "mdi-badge-account-horizontal",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Сотрудники",
          disabled: true,
          link: true,
          to: { name: STAFF_GROUPS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/pps",
    name: PPS_GROUPS_ROUTE,
    component: PpsGroupList,
    meta: {
      group: "Управление категориями",
      title: "Преподаватели",
      description: "Управление подразделениями кампусной системы",
      icon: "mdi-school",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Преподаватели",
          disabled: true,
          link: true,
          to: { name: PPS_GROUPS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/users",
    name: USERS_GROUPS_ROUTE,
    component: UsersGroupList,
    meta: {
      group: "Управление категориями",
      title: "Факультеты",
      description: "Управление факультетами кампусной системы",
      icon: "mdi-book",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Факультеты",
          disabled: true,
          link: true,
          to: { name: USERS_GROUPS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/speciality",
    name: SPECIALITY_GROUPS_ROUTE,
    component: SpecialityGroupList,
    meta: {
      group: "Управление категориями",
      title: "Специальности",
      description: "Управление специальностями кампусной системы",
      icon: "mdi-book-outline",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Специальности",
          disabled: true,
          link: true,
          to: { name: SPECIALITY_GROUPS_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/staff/info",
    name: INFO_STAFF_EDIT_ROUTE,
    component: InfoGroup,
    meta: {
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Сотрудники",
          link: true,
          exact: true,
          to: { name: STAFF_GROUPS_ROUTE }
        },
        {
          text: "Управление категорией",
          disabled: true,
          link: true,
          to: { name: INFO_STAFF_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/pps/info",
    name: INFO_PPS_EDIT_ROUTE,
    component: InfoGroup,
    meta: {
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Преподаватели",
          link: true,
          exact: true,
          to: { name: PPS_GROUPS_ROUTE }
        },
        {
          text: "Управление категорией",
          disabled: true,
          link: true,
          to: { name: INFO_PPS_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/users/info",
    name: INFO_USERS_EDIT_ROUTE,
    component: InfoGroup,
    meta: {
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Факультеты",
          link: true,
          exact: true,
          to: { name: USERS_GROUPS_ROUTE }
        },
        {
          text: "Управление категорией",
          disabled: true,
          link: true,
          to: { name: INFO_USERS_EDIT_ROUTE }
        }
      ]
    }
  },

  {
    path: "/groups/speciality/info",
    name: INFO_SPECIALITY_EDIT_ROUTE,
    component: InfoSpeciality,
    meta: {
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Специальности",
          link: true,
          exact: true,
          to: { name: SPECIALITY_GROUPS_ROUTE }
        },
        {
          text: "Управление категорией",
          disabled: true,
          link: true,
          to: { name: INFO_SPECIALITY_EDIT_ROUTE }
        }
      ]
    }
  }
];
