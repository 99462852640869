import { isUndefined } from "lodash";

function serialize(formModel = {}, transform = {}) {
  const serializedFormModel = {};

  for (const [key, value] of Object.entries(formModel)) {
    let _value = value;

    if (typeof value === "string")
      _value = value.trim().replace(/[\s,;\t]+/, " ");

    if (!isUndefined(transform[key])) {
      _value = transform[key](_value);
    }

    serializedFormModel[key] = _value;
  }

  return serializedFormModel;
}

export { serialize };
