import Login from "../views/Login";
import { LOGIN_ROUTE } from "@/router/names";

export const authRoutes = [
  {
    path: "/login",
    name: LOGIN_ROUTE,
    component: Login
  }
];
