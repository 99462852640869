<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="tag in dataset" :key="tag.id" cols="4">
        <v-card outlined>
          <v-card-title>{{ tag.name }}</v-card-title>

          <v-divider />

          <v-card-text>
            <h4 style="font-weight: normal; color: black">
              Количество: {{ tag.count }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getTagsStats } from "../../services/tags.service";

export default {
  name: "TagsStatistics",

  data() {
    return {
      dataset: []
    };
  },

  mounted() {
    this.getTagsStats();
  },

  methods: {
    async getTagsStats() {
      try {
        this.dataset = await getTagsStats();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
