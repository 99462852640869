<template>
  <v-card>
    <v-card-title>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="model.course"
        label="Курс"
        autifocus
      ></v-text-field>
      <v-text-field v-model="model.liter" label="Литер"></v-text-field>
      <v-select
        v-model="model.speciality"
        :items="specialties"
        :item-text="
          i =>
            i.profile
              ? `${i.direction} (${i.qualification || '-'}), профиль ${
                  i.profile
                }`
              : `${i.direction} (${i.qualification || '-'})`
        "
        return-object
        dense
        label="Специальность"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn block text color="primary" @click="save">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object,
    specialties: Array
  },

  data() {
    return {
      model: {}
    };
  },

  computed: {
    title() {
      return this.model.id ? "Редактирование группы" : "Создание группы";
    }
  },

  watch: {
    value: {
      handler(v) {
        this.model = { ...v };
      },
      immediate: true
    }
  },

  methods: {
    close() {
      this.model = {};
      this.$emit("close");
    },

    save() {
      this.$emit("input", this.model);
      this.$emit("save", this.model);
      this.close();
    }
  }
};
</script>
