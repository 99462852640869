// auth
export const LOGIN_ROUTE = "LOGIN_ROUTE";

// user management
export const USERS_ROUTE = "USERS_ROUTE";
export const STAFF_ROUTE = "STAFF_ROUTE";
export const PPS_ROUTE = "PPS_ROUTE";
export const USERS_SEARCH_ROUTE = "USERS_SEARCH_ROUTE";
export const USERS_ADDITION_ROUTE = "USERS_ADDITION_ROUTE";
export const USERS_EDIT_ROUTE = "USERS_EDIT_ROUTE";
export const STAFF_EDIT_ROUTE = "STAFF_EDIT_ROUTE";
export const PPS_EDIT_ROUTE = "PPS_EDIT_ROUTE";

// access management
export const TAGS_ROUTE = "TAGS_ROUTE";
export const KEYS_ROUTE = "KEYS_ROUTE";
export const IDENTIFIERS_STORAGE_ROUTE = "IDENTIFIERS_STORAGE_ROUTE";
export const PASS_ROUTE = "PASS_ROUTE";
export const PASS_EDIT_ROUTE = "PASS_EDIT_ROUTE";
export const EVENTS_ROUTE = "EVENTS_ROUTE";
export const EVENT_ROUTE = "EVENT_ROUTE";

// group management
export const STAFF_GROUPS_ROUTE = "STAFF_GROUPS_ROUTE";
export const PPS_GROUPS_ROUTE = "PPS_GROUPS_ROUTE";
export const USERS_GROUPS_ROUTE = "USERS_GROUPS_ROUTE";
export const SPECIALITY_GROUPS_ROUTE = "SPECIALITY_GROUPS_ROUTE";
export const INFO_SPECIALITY_EDIT_ROUTE = "INFO_SPECIALITY_EDIT_ROUTE";
export const INFO_STAFF_EDIT_ROUTE = "INFO_STAFF_EDIT_ROUTE";
export const INFO_PPS_EDIT_ROUTE = "INFO_PPS_EDIT_ROUTE";
export const INFO_USERS_EDIT_ROUTE = "INFO_USERS_EDIT_ROUTE";

//controllers
export const CONTROLLERS_ROUTE = "CONTROLLERS_ROUTE";

//news and voting
export const NEWS_ROUTE = "NEWS_ROUTE";

//exam sheet
export const EXAM_ROUTE = "EXAM_ROUTE";
