<template>
  <v-card outlined>
    <v-card-title>Изменение специальности</v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model.trim="model.code"
              label="Код"
              autofocus
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="model.category"
              :items="categories"
              item-text="name"
              return-object
              dense
              label="Факультет"
            />
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="model.qualification"
              dense
              label="Квалификация"
              :items="qualifications"
            />
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="model.direction"
              dense
              label="Направление"
              :items="directions"
              :disabled="!model.category"
            />
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="model.profile"
              dense
              label="Профиль"
              :items="profiles"
              :disabled="!model.category"
            />
          </v-col>
        </v-row>
        <v-row>
          <div class="col-3 d-flex align-end mb-5 text-md-body-1">
            Срок обучения:
          </div>
          <v-col>
            <v-text-field v-model.trim="duration.year" label="Годы" required />
          </v-col>
          <v-col>
            <v-text-field
              v-model.trim="duration.month"
              label="Месяцы"
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="update">Обновить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  updateSpeciality,
  getCategoryByType,
  getSpecialityListByType
} from "@/services/category.service";
import { formatDuration, formatDurationToMonth } from "@/utils/date-format";

export default {
  name: "EditSpeciality",
  props: {
    value: { type: Boolean },
    speciality: { type: Object, required: true }
  },

  data() {
    return {
      valid: false,
      rules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 1) || "Минимальное число символов: 2"
      ],

      model: { name: "", type: "" },
      duration: {},
      categories: [],
      profiles: [],
      qualifications: [],
      directions: []
    };
  },

  async created() {
    this.model = { ...this.speciality };
    this.duration = formatDuration(this.model.duration);

    this.categories = await this.getCategoryByType("ENTRANT");
    this.qualifications = await this.getSpecialityListByType("qualification");
  },

  watch: {
    category: {
      async handler() {
        this.profiles = await this.getSpecialityListByType("profile", {
          categoryId: this.model.category.id
        });
        this.directions = await this.getSpecialityListByType("direction", {
          categoryId: this.model.category.id
        });
      }
    }
  },

  computed: {
    category() {
      return this.model.category;
    }
  },

  methods: {
    getCategoryByType,
    getSpecialityListByType,

    async update() {
      try {
        this.model.duration = formatDurationToMonth(
          this.duration.year,
          this.duration.month
        );
        await updateSpeciality(this.model.id, this.model).then(() =>
          this.$notify({
            text: "Данные успешно обновлены",
            type: "success"
          })
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
