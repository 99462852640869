<template>
  <v-row>
    <v-menu :close-on-content-click="false" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date.begin"
          v-bind="attrs"
          v-on="on"
          prepend-icon="mdi-calendar"
          label="Начало"
          readonly
          dense
          clearable
          class="col-6"
        />
      </template>

      <v-date-picker v-model="date.begin" />
    </v-menu>

    <v-menu :close-on-content-click="false" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date.end"
          v-bind="attrs"
          v-on="on"
          prepend-icon="mdi-calendar"
          label="Конец"
          readonly
          dense
          clearable
          class="col-6"
        />
      </template>

      <v-date-picker v-model="date.end" />
    </v-menu>
  </v-row>
</template>

<script>
export default {
  name: "Period",

  data: () => ({
    date: {
      begin: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10),
      end: new Date().toISOString().substring(0, 10)
    }
  }),

  watch: {
    date: {
      handler(newDate) {
        if (newDate) {
          const dates = Object.values(newDate).sort();

          this.$emit("update-date", { begin: dates[0], end: dates[1] });
        }
      },
      deep: true
    }
  }
};
</script>
