<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-plus</v-icon> Новый идентификатор
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Новый идентификатор</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="identifierModel.key"
                label="Код идентификатора"
                autofocus
                required
                :rules="rules"
              />
            </v-col>
          </v-row>

          <v-row v-if="creatingError">
            <v-col cols="12">
              <v-alert type="error" prominent>
                <v-row align="center">
                  <v-col class="grow">
                    {{
                      isUserExists && isReceivedUserAnIncomingUser
                        ? errorText
                        : errorText.split("-")[0].trim()
                    }}
                  </v-col>

                  <v-col
                    v-if="isUserExists && !isReceivedUserAnIncomingUser"
                    class="shrink"
                    @click="goToUserEdit"
                  >
                    <v-btn text>{{ `${user.name} ${user.surname}` }}</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="identifierModel.type"
                :items="types"
                item-value="type"
                item-text="text"
                label="Тип идентификатора"
              />
            </v-col>
            <v-col cols="6">
              <v-switch label="Активный?" v-model="identifierModel.active" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="resetForm">Очистить</v-btn>
        <v-spacer />
        <v-btn @click="close" text color="red">Отмена</v-btn>
        <v-btn @click="create" text color="primary" :disabled="!valid">
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { serialize } from "../../services/serialize.service";
import {
  createIdentificator,
  getUserByIdentifierKey
} from "../../services/user.service";
import { map } from "lodash";
import { IDENTIFIERS } from "@/constants/identifiers.constants";
import {
  PPS_EDIT_ROUTE,
  STAFF_EDIT_ROUTE,
  USERS_EDIT_ROUTE
} from "../../router/names";

export default {
  name: "CreateIdentificatorDialog",

  data: () => ({
    show: false,
    valid: false,
    loading: false,
    creatingError: false,

    errorText: "",

    rules: [v => !!v || "Это обязательное поле"],

    types: map(IDENTIFIERS, (value, key) => ({
      type: Number(key),
      text: value.name
    })),

    identifierModel: {
      key: "",
      type: 0,
      active: true
    },
    user: {}
  }),

  computed: {
    isUserExists() {
      return Object.keys(this.user).length;
    },

    isReceivedUserAnIncomingUser() {
      return this.user.id === +this.$route.params.userId;
    }
  },

  watch: {
    show: {
      handler(newValue) {
        if (!newValue) this.creatingError = false;
      }
    }
  },

  methods: {
    close() {
      this.resetForm();
      this.show = false;
    },

    resetForm() {
      this.$refs.form.reset();
    },

    async create() {
      try {
        await createIdentificator(
          this.$route.params.userId,
          serialize(this.identifierModel)
        );

        this.$emit("update");

        this.close();
      } catch (e) {
        if (e.response && e.response.data.status === 409) {
          this.creatingError = true;
          this.errorText = e.response.data.message;

          await this.getUserByIdentifierKey();
        } else {
          this.$notify({
            text: e.response ? e.response.data.message : e,
            type: "error"
          });
        }
      }
    },

    async getUserByIdentifierKey() {
      try {
        this.user = await getUserByIdentifierKey(this.identifierModel.key);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    goToUserEdit() {
      const userGroup = this.user.mainGroup.name.toLowerCase();
      const routerLinkObject = {
        name: "",
        params: {
          group: userGroup,
          userId: this.user.id
        }
      };

      this.$router.push(
        userGroup === "entrant"
          ? { ...routerLinkObject, name: USERS_EDIT_ROUTE }
          : userGroup === "staff"
          ? { ...routerLinkObject, name: STAFF_EDIT_ROUTE }
          : { ...routerLinkObject, name: PPS_EDIT_ROUTE }
      );

      this.$emit("update");
      this.close();
    }
  }
};
</script>
