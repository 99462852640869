<template>
  <v-card outlined>
    <v-card flat>
      <v-card-title>Предоставление данных</v-card-title>

      <v-divider />

      <v-card-text>
        <v-textarea auto-grow label="Информация о пропуске" :value="passData" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn text color="black" @click="copyData()">Копировать</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { formatDateTime } from "@/utils/date-format";

export default {
  props: {
    pass: { type: Object, required: true }
  },

  computed: {
    passData() {
      return `Посетитель: ${this.pass.surname} ${this.pass.name} ${this.pass
        .patronymic || ""}
Назначение: ${this.pass.category?.name}
Описание: ${this.pass.description}
Срок действия пропуска: с ${formatDateTime(
        this.pass.dateStart
      )} по ${formatDateTime(this.pass.dateEnd)}

Ссылка на личный кабинет: ${window.location.origin}/lk?uid=${this.pass.uid}`;
    }
  },

  methods: {
    copyData() {
      navigator.clipboard.writeText(this.passData);
    }
  }
};
</script>
