<template>
  <v-card>
    <v-card-title>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-form v-model="valid" lazy-validation ref="form">
      <v-card-text>
        <div class="row">
          <div class="col-6">
            <v-text-field
              v-model.trim="userModel.surname"
              :rules="surnameRules"
              label="Фамилия"
              autofocus
              required
            />
          </div>

          <div class="col-6">
            <v-text-field
              v-model.trim="userModel.name"
              :rules="nameRules"
              label="Имя"
              required
            />
          </div>

          <div class="col-12">
            <v-text-field v-model="userModel.patronymic" label="Отчество" />
          </div>

          <div class="col-12">
            <v-select
              v-model="userModel.category"
              label="Назначение"
              :items="management"
              :rules="categoryRules"
              item-text="name"
              return-object
              dense
              required
            />
          </div>

          <v-card-text>
            <v-textarea
              rows="1"
              label="Описание"
              v-model="userModel.description"
              :rules="descriptionRules"
            />
          </v-card-text>
        </div>

        <p class="mb-6 primary--text">Начало</p>

        <div class="row">
          <v-menu :close-on-content-click="false" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateBegin.date"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="Дата"
                readonly
                dense
                class="col-6"
              />
            </template>

            <v-date-picker v-model="dateBegin.date" />
          </v-menu>

          <v-menu
            :close-on-content-click="false"
            offset-y
            min-width="auto"
            v-model="menuTimeBegin"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Время"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                dense
                class="col-6"
                v-model="dateBegin.time"
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-time-picker v-model="dateBegin.time" format="24hr" />
          </v-menu>
        </div>

        <p class="mb-6 primary--text">Конец</p>

        <div class="row">
          <v-menu :close-on-content-click="false" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd.date"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="Дата"
                readonly
                dense
                class="col-6"
                :rules="minDay"
              />
            </template>

            <v-date-picker v-model="dateEnd.date" />
          </v-menu>

          <v-menu
            :close-on-content-click="false"
            offset-y
            min-width="auto"
            v-model="menuTimeEnd"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Время"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                dense
                class="col-6"
                v-model="dateEnd.time"
                v-bind="attrs"
                v-on="on"
                :rules="minTime"
              />
            </template>

            <v-time-picker v-model="dateEnd.time" format="24hr" />
          </v-menu>
        </div>

        <v-switch
          v-model="userModel.active"
          :label="labelActive"
          dense
          hide-details
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed block @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { currentUser } from "../../services/auth.service";
export default {
  props: {
    value: Object
  },

  data() {
    return {
      userModel: {},
      valid: true,

      surnameRules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 2) || "Фамилия должна быть длинее 2 символов"
      ],
      nameRules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 2) || "Имя должно быть длинее 2 символов"
      ],
      categoryRules: [v => !!v || "Это обязательное поле"],
      descriptionRules: [v => !!v || "Это обязательное поле"],
      minDay: [
        () =>
          new Date(`${this.dateBegin.date}`) <=
            new Date(`${this.dateEnd.date}`) ||
          "Дата окончания не может быть раньше даты начала"
      ],

      minTime: [
        () =>
          new Date(`${this.dateBegin.date} ${this.dateBegin.time}`) <=
            new Date(`${this.dateEnd.date} ${this.dateEnd.time}`) ||
          "Время окончания не может быть раньше времени начала"
      ],

      user: {},
      management: [],

      dateBegin: {
        date: null,
        time: null
      },
      dateEnd: {
        date: null,
        time: null
      },
      menuDateBegin: false,
      menuTimeBegin: false,
      menuDateEnd: false,
      menuTimeEnd: false
    };
  },

  watch: {
    value: {
      handler(v) {
        this.userModel = { active: true, ...v };
      },
      immediate: true
    },

    userModel: {
      handler() {
        this.dateBegin = this.dateStrToModel(this.userModel.dateStart);
        this.dateEnd = this.dateStrToModel(this.userModel.dateEnd);
      },
      immediate: true
    }
  },

  computed: {
    title() {
      return this.userModel.id
        ? "Редактирование пропуска"
        : "Создание пропуска";
    },
    labelActive() {
      return this.userModel.active ? "Активный" : "Неактивный";
    }
  },

  async created() {
    this.user = await this.currentUser();
    this.management = this.user.management.map(o => o.category);
  },

  methods: {
    currentUser,
    close() {
      this.$emit("close");
    },

    save() {
      this.userModel.dateEnd = new Date(
        `${this.dateEnd.date} ${this.dateEnd.time}`
      );
      this.userModel.dateStart = new Date(
        `${this.dateBegin.date} ${this.dateBegin.time}`
      );

      if (this.$refs.form.validate()) {
        this.$emit("save", this.userModel);
        this.close();
      }
    },

    dateStrToModel(str) {
      const date = str ? new Date(str) : new Date();
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      return {
        date: [y, m, d].join("-"),
        time: date.toLocaleString().slice(11, 17)
      };
    }
  }
};
</script>
