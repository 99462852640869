<template>
  <div>
    <v-card outlined>
      <v-card flat>
        <v-card-title>Доступ в личный кабинет</v-card-title>

        <v-divider />

        <v-card-text>
          <credential-form v-model="user" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn text color="primary" @click="print">Печать</v-btn>
          <v-spacer />

          <v-btn text color="primary" @click="update">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <v-row dense>
      <v-col cols="12" md="6">
        <copy-user-bot-token :user="user" class="my-2" />
      </v-col>
      <v-col>
        <copy-user-auth :user="user" class="my-2" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { printSticker } from "@/services/printStickers.service";
import { updateUser } from "@/services/user.service";
import CredentialForm from "../AccessInformation/CredentialForm";
import CopyUserAuth from "@/components/user-management/UserLK/CopyUserAuth";
import CopyUserBotToken from "@/components/user-management/UserLK/CopyUserBotToken";

export default {
  name: "UserLk",
  components: { CopyUserAuth, CopyUserBotToken, CredentialForm },
  props: {
    user: { required: true, type: Object }
  },

  methods: {
    async update() {
      await updateUser(this.user);
    },

    print() {
      const options = {
        fontSize: "5vmin"
      };

      printSticker(
        [
          "Кампус",
          "Доступ в личный кабинет",
          "<br/>",
          this.user.name,
          this.user.surname,
          "<br/>",
          "Адрес: *",
          "Логин: " + this.user.login,
          "Пароль: " + this.user.password
        ],
        options
      );
    }
  }
};
</script>
