<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-row class="pt-8">
        <v-col>
          <v-menu
            v-model="menuBegin"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="begin"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="С"
                readonly
                clearable
                dense
              />
            </template>

            <v-date-picker v-model="begin" @input="menuBegin = false" />
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="menuEnd"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end"
                v-bind="attrs"
                v-on="on"
                prepend-icon="mdi-calendar"
                label="По"
                readonly
                clearable
                dense
              />
            </template>

            <v-date-picker v-model="end" @input="menuEnd = false" />
          </v-menu>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      item-key="id"
    >
      <template v-slot:item.key="{ item }">
        {{ item.key.name }}
      </template>
      <template v-slot:item.takenAt="{ item }">
        <keys-journal-item
          v-if="item.takenAt"
          :date="item.takenAt"
          :user="item.taker"
        ></keys-journal-item>
        <span v-else>-</span>
      </template>
      <template v-slot:item.returnedAt="{ item }">
        <keys-journal-item
          v-if="item.returnedAt"
          :date="item.returnedAt"
          :user="item.returner"
        ></keys-journal-item>
        <span v-else>-</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { withNotifications } from "@/utils/notifications";
import { getKeysJournal } from "@/services/keys.service";
import KeysJournalItem from "./KeysJournalItem.vue";

export default {
  components: { KeysJournalItem },
  data() {
    return {
      headers: [
        { text: "Ключ", value: "key" },
        { text: "Взят", value: "takenAt" },
        { text: "Возвращен", value: "returnedAt" }
      ],
      items: [],
      item: {},
      total: 0,

      begin: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10),
      options: {},

      loading: false,
      menuBegin: false,
      menuEnd: false
    };
  },

  watch: {
    options: {
      handler() {
        this.load();
      },
      immediate: true,
      deep: true
    },

    begin() {
      this.load();
    },

    end() {
      this.load();
    }
  },

  methods: {
    async load() {
      const { page = 1, itemsPerPage: size } = this.options;
      const { begin, end } = this;

      this.loading = true;
      await withNotifications(async () => {
        const pageable = await getKeysJournal({
          page: page - 1,
          size,
          begin,
          end
        });

        this.total = pageable.totalElements;
        this.items = pageable.content;
      });
      this.loading = false;
    }
  }
};
</script>
