<template>
  <v-card outlined>
    <exam-sheet-title :states="states" @get-state="getState" />

    <v-card-text>
      <v-data-iterator
        :items="examSheet.content"
        item-key="id"
        :loading="loading"
        :options.sync="examSheetOptions"
        :server-items-length="examSheet && examSheet.totalElements"
        :footer-props="{ itemsPerPageOptions: [10, 15, 20] }"
      >
        <template #default="{items}">
          <v-row v-for="exam in items" :key="exam.id">
            <v-col>
              <exam-card
                :exam="exam"
                :states="states"
                @update="updateExamSheet"
                @download="downloadExamSheet"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>

<script>
import ExamSheetTitle from "./ExamSheetTitle";
import {
  deleteExamSheet,
  getExamSheet,
  getExamSheetStates,
  updateExamSheet
} from "../../services/exam.service";
import ExamCard from "./ExamCard";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { DocxGeneratorService } from "../../services/DocxGenerator/docxGenerator.service";

export default {
  name: "ExamSheet",
  components: { ExamCard, ExamSheetTitle },

  data: () => ({
    examSheet: [],
    states: [],

    examSheetOptions: {},
    params: {},

    loading: false
  }),

  watch: {
    examSheetOptions: {
      handler(newOptions) {
        const { page, itemsPerPage } = newOptions;
        this.params.page = page - 1;
        this.params.size = itemsPerPage;

        this.getExamSheet();
      }
    }
  },

  created() {
    this.getStates();

    this.examSheet.sort((a, b) =>
      a.created > b.created ? 1 : b.created > a.created ? -1 : 0
    );
  },

  methods: {
    async getExamSheet() {
      try {
        this.loading = true;

        this.examSheet = await getExamSheet(this.params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    async getStates() {
      try {
        this.states = await getExamSheetStates();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    updateExamSheet(exam) {
      if (typeof exam === "object") {
        this.saveExamSheet(exam);
      } else {
        this.deleteExamSheet(exam);
      }
    },

    async saveExamSheet(exam) {
      try {
        await updateExamSheet({
          ...exam,
          date: new Date(exam.date).toISOString()
        });

        await this.getExamSheet();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async deleteExamSheet(id) {
      try {
        await deleteExamSheet(id);

        await this.getExamSheet();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getState(state) {
      state ? (this.params.state = state) : delete this.params.state;

      this.getExamSheet();
    },

    downloadExamSheet(exam, marks) {
      let docxCreator = new DocxGeneratorService(exam, marks);
      let docx = docxCreator.createDocument();

      Packer.toBlob(docx).then(blob => {
        saveAs(blob, `Отчёт ${exam.id}.docx`);
      });
    }
  }
};
</script>
