import { AlignmentType, Document, Paragraph, TabStopType } from "docx";
import { TextService } from "./text.service";
import { TableService } from "./table.service";

export class DocxGeneratorService {
  constructor(exam, marks) {
    this.exam = exam;
    this.marks = marks;

    this.textService = new TextService();
    this.tableService = new TableService(this.exam);
  }

  createDocument() {
    return new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 455,
                right: 555,
                bottom: 455,
                left: 755
              }
            }
          },

          children: [
            this.createHeaderParagraph(),
            this.createTextParagraph(),
            this.tableService.createTable(),
            this.createFooterParagraph()
          ]
        }
      ]
    });
  }

  createHeaderParagraph() {
    return new Paragraph({
      alignment: AlignmentType.CENTER,

      children: [
        this.textService.createAnotherText(
          "Федеральное государственное бюджетное образовательное учреждение высшего образования",
          null,
          24,
          true
        ),

        this.textService.createAnotherText(
          "«Благовещенский государственный педагогический университет»",
          1,
          24,
          true
        ),

        this.textService.createAnotherText("", 1, 20),
        this.textService.createAnotherText("", 1, 24),

        this.textService.createAnotherText(
          `ЗАЧЁТНО-ЭКЗАМЕНАЦИОННАЯ ВЕДОМОСТЬ № ${this.exam.id}`,
          1,
          32,
          true
        )
      ]
    });
  }

  createTextParagraph() {
    return new Paragraph({
      children: [
        this.textService.createText(
          `Семестр ${this.textService.calculateSemester(
            this.exam
          )} ${this.textService.calculateStudyYear(this.exam)} учебного года`,
          1
        ),

        this.textService.createText(
          `Форма контроля – ${this.textService.examTypeText(this.exam.type)}`,
          1
        ),

        this.textService.createText(`Факультет ${this.exam.categoryTitle}`, 1),

        this.textService.createText(
          `Группа ${this.exam.groupTitle} ${this.exam.courseTitle}`,
          1
        ),

        this.textService.createText(
          `Дисциплина ${this.exam.discipline ||
            "_____________________________________________________________________________"}`,
          1
        ),

        this.textService.createText(
          `Общее количество часов, час/з.е.____________________________________________________________`,
          1
        ),

        this.textService.createText(
          `Фамилия, имя, отчество преподавателей: ${this.exam.user.surname} ${
            this.exam.user.name
          } ${this.exam.user.patronymic || ""}`,
          1
        ),

        this.textService.createText(
          `Дата проведения: ${this.textService
            .examDateFormatted(this.exam.date)
            .slice(4, 19)}`,
          1
        ),

        this.textService.createText(``, 1)
      ]
    });
  }

  createFooterParagraph() {
    const marksCount = Object.entries(this.marks)
      .sort()
      .map(([key, value]) => {
        if (key === "неудовлетворительно") return "";

        return this.textService.createText(
          this.textService.marksString(key, value, this.exam.type),
          1
        );
      });

    return new Paragraph({
      children: [
        this.textService.createText(
          `\tЧисло студентов на экзамене (зачете): ${this.exam.items.length}`
        ),

        ...marksCount,

        this.textService.createText(
          `\tЧисло студентов, не допущенных к экзамену (зачету)  _____________________________________`,
          1
        ),

        this.textService.createText(
          `\t\t\t  Заместитель декана по учебной работе _________________________________________`,
          2
        ),
        this.textService.createAnotherText(`Запрещается: `, 1, 24, true),
        this.textService.createText(
          `1. Принимать экзамены от студентов, не внесенных в данную экзаменационную\t\t\t
                 ведомость.`
        ),
        this.textService.createText(
          `\t\t\t  2. Принимать экзамены в сроки, не установленные утвержденным расписанием, кроме\t
                     случаев, специально разрешенных деканом.`,
          1
        )
      ],

      tabStops: [
        {
          type: TabStopType.START,
          position: 468
        }
      ]
    });
  }
}
