<template>
  <v-card outlined>
    <exam-card-title
      :exam="exam"
      :states="states"
      @update="updateExamSheet"
      @download="downloadExamSheet"
    />

    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">
            Оценки
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>ФИО</th>

                  <th>№ зачётной книжки</th>

                  <th>Оценка</th>
                </tr>
              </thead>

              <tbody v-for="item in exam.items" :key="item.id">
                <tr>
                  <td>{{ item.family }} {{ item.name }} {{ item.surname }}</td>

                  <td>{{ item.booknumber }}</td>

                  <td>
                    {{ item.mark ? item.mark.name : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-divider class="my-2" />

            <v-form>
              <h4 v-for="(key, value) in marks" :key="key">
                {{ value }}: {{ key }}
              </h4>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-card-actions>
      <h4 class="text--disabled">
        Дата создания: {{ dateFormatted(exam.created) }}
      </h4>

      <v-spacer />

      <h4 class="text--disabled">
        {{ exam.user.surname }} {{ exam.user.name }},
        {{ exam.user.description }}
      </h4>
    </v-card-actions>
  </v-card>
</template>

<script>
import ExamCardTitle from "./ExamCardTitle";
export default {
  name: "ExamCard",
  components: { ExamCardTitle },
  props: {
    exam: { type: Object, required: true },
    states: { type: Array, required: true }
  },

  data: () => ({
    marks: {}
  }),

  created() {
    this.marksCount();
  },

  methods: {
    dateFormatted(date) {
      return new Date(date).toLocaleString("ru-RU", {
        weekday: "short",
        day: "2-digit",
        month: "long",
        year: "numeric"
      });
    },

    updateExamSheet(exam) {
      this.$emit("update", exam);
    },

    downloadExamSheet(exam) {
      this.$emit("download", exam, this.marks);
    },

    marksCount() {
      this.exam.items.map(item => (item.mark ? item.mark.name : ""));

      this.exam.items.forEach(item =>
        item.mark
          ? (this.marks[item.mark.name] = (this.marks[item.mark.name] || 0) + 1)
          : ""
      );
    }
  }
};
</script>
