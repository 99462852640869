<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <change-tag-dialog @create="createTag" />

      <v-switch
        class="ml-4"
        dense
        hide-details
        v-model="isArchival"
        label="В архиве"
      />
    </v-toolbar>

    <v-divider />

    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>
          <th class="col-4">Действия</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="tag in allTags" :key="tag.id">
          <td>{{ tag.name }}</td>

          <td>
            <change-tag-dialog
              @update="updateTag"
              :tag="tag"
              :controllers="controllers"
            />

            <v-btn icon @click="archivingTag(tag)">
              <v-icon :color="archivalColor">{{ archivalIcon }}</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import {
  archivingOrRestoringTag,
  createTag,
  updateTag
} from "../../services/tags.service";
import ChangeTagDialog from "./ChangeTagDialog";
export default {
  name: "TagsListTable",
  components: { ChangeTagDialog },

  props: {
    group: {
      type: String,
      default() {
        return "null";
      }
    },

    allTags: Array
  },

  data() {
    return {
      controllers: [],

      isArchival: false
    };
  },

  computed: {
    archivalIcon() {
      return this.isArchival
        ? "mdi-archive-arrow-up"
        : "mdi-archive-arrow-down";
    },

    archivalColor() {
      return this.isArchival ? "gray" : "red";
    }
  },

  watch: {
    isArchival: {
      handler(newValue) {
        this.$emit("archiving", newValue);
      }
    }
  },

  methods: {
    async createTag(tag) {
      try {
        await createTag({ name: tag.name || tag, archival: false });

        this.emitToUpdate();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateTag(tag) {
      try {
        await updateTag(tag.id, { name: tag.name });

        this.emitToUpdate();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async archivingTag(tag) {
      try {
        if (!tag.archival) {
          await archivingOrRestoringTag(tag.id, { archival: true });
        } else {
          await archivingOrRestoringTag(tag.id, { archival: false });
        }

        this.emitToUpdate();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    emitToUpdate() {
      return this.$emit("update", this.isArchival);
    }
  }
};
</script>
