<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{ on, attr }">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-access-point</v-icon> Запрос на терминал
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Запрос на терминал</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="requestModel.eventType"
                :items="actionTypes"
                item-value="type"
                item-text="name"
                label="Действие"
              />
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="requestModel.controllerId"
                :items="controllers"
                item-value="id"
                item-text="name"
                label="Терминал"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" text color="red">Отмена</v-btn>
        <v-btn @click="create" text color="primary" :disabled="!valid">
          Запросить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getControllers, createRequest } from "@/services/device.service";
import { getUserById } from "../../services/user.service";

export default {
  name: "CreateIdentificatorDialog",

  data: () => ({
    show: false,
    valid: false,
    loading: false,
    creatingError: false,

    actionTypes: [
      {
        name: "Добавить идентификатор",
        type: "add"
      }
    ],

    errorText: "",

    rules: [v => !!v || "Это обязательное поле"],

    requestModel: {
      user: {},
      eventType: "add",
      controllerId: null
    },

    controllers: []
  }),

  computed: {
    isUserExists() {
      return Object.keys(this.user).length;
    },

    isReceivedUserAnIncomingUser() {
      return this.user.id === +this.$route.params.userId;
    }
  },

  watch: {
    show: {
      handler(newValue) {
        if (!newValue) this.creatingError = false;
      }
    },
    controllers: {
      handler(v) {
        this.requestModel.controllerId = v[0].id;
      }
    }
  },

  mounted() {
    this.getControllers();
  },

  methods: {
    close() {
      this.show = false;
    },

    async create() {
      try {
        const { userId } = this.$route.params;

        this.requestModel.user = await getUserById(userId);
        const request = await createRequest(this.requestModel);
        if (request.status == "success") {
          this.$notify({
            text: "Страница на терминале открыта",
            type: "success"
          });
        } else {
          this.$notify({
            text: "Терминал занят",
            type: "error"
          });
        }

        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.close();
      }
    },

    async getControllers() {
      try {
        this.controllers = await getControllers({ type: 4 });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
