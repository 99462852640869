<template>
  <v-container fluid>
    <events-list />
  </v-container>
</template>

<script setup>
import EventsList from "@/components/events/EventsList.vue";

export default {
  components: { EventsList }
};
</script>
