<template>
  <v-simple-table fixed-header dense>
    <thead>
      <tr>
        <th class="col-1">Активный</th>
        <th>Точка доступа</th>
        <th>Начало</th>
        <th>Окончание</th>
        <th class="col-2" />
      </tr>
    </thead>

    <tbody style="max-height: 200px">
      <template v-if="rules.length">
        <tr v-for="rule in rules" :key="rule.id">
          <td class="col-1">
            <v-icon :color="rule.active ? 'green' : 'grey'">{{
              rule.active ? "mdi-check-circle" : "mdi-close-circle"
            }}</v-icon>
          </td>
          <td>{{ rule.accessPointName }}</td>
          <td>{{ rule.beginDate || "" }}</td>
          <td>{{ rule.endDate || "Бессрочно" }}</td>
          <td>
            <create-rule
              :rule="rule"
              :type="rule.beginDate ? 'expire' : 'infinity'"
              @update-rule="updateRule"
            />

            <v-btn icon @click="deleteRule(rule.id)" small>
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <tr v-else>
        <td colspan="3">
          <p>Правил нет</p>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import CreateRule from "./CreateRule";
export default {
  name: "ListTable",
  components: { CreateRule },
  props: {
    rules: Array
  },

  methods: {
    updateRule(params) {
      this.$emit("update-rule", params);
    },

    deleteRule(ruleId) {
      this.$emit("delete-rule", ruleId);
    }
  }
};
</script>
