<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-plus</v-icon> Добавить
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <v-form v-model="valid" class="row">
          <div class="col-9">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Название"
              autofocus
              required
            />
          </div>

          <div class="col-3">
            <v-text-field
              :value="group.toUpperCase()"
              label="Категория"
              disabled
            />
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="close">
          Отмена
        </v-btn>
        <v-btn
          :disabled="!valid"
          type="submit"
          color="primary"
          text
          @click="create"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createCategory } from "@/services/category.service";
import { session } from "@/services/session.service";
import { updateUserManagement } from "@/services/user.service";

export default {
  name: "CreateCategoryDialog",

  props: {
    group: { type: String, required: true }
  },

  data: () => ({
    show: false,
    valid: false,
    name: "",

    nameRules: [
      v => !!v || "Это обязательное поле",
      v => (v && v.length > 1) || " Название должно быть длинее 2 символов"
    ]
  }),

  computed: {
    title() {
      const titles = {
        staff: "Новое подразделение",
        entrant: "Новый факультет"
      };

      return titles[this.group];
    },

    buttonText() {
      const label = {
        staff: "Создать подразделение",
        entrant: "Создать факультет"
      };

      return label[this.group];
    }
  },

  methods: {
    async create() {
      try {
        const category = await createCategory(
          this.name.trim(),
          this.group.trim()
        );

        await updateUserManagement(session.user.id, [
          ...session.user.management,
          { category }
        ]);

        session.user.management.push({ category });
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.close();
      }
    },

    close() {
      this.name = "";
      this.show = false;
    }
  }
};
</script>
