<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="userModel.login"
        label="Логин (имя пользователя)"
        class="mt-3"
        readonly
      />
    </v-col>

    <v-col cols="6">
      <v-text-field
        :value="userModel.defaultPassword ? userModel.password : ''"
        :hint="userModel.defaultPassword ? '' : 'Пароль задан пользователем'"
        label="Пароль"
        persistent-hint
        readonly
      >
        <template #append>
          <v-btn @click="generateRandomPassword" color="primary" text>
            <v-icon left>mdi-lock</v-icon>
            Сгенерировать пароль
          </v-btn>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="4">
      <v-switch
        v-model="userModel.active"
        dense
        label="Доступ в личный кабинет"
      />
    </v-col>
  </v-row>
</template>

<script>
import { generateRandomPassword } from "@/services/user.service";

export default {
  name: "CredentialForm",

  model: {
    event: "update",
    prop: "user"
  },

  props: {
    user: { type: Object, required: true }
  },

  data() {
    return {
      regex: new RegExp(/^[23456789qwertyupasdfghjkzxcvbnm]+$/, "i")
    };
  },

  computed: {
    userModel: {
      get() {
        return this.user;
      },

      set(newModel) {
        this.$emit("update", newModel);
      }
    }
  },

  methods: {
    async generateRandomPassword() {
      try {
        this.userModel = await generateRandomPassword(
          this.$route.params.userId
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
