import { saveObject } from "./file.service";
import { Workbook } from "exceljs";

async function toXLS(rows) {
  let workbook = new Workbook();
  workbook.calcProperties.fullCalcOnLoad = true;

  let worksheet = workbook.addWorksheet("Стр. 1");

  for (let i = 0; i < rows.length; i++) {
    let row = worksheet.getRow(i + 1);
    for (let j = 0; j < rows[i].length; j++) {
      let cell = row.getCell(j + 1);
      cell.value = rows[i][j].value;
      cell.alignment = {
        horizontal: rows[i][j].align
      };

      if (rows[i][j].colspan) {
        let end = row.getCell(j + rows[i][j].colspan);
        worksheet.mergeCells(`${cell._address}:${end._address}`);
      }

      if (rows[i][j].width) {
        worksheet.getColumn(j + 1).width = rows[i][j].width;
      }

      if (rows[i][j].header) {
        cell.style = {
          font: {
            bold: true
          }
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD6E5CB" }
        };
      }
    }
  }

  worksheet.eachRow(row => {
    row.eachCell(cell => {
      if (!cell.font?.size) {
        cell.font = Object.assign(cell.font || {}, { size: 9 });
      }

      if (!cell.font?.name) {
        cell.font = Object.assign(cell.font || {}, { name: "Arial" });
      }

      cell.border = {
        top: { style: "thin", color: { argb: "acc8bd" } },
        left: { style: "thin", color: { argb: "acc8bd" } },
        bottom: { style: "thin", color: { argb: "acc8bd" } },
        right: { style: "thin", color: { argb: "acc8bd" } }
      };
    });
  });

  let arr = await workbook.xlsx.writeBuffer();
  let blob = new Blob([arr.buffer], { type: "application/octet-stream" });

  return blob;
}

async function downloadXLS(rows, fileName) {
  const blob = await toXLS(rows);
  return saveObject(blob, fileName);
}

export { downloadXLS };
