<template>
  <page>
    <list-table-speciality />
  </page>
</template>

<script>
import Page from "../../components/Page";
import ListTableSpeciality from "../../components/group-management/ListTableSpeciality.vue";

export default {
  name: "SpecialityGroupList",
  components: { ListTableSpeciality, Page }
};
</script>

<style scoped></style>
