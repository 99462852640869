import { CONTROLLERS_ROUTE } from "@/router/names";

import ControllersList from "@/views/devices/ControllersList";

export const controllerRoutes = [
  {
    path: "/controllers",
    name: CONTROLLERS_ROUTE,
    component: ControllersList,
    meta: {
      type: "controllers",
      group: "Управление устройствами",
      title: "Контроллеры",
      icon: "mdi-developer-board",
      linked: true,
      breadcrumbs: [
        {
          text: "Главная",
          link: true,
          exact: true,
          to: { name: "home" }
        },
        {
          text: "Контроллеры",
          disabled: true,
          link: true,
          to: { name: CONTROLLERS_ROUTE }
        }
      ]
    }
  }
];
