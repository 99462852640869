import { Api } from "./api.service";
import qs from "qs";

export function getTags(params) {
  const query = qs.stringify(params);
  return Api.get(`/tag${query && "?" + query}`);
}

export function getTagsStats() {
  return Api.get("/tag/stats");
}

export function createTag(params) {
  return Api.post(`/tag`, params);
}

export function updateTag(tagId, params) {
  return Api.put(`/tag/${tagId}`, params);
}

export function archivingOrRestoringTag(tagId, params) {
  const query = qs.stringify(params);
  return Api.put(`/tag/${tagId}/archival${query && "?" + query}`);
}

export function deleteTag(tagId) {
  return Api.delete(`/tag/${tagId}`);
}
