var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('discipline-toolbar',{on:{"load":_vm.getParams}}),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.discipline.content,"options":_vm.tableOptions,"server-items-length":_vm.discipline && _vm.discipline.totalElements,"footer-props":{ itemsPerPageOptions: [25, 50, 100] },"dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.fio",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_c('router-link',{staticClass:"black--text",attrs:{"to":{
              name: ("" + (_vm.params.categoryType === 'STAFF'
                  ? 'STAFF_EDIT_ROUTE'
                  : _vm.params.categoryType === 'ENTRANT'
                  ? 'USERS_EDIT_ROUTE'
                  : 'PPS_EDIT_ROUTE')),
              params: {
                group: _vm.params.categoryType,
                userId: item.userId
              }
            }}},[_vm._v(_vm._s(item.fio))])],1),_c('p',{staticClass:"ma-0 pb-2 grey--text",staticStyle:{"font-size":"0.8rem"}},[_vm._v(" "+_vm._s(("(" + (item.description) + ")"))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }