<template>
  <div class="row">
    <div class="col-12 mb-4">
      <v-select
        v-model="employment.categoryId"
        :label="label"
        :items="categorySortedList"
        :rules="categoryRules"
        item-text="name"
        item-value="id"
        dense
        required
        :disabled="!group"
      />
    </div>

    <template v-if="isEntrant">
      <div class="col-6 pt-0 mb-4">
        <v-select
          v-model="selectedCourse"
          :rules="courseRules"
          :items="coursesList"
          :disabled="!employment.categoryId"
          dense
          label="Курс"
        />
      </div>

      <div class="col-6 pt-0 mb-4">
        <v-select
          v-model="employment.pupilGroup"
          :items="groupListByCourse || '-'"
          :rules="groupRules"
          :disabled="!selectedCourse"
          dense
          item-text="liter"
          return-object
          label="Группа"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { getCategoryList } from "../../../services/user.service";
import { getPupilGroupList } from "../../../services/category.service";
import { getManagement } from "../../../services/session.service";

export default {
  name: "EmploymentChoose",

  props: {
    group: { required: true, type: String }
  },

  data: () => ({
    employment: {},

    categoryList: [],
    pupilGroupsList: [],
    selectedCourse: null,

    courseRules: [v => (v && /[0-9]/.test(v)) || "Курс должен быть числом"],
    categoryRules: [v => !!v || "Это обязательное поле"],
    groupRules: [v => !!v || "Это обязательное поле"]
  }),

  computed: {
    isEntrant() {
      return this.group.toLowerCase() === "entrant";
    },

    label() {
      const label = {
        staff: "Категория пользователя",
        pps: "Категория пользователя",
        entrant: "Факультет"
      };

      return label[this.group.toLowerCase()];
    },

    coursesList() {
      return Array.from(
        new Set(this.pupilGroupsList.map(el => el.course))
      ).sort((a, b) => a - b);
    },

    categorySortedList() {
      return (getManagement(this.group) || this.categoryList).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },

    groupListByCourse() {
      return this.pupilGroupsList.filter(i => i.course === this.selectedCourse);
    }
  },

  watch: {
    "employment.categoryId": {
      async handler() {
        try {
          if (this.employment.categoryId) {
            await this.getPupilGroupList();
          }

          if (this.employment.pupilGroup) {
            this.employment.pupilGroup = null;
          }

          this.$emit("update", this.employment);
        } catch (e) {
          this.$notify({
            text: e.response ? e.response.data.message : e,
            type: "error"
          });
        }
      }
    },

    "employment.pupilGroup": {
      handler() {
        this.$emit("update", this.employment);
      }
    }
  },

  methods: {
    getManagement,

    async getPupilGroupList() {
      this.pupilGroupsList = await getPupilGroupList({
        categoryId: this.employment.categoryId
      });
    },

    async getCategoryList() {
      try {
        this.categoryList = await getCategoryList(this.group);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
