<template>
  <v-card outlined>
    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>

          <th>Метки</th>

          <th>Действия</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="controller in controllers" :key="controller.id">
          <td>{{ controller.name }}</td>

          <td>
            <v-chip-group column>
              <v-chip
                v-for="tag in controller.accessPoint.tags"
                :key="tag.id"
                small
              >
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </td>

          <td>
            <change-acs-tags-dialog
              :controller="controller"
              :all-tags="allTags"
              @update="updateTags"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { getControllers, updateTags } from "../../services/device.service";
import ChangeAcsTagsDialog from "./ChangeAcsTagsDialog";

export default {
  name: "AccessPointsTable",
  components: { ChangeAcsTagsDialog },

  props: {
    allTags: Array
  },

  data() {
    return {
      controllers: []
    };
  },

  mounted() {
    this.getControllers();
  },

  methods: {
    async getControllers() {
      try {
        const allControllers = await getControllers();

        this.controllers = allControllers.filter(
          controller => controller.type !== 2 && controller.name
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateTags(tagId, newTags) {
      try {
        await updateTags(tagId, newTags);

        await this.getControllers();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
