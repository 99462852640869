var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.dataset),function(controllers,name){return _c('v-col',{key:name,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(name))]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Название")]),_c('th',[_vm._v("Активен?")]),_c('th',[_vm._v("Шлюзы")]),_c('th',[_vm._v("Обновлено")])])]),_c('tbody',_vm._l((controllers),function(controller,index){return _c('tr',{key:controller.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"col-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyHost(controller.host)}}},[_vm._v(" "+_vm._s(controller.name)+" ")]),_c('td',{staticClass:"col-1"},[_c('v-badge',{attrs:{"color":controller.active ? 'green' : '#808080',"dot":""}})],1),_c('td',{staticClass:"col-3"},_vm._l((controller.gateways),function(gateway){return _c('v-tooltip',{key:gateway.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [(gateway.type === 'door')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":gateway.active && controller.active
                          ? 'green'
                          : '#808080'}},'v-icon',attr,false),on),[_vm._v(" mdi-door ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":gateway.active && controller.active
                          ? 'green'
                          : '#808080'}},'v-icon',attr,false),on),[_vm._v(" mdi-turnstile ")])]}}],null,true)},[_vm._v(" "+_vm._s(gateway.name)+" ")])}),1),_c('td',[_vm._v(" "+_vm._s(new Date(controller.modification).toLocaleString("ru-RU"))+" ")])])}),0)])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }