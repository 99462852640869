<template>
  <v-dialog v-model="show" persistent width="720">
    <template #activator="{on, attr}">
      <v-btn v-on="on" v-bind="attr" text color="primary">
        <v-icon left>{{ activatorButtonIcon }}</v-icon>
        {{ activatorButtonTitle }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title> {{ editorTitle }} </v-card-title>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model.trim="newsModel.text"
                no-resize
                rows="4"
                label="Текст объявления"
              />
            </v-col>
          </v-row>

          <p class="text-caption mt-4">Настройки голосования</p>

          <v-row>
            <v-col cols="8">
              <v-combobox
                v-model="newsModel.categories"
                multiple
                clearable
                :items="categories"
                item-text="name"
                :item-value="item => item"
                label="Категория"
              />
            </v-col>

            <v-col cols="4">
              <v-combobox
                v-model="newsModel.courses"
                multiple
                clearable
                :items="courses"
                label="Курс"
              />
            </v-col>

            <v-col cols="12">
              <v-menu
                v-model="showDatepicker"
                ref="menu"
                :close-on-content-click="false"
                :return-value.sync="newsModel.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newsModel.date"
                    label="Дата публикации"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="newsModel.date"
                  no-title
                  scrollable
                  @change="$refs.menu.save(newsModel.date)"
                  class="pb-2"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn @click="close" text> Отмена </v-btn>
        <v-btn @click="save" color="primary" :disabled="!canSave" text>
          {{ saveButtonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { getCategoryList } from "../../services/category.service";
import { createNews, updateNews } from "../../services/news.service";

export default {
  name: "NewsEditor",

  props: {
    news: Object,
    isEdit: Boolean
  },

  data() {
    return {
      show: false,
      showDatepicker: false,

      categories: [],
      courses: [1, 2, 3, 4, 5],

      newsModel: {
        text: "",
        date: new Date().toISOString().substring(0, 10),
        archival: false,
        published: false,
        categories: [],
        courses: []
      }
    };
  },

  watch: {
    show(value) {
      value && this.getCategories();
    }
  },

  computed: {
    activatorButtonTitle() {
      return this.isEdit ? "Изменить новость" : "Создать новость";
    },

    activatorButtonIcon() {
      return this.isEdit ? "mdi-pencil" : "mdi-plus";
    },

    editorTitle() {
      return this.isEdit ? "Изменение новости" : "Создание новости";
    },

    saveButtonTitle() {
      return this.isEdit ? "Обновить" : "Создать";
    },

    canSave() {
      return this.newsModel.text && this.newsModel.date;
    }
  },

  mounted() {
    if (this.news) {
      Vue.set(this, "newsModel", {
        ...this.newsModel,
        ...this.news
      });
    }
  },

  methods: {
    async getCategories() {
      try {
        const departments = await getCategoryList("staff");
        const faculties = await getCategoryList("entrant");

        this.categories = [...departments, ...faculties];
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getCategoryType(categoryId) {
      const category = this.categories.find(c => c.id === categoryId);

      if (category) {
        return category.type;
      }
    },

    close() {
      this.show = false;
    },

    async save() {
      this.news
        ? await updateNews(this.newsModel)
        : await createNews(this.newsModel);
      this.$emit("update");
      this.close();
    }
  }
};
</script>
