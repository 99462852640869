<template>
  <v-dialog width="640" v-model="show">
    <template #activator="{ on }">
      <label>
        <v-avatar size="128" color="grey lighten-3">
          <img
            v-if="croppedImage !== 'deleted' && (croppedImage || user.photo)"
            :src="croppedImage || user.photo"
          />
          <v-icon v-else>mdi-camera</v-icon>
        </v-avatar>

        <input
          v-if="hasRouteParams"
          type="file"
          hidden
          ref="inputFile"
          accept="image/*"
          @change="onChangeFile"
        />
        <div v-on="on" />
      </label>

      <v-btn
        v-if="hasRouteParams"
        block
        color="error"
        text
        class="mt-2"
        @click="deleteImage"
      >
        <v-icon left>mdi-delete</v-icon>
        Удалить фото
      </v-btn>

      <v-switch
        v-if="hasRouteParams"
        v-model="user.validPhoto"
        :true-value="true"
        :false-value="false"
        label="Фото проверено"
        dense
        hide-details
      />
    </template>

    <v-card>
      <v-card-title>Обрезка изображения</v-card-title>

      <div style="height: 480px">
        <img :src="image" ref="cropper" />
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="crop">
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "UserAvatar",
  props: {
    user: Object
  },

  data() {
    return {
      show: false,
      image: null,
      cropper: null,
      croppedImage: null
    };
  },

  computed: {
    hasRouteParams() {
      return Object.keys(this.$route.params).length;
    }
  },

  watch: {
    show: {
      handler(newValue) {
        this.$nextTick(() => {
          if (newValue) {
            this.cropper = new Cropper(this.$refs.cropper, {
              dragMode: "move",
              cropBoxMovable: false,
              cropBoxResizable: false,
              responsive: false,
              restore: false,
              center: false,
              aspectRatio: 1,
              autoCropArea: 1,
              toggleDragModeOnDblclick: false,
              minCanvasWidth: 360,
              maxCanvasHeight: 480
            });
          } else {
            this.cropper && this.cropper.destroy();
            this.cropper = null;
          }
        });
      }
    },

    "user.validPhoto": function(newVal, oldVal) {
      this.user.validPhoto = newVal;

      if (typeof oldVal === "boolean") {
        this.$emit("update-user", this.user);
      }
    }
  },

  methods: {
    onChangeFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = e => {
        this.image = e.target.result;
        this.show = true;

        this.$refs.inputFile.value = null;
      };
    },

    crop() {
      this.croppedImage = this.cropper
        .getCroppedCanvas({ width: 196, height: 196, fillColor: "#eeeeee" })
        .toDataURL("image/jpeg", 0.84);

      this.image = null;
      this.show = false;

      this.$emit("update-image", this.croppedImage);
    },

    deleteImage() {
      this.croppedImage = "deleted";
      this.$emit("update-image", null);
    }
  }
};
</script>
