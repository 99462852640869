<template>
  <page>
    <news-list />
  </page>
</template>

<script>
import NewsList from "../../components/news/NewsList";
import Page from "../../components/Page";

export default {
  name: "NewsAndVoting",
  components: { NewsList, Page }
};
</script>
