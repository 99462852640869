<template>
  <div>
    <v-card outlined>
      <v-card flat>
        <v-card-title>
          <h4>Основная информация</h4>

          <v-spacer />

          <h4 v-if="user.archival" class="red--text">Архивный</h4>
        </v-card-title>

        <v-card-text>
          <personal-info-form v-model="user" @update-user="update" />
        </v-card-text>
      </v-card>

      <v-divider />

      <v-card-actions>
        <v-btn text color="primary" @click="print">
          Печать наклейки
        </v-btn>

        <v-spacer />

        <deleting-user-dialog @delete-user-forever="deleteUserForever" />

        <v-btn text :color="archivalColor" @click="movingArchival">
          <v-icon left>{{ archivalIcon }}</v-icon>
          {{ archivalName }}
        </v-btn>

        <v-btn text color="primary" @click="update">Сохранить</v-btn>
      </v-card-actions>
    </v-card>

    <v-row class="my-2">
      <v-col>
        <moderate-information
          :user="user"
          :user-management="userManagement"
          :category-list="categoryList"
          @update="newAccess => (accessManagement = newAccess)"
        />
      </v-col>

      <v-col>
        <departments-information :category-list="categoryList" :user="user" />
      </v-col>
    </v-row>
    <v-row v-if="accessManagement.canModerate || accessManagement.canGivePass">
      <v-col>
        <copy-user-management
          :access-management="accessManagement"
          :user="user"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CopyUserManagement from "../UserLK/CopyUserManagement.vue";
import { updateUser } from "@/services/user.service";
import PersonalInfoForm from "@/components/user-management/UserInformation/PersonalInfoForm";
import {
  deleteUserForever,
  movingArchival
} from "../../../services/user.service";
import { printSticker } from "../../../services/printStickers.service";
import DepartmentsInformation from "../DepartmentsInformation";
import ModerateInformation from "../ModerateInformation";
import DeletingUserDialog from "./DeletingUserDialog";
import { PPS_ROUTE, STAFF_ROUTE, USERS_ROUTE } from "../../../router/names";

export default {
  name: "GeneralInformation",
  components: {
    DeletingUserDialog,
    ModerateInformation,
    DepartmentsInformation,
    PersonalInfoForm,
    CopyUserManagement
  },
  model: {
    event: "update",
    prop: "user"
  },

  props: {
    user: { type: Object, required: true },
    categoryList: { type: Array, default: () => [] },
    userManagement: { type: Array, default: () => [] }
  },

  data() {
    return {
      accessManagement: {},
      coursesList: [],
      groupsList: [],

      credentialValid: false,
      passwordRegex: new RegExp(/^[23456789qwertyupasdfghjkzxcvbnm]+$/, "i"),
      rules: [
        v => (v && v.length > 3) || "Минимальное число символов: 4",
        v =>
          (v && this.passwordRegex.test(v.toLowerCase())) ||
          "Недопустимые символы"
      ]
    };
  },

  computed: {
    archivalName() {
      return this.user.archival ? "Вернуть из архива" : "Отправить в архив";
    },

    archivalIcon() {
      return this.user.archival
        ? "mdi-archive-arrow-up"
        : "mdi-archive-arrow-down";
    },

    archivalColor() {
      return this.user.archival ? "primary" : "red darken-4";
    },

    userLogin() {
      const stringUserId = String(this.user.id);

      if (stringUserId.length < 4) {
        return stringUserId.padStart(4, "0");
      }

      return stringUserId;
    }
  },

  methods: {
    async update() {
      await updateUser(this.user);
    },

    async movingArchival() {
      try {
        await movingArchival(this.user.id, { archival: !this.user.archival });
        this.user.archival = !this.user.archival;
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async deleteUserForever() {
      try {
        await deleteUserForever(this.user.id);

        const group =
          this.user.mainGroup.name === "ENTRANT"
            ? "USERS"
            : this.user.mainGroup.name;
        const routeName = `${group}_ROUTE`;

        await this.$router.push({
          name: routeName,
          params: { userId: this.user.id }
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    print() {
      printSticker(
        [
          this.user.description,
          "Логин: " + this.userLogin,
          this.user.name,
          this.user.surname
        ],
        { fontSize: "10vmin" }
      );
    }
  }
};
</script>
