import { Api } from "./api.service";
import { sortBy } from "lodash";
import qs from "qs";

function getPass(params) {
  const query = qs.stringify(params);
  return Api.get(`/pass/find${query && "?" + query}`).then(p =>
    sortBy(p, "id")
  );
}

function getPassById(passId) {
  return Api.get(`/pass/${passId}`);
}

function createPass(pass) {
  return Api.post("/pass", pass);
}

function updatePass(pass) {
  return Api.put(`/pass/${pass.id}`, pass);
}

export { getPass, createPass, getPassById, updatePass };
