<script>
import CategoryRules from "@/components/group-management/CategoryRules.vue";

export default {
  components: { CategoryRules },

  props: {
    event: Object
  },

  computed: {
    categoryId() {
      return this.event.categoryId;
    },

    category() {
      return this.categoryId ? { id: this.categoryId } : null;
    }
  }
};
</script>

<template>
  <category-rules v-if="category" :category="category"></category-rules>
</template>
