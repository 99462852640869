import { Api } from "./api.service";
import qs from "qs";

function getCategoryList(type = "staff") {
  return Api.get(`/category/${type}`);
}

function getCoursesList(categoryId) {
  return Api.get(`/category/${categoryId}/courses`);
}

function getGroupsList(categoryId, courseId) {
  return Api.get(`/category/${categoryId}/course/${courseId}`);
}

function getCategoryByType(type) {
  return Api.get(`category/all/${type}`);
}

function createCategory(name, type) {
  return Api.post(`/category`, { name, type });
}

function updateCategory(params) {
  return Api.put(`/category`, params);
}

function getCategoryAccessRules(categoryId) {
  return Api.get(`/category/${categoryId}/access/rule`);
}

function createCategoryAccessRules(categoryId, params) {
  return Api.post(`/category/${categoryId}/access/rule`, params);
}

function updateCategoryAccessRule(params) {
  return Api.put(`/category/access/rule`, params);
}

function deleteCategoryAccessRules(categoryId) {
  return Api.delete(`/category/access/rule/${categoryId}`);
}

function getSpecialityList(params) {
  const query = qs.stringify(params);
  return Api.get(`/speciality${query && "?" + query}`);
}

function createSpeciality(params) {
  return Api.post(`/speciality`, params);
}

function getSpecialityListByType(type, params) {
  const query = qs.stringify(params);
  return Api.get(`/speciality/items/${type}${query && "?" + query}`);
}

function updateSpeciality(id, params) {
  return Api.put(`/speciality/${id}`, params);
}

function getPupilGroupList(params) {
  const query = qs.stringify(params);
  return Api.get(`/pupil-group${query && "?" + query}`);
}

function createPupilGroup(params) {
  return Api.post(`/pupil-group`, params);
}

function updatePupilGroup(id, params) {
  return Api.put(`/pupil-group/${id}`, params);
}

export {
  getGroupsList,
  getCoursesList,
  getCategoryList,
  getCategoryByType,
  createCategory,
  updateCategory,
  getCategoryAccessRules,
  createCategoryAccessRules,
  updateCategoryAccessRule,
  deleteCategoryAccessRules,
  getSpecialityList,
  createSpeciality,
  getSpecialityListByType,
  updateSpeciality,
  getPupilGroupList,
  createPupilGroup,
  updatePupilGroup
};
