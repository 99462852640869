import qs from "qs";
import { Api } from "./api.service";

function findUser(params) {
  const query = qs.stringify(params);
  return Api.get(`/user/find${query && "?" + query}`);
}

function getUserById(userId) {
  return Api.get(`/user/${userId}`);
}

function getManagers(categoryId) {
  return Api.get(`/user/managers/${categoryId}`);
}

function getUserBotToken(userId) {
  return Api.get(`/user/${userId}/bot-token`);
}

function getUserManagement(userId) {
  return Api.get(`/user/${userId}/management`);
}

function getUserCategories(userId) {
  return Api.get(`/user/${userId}/categories`);
}

function getCoursesList(categoryId) {
  return Api.get(`/category/${categoryId}/courses`);
}

function getGroupsList(categoryId, courseId) {
  return Api.get(`/category/${categoryId}/course/${courseId}`);
}

function getCategoryList(type = "staff") {
  return Api.get(`/category/${type}`);
}

function getUserIdentifierOperation(userId) {
  return Api.get(`/user/${userId}/identifier-operations`);
}

function generateRandomPassword(userId) {
  return Api.get(`/user/${userId}/password`);
}

function getUserAccessPoints(userId) {
  return Api.get(`/user/${userId}/access/rule`);
}

function getAcsEvent(userId, params) {
  const query = qs.stringify(params);
  return Api.get(`/user/${userId}/acs/event${query && "?" + query}`);
}

function getUserByIdentifierKey(key) {
  return Api.get(`/user/identifier/${key}`);
}

function getUserTags(userId) {
  return Api.get(`/user/${userId}/tags`);
}

function updateUser(user) {
  return Api.put(`/user`, user);
}

function updateUserGroup(userId, groupName) {
  return Api.put(`/user/${userId}/group?name=${groupName}`);
}

function updateUserManagement(userId, management) {
  return Api.put(`/user/${userId}/management`, management);
}

function updateCategory(userId, params) {
  return Api.put(`/user/${userId}/categories`, params);
}

function updateUserAccessPoint(params) {
  return Api.put(`/user/access/rule`, params);
}

function updateUserPhoto(userId, params) {
  return Api.put(`/user/${userId}/photo`, params);
}

function updateIdentifier(params) {
  return Api.put(`/identifier`, params);
}

function movingArchival(userId, params) {
  const query = qs.stringify(params);
  return Api.put(`/user/${userId}/archival`, query);
}

function updateUserTag(userId, params) {
  return Api.put(`/user/${userId}/tags`, params);
}

function createUser(categoryId, params) {
  return Api.post(`/category/${categoryId}/user`, params);
}

function createUsers(categoryId, params) {
  return Api.post(`/category/${categoryId}/users`, params);
}

function createIdentificator(userId, params) {
  return Api.post(`/user/${userId}/identifier`, params);
}

function createUserAccessPoints(userId, params) {
  return Api.post(`/user/${userId}/access/rule`, params);
}

function deleteIdentifier(id) {
  return Api.delete(`/identifier/${id}`);
}

function deleteUserAccessPoints(userId) {
  return Api.delete(`/user/access/rule/${userId}`);
}

function deleteUserForever(userId) {
  return Api.delete(`/user/${userId}`);
}

function deleteUserTag(userId, tagId) {
  return Api.delete(`/user/${userId}/tags/${tagId}`);
}

function updatePupilGroup(userId, params) {
  return Api.put(`/user/${userId}/pupil-group`, params);
}

export {
  findUser,
  getUserById,
  getManagers,
  getUserCategories,
  getCategoryList,
  getCoursesList,
  getUserManagement,
  getGroupsList,
  getUserIdentifierOperation,
  getUserAccessPoints,
  generateRandomPassword,
  getAcsEvent,
  getUserByIdentifierKey,
  getUserTags,
  updateUser,
  movingArchival,
  updateCategory,
  updateIdentifier,
  updateUserManagement,
  updateUserGroup,
  updateUserAccessPoint,
  updateUserPhoto,
  createUser,
  createUsers,
  createIdentificator,
  createUserAccessPoints,
  updateUserTag,
  deleteIdentifier,
  deleteUserAccessPoints,
  deleteUserForever,
  deleteUserTag,
  getUserBotToken,
  updatePupilGroup
};
