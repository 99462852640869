<template>
  <div>
    <v-card outlined>
      <v-card flat>
        <v-card-title>
          <h4>Основная информация о посетителе</h4>
        </v-card-title>

        <v-card-text>
          <pass-info-form v-model="pass" :management="management" />
        </v-card-text>
      </v-card>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="update">Сохранить</v-btn>
      </v-card-actions>
    </v-card>

    <v-row class="my-2">
      <v-col>
        <pass-creator-information :creator="pass.creator" />
      </v-col>

      <v-col>
        <copy-pass-information :pass="pass" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CopyPassInformation from "./CopyPassInformation.vue";
import PassInfoForm from "./PassInfoForm.vue";
import PassCreatorInformation from "./PassCreatorInformation.vue";
import { updatePass } from "../../services/pass.service";
import { withNotifications } from "@/utils/notifications";

export default {
  name: "GeneralInformation",
  components: {
    PassInfoForm,
    CopyPassInformation,
    PassCreatorInformation
  },
  model: {
    event: "update",
    prop: "pass"
  },

  props: {
    pass: { type: Object, required: true },
    management: { type: Array, default: () => [] }
  },

  data() {
    return {};
  },

  methods: {
    async update() {
      withNotifications(async () => {
        await updatePass(this.pass);
      });
    }
  }
};
</script>
