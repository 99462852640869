var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Список идентификаторов ")]),_c('v-divider'),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('create-identificator-dialog',{on:{"update":function($event){return _vm.$emit('update')}}}),_c('create-identificator-request-dialog',{on:{"update":function($event){return _vm.$emit('update')}}}),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"hide-details":"","single-line":"","prepend-icon":"mdi-magnify","placeholder":"Поиск..."},model:{value:(_vm.idSearch),callback:function ($$v) {_vm.idSearch=$$v},expression:"idSearch"}})],1),_c('v-divider'),(_vm.edit)?_c('edit-identifier-dialog',{attrs:{"selected":_vm.selectedIdentifier},on:{"update":function($event){return _vm.$emit('update')}},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}}):_vm._e(),_c('v-data-table',{attrs:{"dense":"","search":_vm.idSearch,"headers":_vm.idHeaders,"items":_vm.identifiers,"sort-by":"updateDate","sort-desc":true},on:{"click:row":_vm.editIdentifier},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":_vm.getIdentifierColor(item.type)}},[_vm._v(" "+_vm._s(_vm.getIdentifierIcon(item.type))+" ")]),_vm._v(" "+_vm._s(_vm.getIdentifierName(item.type))+" ")],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"id":item.id + '-active',"value":item.active,"disabled":""}})]}},{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createDate).toLocaleString("ru-RU"))+" ")]}},{key:"item.updateDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updateDate).toLocaleString("ru-RU"))+" ")]}}])})],1)],1),_c('v-card',{staticClass:"my-4",attrs:{"outlined":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Операции с идентификаторами")]),_c('v-divider'),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"hide-details":"","single-line":"","prepend-icon":"mdi-magnify","placeholder":"Поиск..."},model:{value:(_vm.idDesc),callback:function ($$v) {_vm.idDesc=$$v},expression:"idDesc"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","search":_vm.idDesc,"headers":_vm.opHeaders,"items":_vm.operations,"sort-by":"date","sort-desc":true},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.upperFirst(item.description))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('operation-type',{attrs:{"type":item.type}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toLocaleString("ru-RU"))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }