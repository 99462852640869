<template>
  <div class="pa-4">
    <v-tabs v-model="tabModel">
      <v-tab v-if="session.permission.isAllow('tabs', 'access_control')">
        Мониторинг
      </v-tab>

      <v-tab>Статистика: контингент</v-tab>

      <v-tab v-if="session.permission.isAllow('tabs', 'access_control')"
        >Статистика: метки</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tabModel">
      <v-tab-item v-if="session.permission.isAllow('tabs', 'access_control')">
        <controllers-statistics />
      </v-tab-item>

      <v-tab-item>
        <category-statistics />
      </v-tab-item>

      <v-tab-item v-if="session.permission.isAllow('tabs', 'access_control')">
        <tags-statistics />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CategoryStatistics from "../components/home/CategoryStatistics";
import ControllersStatistics from "../components/home/ControllersStatistics";
import { session } from "../services/session.service";
import TagsStatistics from "../components/home/TagsStatistics";

export default {
  name: "Home",
  components: { TagsStatistics, ControllersStatistics, CategoryStatistics },

  data: () => ({
    session: session,
    tabModel: 0
  })
};
</script>
