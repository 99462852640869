<template>
  <v-dialog
    :value="!!value"
    @keydown.esc="close"
    @click:outside="close"
    max-width="640"
  >
    <v-card>
      <v-card-title>Изменение идентификатора</v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.key"
                label="Код идентификатора"
                autofocus
                disabled
                required
                :rules="rules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="model.type"
                :items="types"
                item-value="type"
                item-text="text"
                label="Тип идентификатора"
              />
            </v-col>
            <v-col cols="6">
              <v-switch label="Активный?" v-model="model.active" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-dialog
          v-model="confirmDelete"
          @keydown.esc="confirmDelete = false"
          max-width="480"
        >
          <template #activator="{on, attr}">
            <v-btn text color="red" v-on="on" v-bind="attr">
              <v-icon left>mdi-delete</v-icon> Удалить
            </v-btn>
          </template>

          <v-card>
            <v-card-title>Удалить идентификатор?</v-card-title>
            <v-card-text>
              Внимание, данное действие несет необратимый характер!
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="confirmDelete = false">Отмена</v-btn>
              <v-btn text color="red" @click="remove">
                Да, удалить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-btn text color="red" @click="close">Отмена</v-btn>
        <v-btn text color="primary" @click="update">Обновить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { map } from "lodash";
import { IDENTIFIERS } from "../../constants/identifiers.constants";
import { serialize } from "../../services/serialize.service";
import {
  deleteIdentifier,
  updateIdentifier
} from "../../services/user.service";

export default {
  name: "EditIdentifierDialog",

  props: {
    value: Boolean,
    selected: { type: Object, required: true }
  },

  data() {
    return {
      confirmDelete: false,
      valid: false,
      loading: false,

      model: { ...this.selected },

      rules: [
        v => !!v || "Это обязательное поле",
        v => (v && v.length > 3) || "Минимальное число символов: 4"
      ],

      types: map(IDENTIFIERS, (value, key) => ({
        type: Number(key),
        text: value.name
      }))
    };
  },

  methods: {
    close() {
      this.$emit("input", false);
    },

    async update() {
      await updateIdentifier(serialize(this.model));
      this.close();
      this.$emit("update");
    },

    async remove() {
      await deleteIdentifier(this.model.id);
      this.confirmDelete = false;
      this.close();
      this.$emit("update");
    }
  }
};
</script>
