<template>
  <v-form @submit.prevent="$listeners.submit" ref="form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="event.name"
          :rules="eventNameRules"
          label="Название мероприятия"
          autofocus
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="event.address" label="Адрес"></v-text-field>
      </v-col>
      <v-col>
        <date-picker
          v-model="event.beginDate"
          label="Начало"
          clearable
        ></date-picker>
      </v-col>
      <v-col>
        <date-picker
          v-model="event.endDate"
          label="Конец"
          clearable
        ></date-picker>
      </v-col>
    </v-row>

    <slot> </slot>
  </v-form>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";

export default {
  components: { DatePicker },

  props: {
    value: Object
  },

  computed: {
    event() {
      return this.value;
    },

    eventNameRules() {
      return [v => !!v?.length || "Название не должно быть пустым"];
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
