<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Ключи</v-tab>
      <v-tab>Журнал</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <keys-list />
      </v-tab-item>

      <v-tab-item>
        <keys-journal />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import KeysList from "@/components/keys/KeysList.vue";
import KeysJournal from "@/components/keys/KeysJournal.vue";

export default {
  components: { KeysList, KeysJournal },

  data() {
    return {
      tab: null
    };
  }
};
</script>
