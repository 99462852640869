<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :items-per-page="15"
    @toggle-select-all="toggleAll"
    @item-selected="toggle"
    show-select
    item-key="id"
    dense
  >
    <template v-if="disabled" #header.data-table-select="{ props }">
      <v-simple-checkbox
        :disabled="true"
        :ripple="false"
        :value="props.value"
        :indeterminate="props.indeterminate"
      ></v-simple-checkbox>
    </template>
    <template v-if="disabled" #item.data-table-select="{ isSelected }">
      <v-simple-checkbox
        :disabled="true"
        :ripple="false"
        :value="isSelected"
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    value: Array,
    keys: Array,
    disabled: Boolean
  },

  data() {
    return {
      headers: [
        { text: "Ключ", value: "name" },
        { text: "Идентификатор", value: "code" },
        { text: "Описание", value: "description" }
      ]
    };
  },

  computed: {
    items() {
      return this.keys;
    },

    selected: {
      get() {
        return this.value;
      },

      set(selected) {
        this.$emit("input", selected);
      }
    }
  },

  methods: {
    toggleAll({ value }) {
      this.$emit("toggle-all", value);
    },

    toggle({ item: key, value }) {
      this.$emit("toggle", { key, value });
    }
  }
};
</script>
