<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title>Правила доступа</v-card-title>

        <v-divider />

        <v-toolbar dense flat>
          <create-rule
            @create-rule="createRule"
            @update-rule="getUserAccessPoints"
          />
        </v-toolbar>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col>
              <h4 class="black--text">Антипассбэк</h4>

              <div class="d-flex align-center justify-space-between">
                <v-text-field
                  type="number"
                  label="Разрешать повторный проход через указанное кол-во минут"
                  v-model="passbackTimeout"
                ></v-text-field>
                <v-btn @click="update" class="primary--text ml-4" text
                  >Сохранить</v-btn
                >
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4 class="black--text">Доступ пользователя</h4>

              <access-rule-table
                :user-id="+$route.params.userId"
                :rules="accessRules"
                @update="getUserAccessPoints"
              />
            </v-col>

            <v-col>
              <h4>Доступ категории</h4>

              <access-rule-table
                class="v-card--disabled"
                :category-id="categoryId"
                :rules="userCategoriesRules"
                @update="getUserAccessPoints"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-if="!$route.path.includes('pass')">
      <v-card outlined>
        <v-card-title>Доступ к ключам</v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <h4>Доступ пользователя</h4>
          <keys-user-access
            v-model="keysAccessRules"
            :keys="keys"
            :disabled="false"
            @set-access="setKeysAccess"
          ></keys-user-access>

          <v-divider></v-divider>

          <h4>Доступ категории</h4>
          <keys-access
            :value="categoryKeys"
            :keys="categoryKeys"
            :disabled="true"
          ></keys-access>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { updateUser } from "@/services/user.service";
import {
  createUserAccessPoints,
  getUserAccessPoints,
  getUserCategories,
  updateUserAccessPoint
} from "../../../services/user.service";
import AccessRuleTable from "../../rules/AccessRuleTable";
import { sortBy, intersection, map, find } from "lodash";
import CreateRule from "../../rules/CreateRule";
import { getCategoryAccessRules } from "../../../services/category.service";
import KeysAccess from "../../keys/KeysAccess.vue";
import { withNotifications } from "@/utils/notifications.js";
import {
  getKeys,
  getKeyAccessRules,
  deleteKeyAccessRules,
  updateKeyAccessRules
} from "@/services/keys.service";
import KeysUserAccess from "../../keys/KeysUserAccess.vue";

export default {
  name: "AccessInformation",
  components: { CreateRule, AccessRuleTable, KeysAccess, KeysUserAccess },

  props: {
    user: { type: Object, required: true },
    categoryList: { type: Array, default: () => [] }
  },

  data() {
    return {
      accessRules: [],
      keysAccessRules: [],
      keys: [],

      userCategories: [],
      userCategoriesRules: [],
      categoryId: null
    };
  },

  computed: {
    passbackTimeout: {
      get() {
        return this.user.passbackTimeout / 60;
      },
      set(value = 0) {
        this.user.passbackTimeout = value * 60;
      }
    },

    categoryKeys() {
      return this.keys.filter(
        k =>
          intersection(
            map(this.userCategories, "category.id"),
            map(k.categories, "id")
          ).length > 0
      );
    }
  },

  created() {
    this.getUserAccessPoints();
    this.getUserCategories();

    this.getKeyAccessRules();
    this.getKeys();
  },

  methods: {
    getKeys() {
      withNotifications(async () => {
        this.keys = await getKeys();
      });
    },

    getKeyAccessRules() {
      withNotifications(async () => {
        this.keysAccessRules = await getKeyAccessRules(this.user.id);
      });
    },

    async setKeysAccess(keyIds, accessType) {
      let keyAccessRules = keyIds.map(keyId => ({
        keyId,
        userId: this.user.id,
        accessType
      }));

      await withNotifications(async () => {
        if (accessType === null) {
          await this.deleteKeyAccessRules(keyAccessRules);
        } else {
          await this.updateKeyAccessRules(keyAccessRules);
        }
      });
    },

    async updateKeyAccessRules(keyAccessRules) {
      await updateKeyAccessRules(keyAccessRules);
      keyAccessRules.forEach(rule => {
        let found = find(this.keysAccessRules, { keyId: rule.keyId });
        found
          ? (found.accessType = rule.accessType)
          : this.keysAccessRules.push(rule);
      });
    },

    async deleteKeyAccessRules(keyAccessRules) {
      await deleteKeyAccessRules(keyAccessRules);
      this.keysAccessRules = this.keysAccessRules.filter(
        rule => !find(keyAccessRules, { keyId: rule.keyId })
      );
    },

    async update() {
      await updateUser(this.user);
    },

    async updateRule(event, rule) {
      const { userId } = this.$route.params;

      try {
        await updateUserAccessPoint(userId, {
          id: rule.id,
          name: rule.name,
          type: Number(event)
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getUserAccessPoints() {
      try {
        const rules = await getUserAccessPoints(this.user.id);
        this.accessRules = sortBy(rules, "id");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getUserCategories() {
      try {
        this.userCategories = await getUserCategories(this.user.id);

        this.categoryId = this.userCategories.map(
          category => category.category.id
        )[0];

        this.userCategoriesRules = await getCategoryAccessRules(
          this.categoryId
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async createRule(params) {
      try {
        await createUserAccessPoints(this.user.id, params);
        await this.getUserAccessPoints();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
