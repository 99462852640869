<script>
import { formatDateTime } from "@/utils/date-format";

export default {
  props: {
    value: {
      type: [Object, Boolean],
      default: () => null
    }
  },

  data() {
    return {
      invitesHeaders: [
        {
          text: "Создано",
          align: "start",
          sortable: false,
          value: "createdAt"
        },
        {
          text: "Отправлено",
          align: "start",
          sortable: false,
          value: "sentAt"
        },
        {
          text: "Прочитано",
          align: "start",
          sortable: false,
          value: "readAt"
        },
        {
          text: "Попыток оправки",
          align: "start",
          sortable: false,
          value: "sendTryCount"
        }
      ]
    };
  },

  computed: {
    request() {
      return this.value;
    },

    dialog: {
      get() {
        return !!this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },

  methods: {
    onClose() {
      this.dialog = null;
    },

    formatUser(user) {
      return ["surname", "name", "patronymic"]
        .map(el => user[el])
        .filter(Boolean)
        .join(" ");
    },

    formatDateTime
  }
};
</script>

<template>
  <v-dialog v-model="dialog" width="800">
    <v-card v-if="dialog">
      <v-card-title class="text-h5">
        Заявка на участие
      </v-card-title>

      <v-card-text>
        <div class="subtitle-1">Участник</div>
        {{ formatUser(request.user) }}, {{ formatDateTime(request.date) }}
      </v-card-text>

      <v-card-text>
        <div class="subtitle-1">Приглашения</div>
        <v-data-table
          :headers="invitesHeaders"
          :items="request.invites"
          hide-default-footer
          style="border: 1px solid lightgray"
          dense
        >
          <template #item.createdAt="{ item }">
            <td>
              <span v-if="item.createdAt">
                {{ formatDateTime(item.createdAt) }}
              </span>
              <span v-else>-</span>
            </td>
          </template>
          <template #item.sentAt="{ item }">
            <td>
              <span v-if="item.sentAt">
                {{ formatDateTime(item.sentAt) }}
              </span>
              <span v-else>-</span>
            </td>
          </template>
          <template #item.readAt="{ item }">
            <td>
              <span v-if="item.readAt">
                {{ formatDateTime(item.readAt) }}
              </span>
              <span v-else>-</span>
            </td>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onClose">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
