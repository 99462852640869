<template>
  <v-card outlined>
    <v-toolbar dense flat class="py-5 mb-4">
      <v-select
        v-model="statisticsSelectModel"
        :items="statisticsItems"
        item-value="value"
        item-text="text"
        label="Период"
      />
    </v-toolbar>

    <controller-toolbar
      @update="getParams"
      :params="params"
      :selected-type="statisticsSelectModel"
    />

    <v-card-text>
      <v-row>
        <v-col>
          <div id="chart" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getControllersStatistics,
  getControllersStatisticsMonth
} from "@/services/device.service";
import ApexCharts from "apexcharts";
import ControllerToolbar from "@/components/devices/ControllerToolbar";

export default {
  name: "ControllerStatistics",
  components: { ControllerToolbar },
  data() {
    return {
      chart: null,
      statisticsSelectModel: "hour",

      params: {
        date: new Date().toISOString().substring(0, 10),
        cId: null
      },

      statisticsItems: [
        { value: "month", text: "По дням" },
        { value: "hour", text: "По часам" }
      ],

      showMonth: false,

      chartOptions: {
        chart: {
          width: "100%",
          height: 450,
          type: "bar",
          stacked: true
        },
        plotOptions: {
          bar: {
            vertical: true
          }
        },
        dataLabels: {
          enabled: false
        },
        series: [],
        title: {
          text: ""
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60
          }
        },
        noData: {
          text: "Нет данных"
        }
      }
    };
  },

  created() {
    this.getControllersHourStatistics();
  },

  mounted() {
    this.chart = new ApexCharts(
      document.querySelector("#chart"),
      this.chartOptions
    );
    this.chart.render();
  },

  beforeDestroy() {
    this.chart && this.chart.destroy();
  },

  methods: {
    getParams(params) {
      this.params = params;

      this.updateChart();
    },

    updateChart() {
      this.statisticsSelectModel === "hour"
        ? this.getControllersHourStatistics()
        : this.getControllersMonthStatistics();
    },

    async getControllersHourStatistics() {
      this.showMonth = false;
      try {
        await getControllersStatistics(this.params).then(response => {
          if (
            this.statisticsSelectModel === "hour" ||
            this.statisticsSelectModel === null
          ) {
            this.updateSeriesIdentifierTypes(response);
            this.updateOptionsHourChart(response);
          }
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message.message : e,
          type: "error"
        });
      }
    },

    async getControllersMonthStatistics() {
      this.showMonth = true;
      try {
        await getControllersStatisticsMonth({
          date: this.params.date + "-01",
          cId: this.params.cId
        }).then(response => {
          this.updateSeriesIdentifierTypes(response);
          this.updateOptionsMonthChart(response);
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    updateSeriesIdentifierTypes(response) {
      this.chart.updateSeries([
        {
          name: "QR",
          data: response.map(item => item.qr)
        },
        {
          name: "Карта",
          data: response.map(item => item.card)
        },
        {
          name: "Пульт",
          data: response.map(item => item.console)
        },
        {
          name: "Отклонен",
          data: response.map(item => item.reject)
        }
      ]);
    },

    updateOptionsHourChart(response) {
      this.chart.updateOptions({
        xaxis: {
          categories: response.map(items => {
            return new Date(new Date().setHours(items.index))
              .toLocaleTimeString("ru-RU")
              .substring(0, 2);
          })
        }
      });
    },

    updateOptionsMonthChart(response) {
      this.chart.updateOptions({
        xaxis: {
          categories: response.map(items => {
            return items.index;
          })
        }
      });
    }
  }
};
</script>
