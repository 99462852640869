<template>
  <div>
    <v-card outlined>
      <v-card flat>
        <v-card-title>
          Список идентификаторов
        </v-card-title>

        <v-divider />
        <v-toolbar dense flat>
          <create-identificator-dialog @update="$emit('update')" />
          <create-identificator-request-dialog @update="$emit('update')" />

          <v-spacer />

          <v-text-field
            style="max-width: 320px"
            v-model="idSearch"
            hide-details
            single-line
            prepend-icon="mdi-magnify"
            placeholder="Поиск..."
          />
        </v-toolbar>
        <v-divider />

        <edit-identifier-dialog
          v-if="edit"
          v-model="edit"
          :selected="selectedIdentifier"
          @update="$emit('update')"
        />

        <v-data-table
          dense
          :search="idSearch"
          :headers="idHeaders"
          :items="identifiers"
          sort-by="updateDate"
          :sort-desc="true"
          @click:row="editIdentifier"
        >
          <template #item.type="{item}">
            <div>
              <v-icon :color="getIdentifierColor(item.type)">
                {{ getIdentifierIcon(item.type) }}
              </v-icon>
              {{ getIdentifierName(item.type) }}
            </div>
          </template>

          <template #item.active="{item}">
            <v-simple-checkbox
              :id="item.id + '-active'"
              :value="item.active"
              disabled
            />
          </template>

          <template #item.createDate="{item}">
            {{ new Date(item.createDate).toLocaleString("ru-RU") }}
          </template>

          <template #item.updateDate="{item}">
            {{ new Date(item.updateDate).toLocaleString("ru-RU") }}
          </template>
        </v-data-table>
      </v-card>
    </v-card>

    <v-card outlined class="my-4">
      <v-card flat>
        <v-card-title>Операции с идентификаторами</v-card-title>

        <v-divider />

        <v-toolbar dense flat>
          <v-spacer />

          <v-text-field
            style="max-width: 320px"
            hide-details
            v-model="idDesc"
            single-line
            prepend-icon="mdi-magnify"
            placeholder="Поиск..."
          />
        </v-toolbar>
        <v-divider />

        <v-data-table
          dense
          :search="idDesc"
          :headers="opHeaders"
          :items="operations"
          sort-by="date"
          :sort-desc="true"
        >
          <template #item.description="{item}">
            {{ upperFirst(item.description) }}
          </template>

          <template #item.type="{item}">
            <operation-type :type="item.type" />
          </template>

          <template #item.date="{item}">
            {{ new Date(item.date).toLocaleString("ru-RU") }}
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { upperFirst } from "lodash";

import {
  getIdentifierColor,
  getIdentifierName,
  getIdentifierIcon
} from "../../../constants/identifiers.constants";

import CreateIdentificatorDialog from "../CreateIdentificatorDialog";
import EditIdentifierDialog from "../EditIdentifierDialog";
import OperationType from "../OperationType";
import CreateIdentificatorRequestDialog from "../CreateIdentificatorRequestDialog.vue";

export default {
  name: "IdentifiersInformation",
  components: {
    OperationType,
    EditIdentifierDialog,
    CreateIdentificatorDialog,
    CreateIdentificatorRequestDialog
  },

  props: {
    identifiers: { type: Array, default: () => [] },
    operations: { type: Array, default: () => [] }
  },

  data: () => ({
    idSearch: "",
    idDesc: "",
    idHeaders: [
      {
        text: "#",
        align: "start",
        sortable: true,
        value: "id"
      },

      {
        text: "Идентификатор",
        align: "start",
        sortable: true,
        value: "key"
      },

      {
        text: "Тип идентификатора",
        align: "start",
        sortable: true,
        value: "type"
      },

      {
        text: "Активен?",
        align: "start",
        sortable: true,
        value: "active"
      },

      {
        text: "Создан",
        align: "start",
        sortable: true,
        value: "createDate"
      },

      {
        text: "Обновлен",
        align: "start",
        sortable: true,
        value: "updateDate"
      }
    ],

    opHeaders: [
      {
        text: "#",
        align: "start",
        sortable: true,
        value: "id"
      },
      {
        text: "Идентификатор",
        align: "start",
        sortable: true,
        value: "key"
      },
      {
        text: "Описание",
        align: "start",
        sortable: true,
        value: "description"
      },
      {
        text: "Тип операции",
        align: "start",
        sortable: true,
        value: "type"
      },
      {
        text: "Дата",
        align: "start",
        sortable: true,
        value: "date"
      }
    ],

    edit: false,

    selectedIdentifier: {
      id: null,
      type: 0,
      key: "",
      active: false
    }
  }),

  methods: {
    getIdentifierColor,
    getIdentifierIcon,
    getIdentifierName,
    upperFirst,

    editIdentifier(item) {
      this.edit = true;
      this.selectedIdentifier = item;
    }
  }
};
</script>
