var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('controller-toolbar',{attrs:{"params":_vm.params,"clearable-date":true},on:{"update":_vm.getParams}}),_c('v-row',{staticClass:"px-5"},[_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.identifiers,"item-value":"value","item-text":"text","clearable":"","multiple":"","label":"Тип идентификатора"},model:{value:(_vm.params.identifierTypes),callback:function ($$v) {_vm.$set(_vm.params, "identifierTypes", $$v)},expression:"params.identifierTypes"}})],1)],1),_c('v-spacer'),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","show-expand":"","headers":_vm.headers,"items":_vm.events.content,"options":_vm.eventsOptions,"server-items-length":_vm.events && _vm.events.totalElements,"footer-props":{ itemsPerPageOptions: [100, 250, 500] },"item-key":"id"},on:{"update:options":function($event){_vm.eventsOptions=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":_vm.getTypesColor(item.type)}},[_vm._v(" "+_vm._s(_vm.getTypesIcon(item.type))+" ")])],1)]}},{key:"item.fio",fn:function(ref){
var item = ref.item;
return [(item.userId)?_c('td',[_c('a',{on:{"click":function($event){return _vm.goToUserEdit(item)}}},[_vm._v(" "+_vm._s(((item.name) + " " + (item.surname)))+" ")])]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localeDateString(item))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.userId)?_c('td',{attrs:{"colspan":headers.length}},[_c('events-info',{attrs:{"event":item}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }