<template>
  <v-card outlined>
    <controller-toolbar
      :params="params"
      @update="getParams"
      :clearable-date="true"
    />

    <v-row class="px-5">
      <v-col>
        <v-combobox
          v-model="params.identifierTypes"
          :items="identifiers"
          item-value="value"
          item-text="text"
          clearable
          multiple
          label="Тип идентификатора"
        />
      </v-col>
    </v-row>

    <v-spacer />

    <v-card-text>
      <v-data-table
        dense
        show-expand
        :headers="headers"
        :items="events.content"
        :options.sync="eventsOptions"
        :server-items-length="events && events.totalElements"
        :footer-props="{ itemsPerPageOptions: [100, 250, 500] }"
        item-key="id"
      >
        <template #item.type="{item}">
          <div>
            <v-icon :color="getTypesColor(item.type)">
              {{ getTypesIcon(item.type) }}
            </v-icon>
          </div>
        </template>

        <template #item.fio="{item}">
          <td v-if="item.userId">
            <a @click="goToUserEdit(item)">
              {{ `${item.name} ${item.surname}` }}
            </a>
          </td>
        </template>

        <template #item.date="{item}">
          {{ localeDateString(item) }}
        </template>

        <template #expanded-item="{headers, item}">
          <td :colspan="headers.length" v-if="item.userId">
            <events-info :event="item" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getControllersEvents } from "@/services/device.service";
import {
  getTypesColor,
  getTypesIcon,
  getTypesName
} from "@/constants/events-type.constants";
import ControllerToolbar from "@/components/devices/ControllerToolbar";
import {
  PPS_EDIT_ROUTE,
  STAFF_EDIT_ROUTE,
  USERS_EDIT_ROUTE
} from "../../../router/names";
import { getUserById } from "../../../services/user.service";
import EventsInfo from "./EventsInfo";

export default {
  name: "ControllerEvents",
  components: { EventsInfo, ControllerToolbar },
  data() {
    return {
      events: [],
      menu: false,
      user: {},

      headers: [
        { text: "Тип", value: "type" },
        { text: "ФИО", value: "fio" },
        { text: "Дата", value: "date" },
        { text: "Контроллер", value: "controllerName" },
        { text: "Событие", value: "eventName" }
      ],
      identifiers: [
        { text: "Карта", value: "card" },
        { text: "QR-код", value: "qr" },
        { text: "Пульт", value: "console" },
        { text: "Отклонен", value: "reject" }
      ],
      group: "",
      eventsOptions: {},
      params: {
        date: new Date().toISOString().substring(0, 10),
        cId: null,
        identifierTypes: []
      }
    };
  },

  watch: {
    eventsOptions: {
      handler() {
        this.getPagination();
        this.getAcsEvents();
      }
    }
  },

  methods: {
    getTypesName,
    getTypesColor,
    getTypesIcon,

    async getAcsEvents() {
      try {
        this.events = await getControllersEvents({
          ...this.params,
          identifierTypes: this.params.identifierTypes
            .map(identifier => identifier.value)
            .toString()
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    getParams(params) {
      this.getPagination();
      this.params = params;

      this.getAcsEvents();
    },

    localeDateString(item) {
      return new Date(item.date).toLocaleString("ru-RU");
    },

    getPagination() {
      const { page, itemsPerPage } = this.eventsOptions;
      this.params.page = page - 1;
      this.params.size = itemsPerPage;
    },

    async getUser(event) {
      try {
        this.user = await getUserById(event.userId);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async goToUserEdit(event) {
      await this.getUser(event);
      const group = this.user.mainGroup.name.toLowerCase();

      await this.$router.push({
        name:
          group === "entrant"
            ? USERS_EDIT_ROUTE
            : group === "staff"
            ? STAFF_EDIT_ROUTE
            : PPS_EDIT_ROUTE,
        params: {
          group,
          userId: event.userId
        }
      });
    }
  }
};
</script>
