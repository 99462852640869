<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <create-speciality-dialog @update="loadSpecialties" />
      <v-switch
        class="ml-4"
        dense
        hide-details
        v-model="params.archival"
        label="В архиве"
      />

      <v-spacer />

      <v-text-field
        style="max-width: 320px"
        hide-details
        v-model="search"
        single-line
        prepend-icon="mdi-magnify"
        placeholder="Поиск..."
      />

      <v-btn icon @click="loadSpecialties"><v-icon>mdi-reload</v-icon></v-btn>
    </v-toolbar>

    <v-divider />
    <v-toolbar dense flat>
      <v-select
        class="mr-4"
        style="max-width: 300px"
        v-model="params.categoryId"
        :items="categories"
        dense
        hide-details
        clearable
        item-value="id"
        item-text="name"
        placeholder="Факультет"
      />
    </v-toolbar>
    <v-divider />

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="specialties"
        :search="search"
        sort-by="direction"
        multi-sort
        :options.sync="specialityOptions"
        :footer-props="{ itemsPerPageOptions: [15, 25, 50] }"
        @click:row="editSpeciality"
      >
        <template #item.category="{ item }">
          <td>{{ item.category.name }}</td>
        </template>

        <template #item.duration="{ item }">
          <td>{{ formatDurationToString(item.duration) }}</td>
        </template>

        <template #item.actions="{ item }">
          <td>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon @click.stop="toggleArchival(item)">
                  <v-icon
                    v-if="item.archival"
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-archive-arrow-up</v-icon
                  >
                  <v-icon v-else color="error" v-bind="attrs" v-on="on"
                    >mdi-archive-arrow-down</v-icon
                  >
                </v-btn>
              </template>
              <span v-if="!item.archival">Архивировать</span>
              <span v-else>Разархивировать</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CreateSpecialityDialog from "./CreateSpecialityDialog";
import {
  getSpecialityList,
  updateSpeciality,
  getCategoryByType
} from "../../services/category.service";
import { formatDurationToString } from "@/utils/date-format";
export default {
  name: "ListTableSpeciality",
  components: { CreateSpecialityDialog },

  data: () => ({
    search: "",

    edit: false,

    specialties: [],
    headers: [
      {
        text: "Код",
        value: "code"
      },
      {
        text: "Направление",
        value: "direction"
      },
      {
        text: "Пофиль",
        value: "profile"
      },
      {
        text: "Факультет",
        value: "category"
      },
      {
        text: "Квалификация",
        value: "qualification"
      },
      {
        text: "Срок",
        value: "duration"
      },
      {
        text: "Действия",
        value: "actions"
      }
    ],
    params: {},
    categories: [],

    accessRules: [],
    selectedCategory: {},
    specialityOptions: {}
  }),

  watch: {
    specialityOptions: {
      handler() {
        this.loadSpecialties();
      },
      immediate: true
    },

    "params.archival": {
      async handler(v) {
        await this.$router.replace({
          query: { ...this.$route.query, archival: v }
        });
        await this.loadSpecialties();
      }
    },

    "params.categoryId": {
      async handler(v) {
        await this.$router.replace({
          query: { ...this.$route.query, categoryId: v }
        });
        await this.loadSpecialties();
      }
    }
  },

  async mounted() {
    this.categories = await this.getCategoryByType("ENTRANT");
  },

  methods: {
    formatDurationToString,
    getCategoryByType,

    async loadSpecialties() {
      try {
        this.specialties = await getSpecialityList(this.params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async editSpeciality(item) {
      await this.$router.push({
        name: "INFO_SPECIALITY_EDIT_ROUTE",
        params: { speciality: item }
      });
    },

    async toggleArchival(item) {
      item.archival = !item.archival;
      await updateSpeciality(item.id, item);
      this.loadSpecialties();
    }
  }
};
</script>
