<template>
  <div>
    <div class="row">
      <p class="col-2 pb-0 pt-0">Первая регистрация:</p>

      <v-combobox
        v-model="firstDirection"
        :items="directions"
        item-text="text"
        item-value="value"
        dense
        class="col"
      />

      <v-text-field
        v-model="firstTime"
        type="time"
        label="Время"
        dense
        prepend-icon="mdi-hours-24"
        class="col"
      />
    </div>

    <div class="row">
      <p class="col-2 pb-0 pt-0">Последняя регистрация:</p>

      <v-combobox
        v-model="lastDirection"
        :items="directions"
        item-text="text"
        item-value="value"
        dense
        class="col"
      />

      <v-text-field
        v-model="lastTime"
        type="time"
        label="Время"
        dense
        prepend-icon="mdi-hours-24"
        class="col"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PeriodTime",

  data: () => ({
    directions: [
      { text: "До", value: "before" },
      { text: "После", value: "after" }
    ],

    time: {
      firstDirection: "",
      fh: "00",
      fm: "00",
      lastDirection: "",
      lh: "23",
      lm: "59"
    }
  }),

  created() {
    this.$emit("get-time", {
      ...this.time,
      firstDirection: this.directions.map(direction => direction.value)[1],
      lastDirection: this.directions.map(direction => direction.value)[0]
    });
  },

  computed: {
    firstTime: {
      get() {
        return `${this.time.fh}:${this.time.fm}`;
      },

      set(newTime) {
        this.time.fh = newTime.substring(0, 2);
        this.time.fm = newTime.substring(3, 5);
      }
    },

    lastTime: {
      get() {
        return `${this.time.lh}:${this.time.lm}`;
      },

      set(newTime) {
        this.time.lh = newTime.substring(0, 2);
        this.time.lm = newTime.substring(3, 5);
      }
    },

    firstDirection: {
      get() {
        return this.directions.map(direction => direction.text)[1];
      },

      set(newDirection) {
        this.time.firstDirection = newDirection.value;
      }
    },

    lastDirection: {
      get() {
        return this.directions.map(direction => direction.text)[0];
      },

      set(newDirection) {
        this.time.lastDirection = newDirection.value;
      }
    }
  },

  watch: {
    time: {
      handler(newTime) {
        this.$emit("get-time", newTime);
      },
      deep: true
    }
  }
};
</script>
