<template>
  <v-card outlined v-if="session.permission.isAllow('tabs', 'access_control')">
    <v-card flat>
      <v-card-title>Модерация</v-card-title>

      <v-divider />

      <v-card-text>
        <moderate-form
          v-model="access"
          :category-list="categoryList"
          :user-management="userManagement"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          color="primary"
          @click="updateAccess"
          :disabled="!access.canModerate && !access.canGivePass"
        >
          Обновить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import ModerateForm from "./AccessInformation/ModerateForm";
import {
  updateUserGroup,
  updateUserManagement
} from "../../services/user.service";
import { session } from "../../services/session.service";
export default {
  name: "ModerateInformation",
  components: { ModerateForm },
  props: {
    userManagement: { type: Array, default: () => [], required: true },
    categoryList: { type: Array, default: () => [], required: true },
    user: { type: Object, required: true }
  },

  data() {
    return {
      session: session,
      access: {
        canModerate: this.user.groups.includes("MANAGER"),
        canGivePass: this.user.groups.includes("PASS_MANAGER"),
        selected: this.userManagement.map(c => ({ ...c.category }))
      }
    };
  },

  watch: {
    "access.canModerate": {
      async handler() {
        try {
          await updateUserGroup(this.user.id, "MANAGER");
          if (!this.access.canModerate && !this.access.canGivePass) {
            await updateUserManagement(this.user.id, []);
            this.access.selected = [];
          }
        } catch (e) {
          this.$notify({
            text: e.response ? e.response.data.message : e,
            type: "error"
          });
        }
      }
    },

    "access.canGivePass": {
      async handler() {
        try {
          await updateUserGroup(this.user.id, "PASS_MANAGER");
          if (!this.access.canModerate && !this.access.canGivePass) {
            await updateUserManagement(this.user.id, []);
            this.access.selected = [];
          }
        } catch (e) {
          this.$notify({
            text: e.response ? e.response.data.message : e,
            type: "error"
          });
        }
      }
    },

    access: {
      handler() {
        this.$emit("update", this.access);
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    async updateAccess() {
      if (!this.access.canModerate && this.access.selected.length === 0) return;

      try {
        await updateUserManagement(
          this.user.id,
          this.access.selected.map(category => ({ category }))
        ).then(() =>
          this.$notify({
            text: "Данные успешно обновлены",
            type: "success"
          })
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
