import { findUser } from "./user.service";
import { downloadXLS } from "./excel.service";

const userGroup = {
  pps: "ППС",
  entrant: "Студенты",
  staff: "Сотрудники",
  manager: "Модератор"
};

function formatNameField({ surname, name, patronymic } = {}, group) {
  return `${group || ""} ${surname || ""} ${name || ""} ${patronymic ||
    ""}`.trim();
}

function formatNameFile(userGroup, category, course, groupName) {
  const name = `${userGroup || ""} ${category || ""} ${course ||
    ""}${groupName || ""}`.trim();
  return `${name} пароли.xlsx`;
}

function formatGroup(groups) {
  return groups.map(g => userGroup[g.toLowerCase()]).join(", ");
}

function checkPwd(item) {
  if (item.defaultPassword) return item.password;
  else return "*****";
}

function formatRows(data, group) {
  let rows = [
    [
      {
        value: "№",
        header: true,
        width: 5
      },
      {
        value: "ФИО",
        header: true,
        width: 35
      },
      {
        value: "Логин",
        header: true,
        width: 10
      },
      {
        value: "Пароль",
        header: true,
        width: 10
      },
      {
        value: "Группы пользователя",
        header: true,
        width: 25
      },
      {
        value: "Подразделение/Факультет",
        header: true,
        width: 25
      }
    ]
  ];

  if (group === "entrant") {
    rows[0].push(
      {
        value: "Курс/Класс",
        header: true,
        width: 10
      },
      {
        value: "Группа/Литер",
        header: true,
        width: 15
      }
    );
  }

  for (let i = 0; i < data.length; i++) {
    rows.push([
      {
        value: i + 1
      },
      {
        value: formatNameField(data[i])
      },
      {
        value: data[i].login
      },
      {
        value: checkPwd(data[i])
      },
      {
        value: formatGroup(data[i].groups)
      },
      {
        value: data[i].description.match(/\D+/)[0].trim()
      }
    ]);
    if (data[i].groups.find(g => g === "ENTRANT")) {
      rows[i + 1].push(
        { value: data[i].pupilGroup.course },
        { value: data[i].pupilGroup.liter }
      );
    }
  }

  return rows;
}

async function downloadData(
  pagination,
  tableOptions,
  totalElements,
  search,
  group,
  categories,
  listGroups
) {
  const { sortBy, sortDesc } = pagination;
  const {
    categoryId,
    pupilCourse,
    pupilGroupId,
    validated,
    archival
  } = tableOptions;

  let listUsers = await findUser({
    page: 0,
    size: totalElements,
    sortBy: sortBy.join(","),
    descending: sortDesc,
    archival,
    categoryId,
    pupilCourse,
    pupilGroupId,
    search: search,
    havePupilGroup: true,
    userGroup: (group !== "null" && group.toUpperCase()) || group,
    validated
  });

  await downloadXLS(
    formatRows(listUsers.content, group),
    formatNameFile(
      userGroup[group],
      categories.find(c => c.id === tableOptions.categoryId)?.name,
      tableOptions.pupilCourse,
      listGroups.find(g => g.id === tableOptions.pupilGroupId)?.liter
    )
  );
}

export { downloadData };
