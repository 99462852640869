import { Api } from "./api.service";

const SESSION_STORAGE_KEY = "campus_jwt";

let interval;

function jwtEnabled() {
  return process.env.VUE_APP_JWT === "true";
}

function jwtLogin(login, password) {
  return Api.post("/auth/jwt/login", { login, password });
}

function jwtRefresh() {
  return Api.put("/auth/jwt/refresh");
}

function jwtLogout() {
  return Api.delete("/auth/jwt/logout");
}

async function login(login, password) {
  const data = await jwtLogin(login, password);
  sessionStorage.setItem(SESSION_STORAGE_KEY, data.jwt);
}

async function refresh() {
  const data = await jwtRefresh();
  sessionStorage.setItem(SESSION_STORAGE_KEY, data.jwt);
}

async function check(ms) {
  const str = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const token = parseJwt(str);
  const diff = (token?.exp || 0) * 1000 - Date.now();
  diff < ms && (await refresh());
}

async function checkEach(ms) {
  clearInterval(interval);
  await check(ms);
  interval = setInterval(() => check(ms), ms);
  return interval;
}

function logout() {
  return jwtLogout();
}

function parseJwt(str) {
  try {
    return JSON.parse(atob(str.split(".")[1]));
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { SESSION_STORAGE_KEY, login, refresh, checkEach, logout, jwtEnabled };
