<template>
  <div>
    <v-row class="my-4">
      <v-col class="col-6">
        <v-text-field v-model="controller.port" label="Порт" readonly dense />

        <v-text-field
          v-model="modificationDate"
          label="Дата последнего изменения"
          readonly
          dense
          class="py-1"
        />

        <v-text-field
          v-model="controller.accessPoint.name"
          label="Точка доступа"
          readonly
          dense
        />

        <v-text-field
          v-model="controller.typeName"
          label="Тип"
          readonly
          dense
        />
      </v-col>

      <v-col>
        <v-simple-table dense>
          <thead>
            <tr>
              <th>Название</th>
              <th>Активный</th>
              <th>Архивный</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="gateway in controller.gateways" :key="gateway.id">
              <td>{{ gateway.name }}</td>
              <td>
                <v-checkbox v-model="gateway.active" readonly class="ma-0" />
              </td>
              <td>
                <v-checkbox v-model="gateway.archival" readonly class="ma-0" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ControllerInfo",
  props: {
    controller: { required: true }
  },

  computed: {
    modificationDate() {
      if (this.controller.modification === null) {
        return null;
      } else {
        return new Date(this.controller.modification).toLocaleString("ru-Ru");
      }
    }
  },

  created() {
    this.controller.gateways.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
  }
};
</script>
