<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <controller-dialog @update="getControllers" />
      <v-switch
        class="ml-4"
        dense
        hide-details
        label="В архиве"
        v-model="isArchival"
      />
    </v-toolbar>

    <v-divider />

    <v-card-text>
      <list-table
        :controllers="controllers"
        @archival="sendToArchive"
        @get-controllers="getControllers"
      />

      <template v-if="isArchival">
        <v-divider />

        <p class="text-caption my-4 pl-4">Архив</p>
        <list-table
          :controllers="archivalControllers"
          @archival="returnFromArchive"
          @get-controllers="getControllersArchival"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getControllers,
  getControllersArchival,
  sendToArchive,
  updateController
} from "@/services/device.service";
import ControllerDialog from "@/components/devices/DeviceInformation/ControllerDialog";
import ListTable from "@/components/devices/DeviceInformation/ListTable";

export default {
  name: "ControllerOperations",
  components: { ListTable, ControllerDialog },

  data() {
    return {
      controllers: [],
      archivalControllers: [],

      isArchival: false
    };
  },

  watch: {
    isArchival: {
      handler(newVal) {
        return newVal ? this.getControllersArchival() : this.getControllers();
      }
    }
  },

  mounted() {
    this.getControllers();
  },

  methods: {
    async getControllers() {
      try {
        this.controllers = await getControllers();

        this.controllers.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getControllersArchival() {
      try {
        this.archivalControllers = await getControllersArchival();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async sendToArchive(controller) {
      try {
        await sendToArchive(controller.id);

        await this.updateControllersView();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async returnFromArchive(controller) {
      try {
        await updateController({
          id: controller.id,
          archival: "false",
          accessPoint: controller.accessPoint,
          token: controller.token
        });

        await this.updateControllersView();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateControllersView() {
      await this.getControllers();
      await this.getControllersArchival();
    }
  }
};
</script>
