<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Основная информация</v-tab>
      <v-tab>Управление доступом</v-tab>
      <v-tab>Учебные группы</v-tab>
      <v-tab>Модераторы</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-4">
      <v-tab-item>
        <edit-category :category="category" />
      </v-tab-item>

      <v-tab-item>
        <category-rules :category="category" />
      </v-tab-item>

      <v-tab-item>
        <pupil-group :category="category" />
      </v-tab-item>

      <v-tab-item>
        <managers-list :category="category" />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import Page from "../../components/Page";
import EditCategory from "../../components/group-management/EditCategory";
import CategoryRules from "../../components/group-management/CategoryRules";
import ManagersList from "../user-management/ManagersList.vue";
import PupilGroup from "../../components/pupil-group/PupilGroupTable.vue";
export default {
  name: "InfoGroup",
  components: { PupilGroup, CategoryRules, EditCategory, Page, ManagersList },
  data: () => ({
    category: {},

    tab: null
  }),

  created() {
    this.category = this.$route.params.category;

    this.tab = Number(this.$route.query.tab) || 0;
  },

  watch: {
    tab() {
      this.$router.replace({ query: { tab: this.tab } });
    }
  }
};
</script>
