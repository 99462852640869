<template>
  <u-notifications>
    <template v-slot="{ notification }">
      <v-alert :type="notification.type" dense prominent>
        {{ notification.text }}
      </v-alert>
    </template>
  </u-notifications>
</template>

<script>
export default {
  name: "NotificationCenter"
};
</script>
