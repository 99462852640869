<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-dialog v-model="editor" max-width="800">
        <template #activator="{ on, attr }">
          <v-btn text color="primary" v-bind="attr" v-on="on">
            <v-icon left>mdi-plus</v-icon>
            <span>Создать</span>
          </v-btn>
        </template>
        <pupil-group-editor
          v-model="item"
          :specialties="specialties"
          @close="close"
          @save="save"
        />
      </v-dialog>

      <v-switch
        class="ml-4"
        dense
        hide-details
        v-model="params.archival"
        label="В архиве"
      />

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-card-text>
      <v-data-table
        sort-by="course"
        multi-sort
        :headers="headers"
        :items="groups"
        :footer-props="{ itemsPerPageOptions: [10, 15, 25] }"
        @click:row="open"
      >
        <template #item.actions="{ item }" class="align-right">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon @click.stop="toggleArchival(item)">
                <v-icon
                  v-if="item.archival"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-archive-arrow-up</v-icon
                >
                <v-icon v-else color="error" v-bind="attrs" v-on="on"
                  >mdi-archive-arrow-down</v-icon
                >
              </v-btn>
            </template>
            <span v-if="!item.archival">Архивировать</span>
            <span v-else>Разархивировать</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import PupilGroupEditor from "./PupilGroupEditor.vue";
import {
  getPupilGroupList,
  createPupilGroup,
  updatePupilGroup,
  getSpecialityList
} from "../../services/category.service";
import { withNotifications } from "@/utils/notifications";

export default {
  name: "PupilGroupTable",
  components: { PupilGroupEditor },

  props: {
    category: Object
  },

  data: () => ({
    groups: [],
    item: {},
    editor: false,
    headers: [
      {
        text: "Курс",
        value: "course"
      },
      {
        text: "Литер",
        value: "liter"
      },
      {
        text: "Действия",
        value: "actions",
        align: "right"
      }
    ],
    specialties: [],

    params: {}
  }),

  watch: {
    "params.archival": {
      async handler(v) {
        await this.$router.replace({
          query: { ...this.$route.query, archival: v }
        });
        await this.loadGroups();
      }
    },
    editor(opened) {
      if (!opened) this.item = {};
    }
  },

  async created() {
    this.params.categoryId = this.category.id;
    this.loadGroups();
    this.specialties = await this.getSpecialityList({
      categoryId: this.category.id
    });
  },

  methods: {
    getSpecialityList,

    async loadGroups() {
      try {
        this.groups = await getPupilGroupList(this.params);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async toggleArchival(item) {
      try {
        item.archival = !item.archival;

        await updatePupilGroup(item.id, item).then(() =>
          this.$notify({
            text: item.archival
              ? `Группа ${item.course}${item.liter} отправлена в архив`
              : `Группа ${item.course}${item.liter} разархивирована`,
            type: "success"
          })
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loadGroups();
      }
    },

    save(group) {
      withNotifications(async () => {
        if (group.id) {
          await updatePupilGroup(group.id, group);
        } else {
          group.archival = false;
          await createPupilGroup(group);
        }
        this.loadGroups();
      });
    },

    open(item) {
      this.item = item;
      this.editor = true;
    },

    close() {
      this.editor = false;
    }
  }
};
</script>
