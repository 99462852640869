<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn
        :text="!tagModel.id"
        :icon="!!tagModel.id"
        color="primary"
        v-on="on"
        v-bind="attr"
      >
        <v-icon :left="!tagModel.id">{{
          tagModel.id ? "mdi-pencil" : "mdi-plus"
        }}</v-icon>
        {{ tagModel.id ? "" : "Создать" }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ tagModel.id ? "Изменить" : "Создать" }}</v-card-title>

      <v-card-text>
        <v-text-field v-model="tagModel.name" label="Название" required />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="red" @click="close">Отменить</v-btn>

        <v-btn
          v-if="!tagModel.id"
          text
          color="primary"
          @click="createTag"
          :disabled="!tagModel.name"
        >
          Создать
        </v-btn>

        <v-btn v-else text color="primary" @click="updateTag">
          Обновить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangeTagDialog",

  props: {
    tag: { type: Object, default: () => ({}) },
    controllers: { type: Array, default: () => [] }
  },

  data() {
    return {
      show: false,

      tagModel: {
        id: null,
        name: "",
        ...this.tag
      }
    };
  },

  methods: {
    createTag() {
      this.$emit("create", this.tagModel);

      this.tagModel.name = "";
      this.close();
    },

    updateTag() {
      this.$emit("update", this.tagModel);

      this.close();
    },

    close() {
      this.show = false;
    }
  }
};
</script>
