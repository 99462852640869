import { app } from "../main";
import {
  PPS_EDIT_ROUTE,
  STAFF_EDIT_ROUTE,
  USERS_EDIT_ROUTE
} from "../router/names";
import { getUserById } from "../services/user.service";

export async function goToUser(user) {
  if (!user.groups) {
    user = await getUserById(user.id);
  }

  const group = user.mainGroup.name.toLowerCase();

  return app.$router.push({
    name:
      !group || group === "entrant"
        ? USERS_EDIT_ROUTE
        : group === "staff"
        ? STAFF_EDIT_ROUTE
        : PPS_EDIT_ROUTE,
    params: {
      group,
      userId: user.id
    }
  });
}
