<template>
  <div>
    <list-table
      :rules="rules"
      @delete-rule="deleteRule"
      @update-rule="updateRule"
    />
  </div>
</template>

<script>
import {
  createCategoryAccessRules,
  deleteCategoryAccessRules,
  updateCategoryAccessRule
} from "../../services/category.service";
import ListTable from "./ListTable";
import {
  createUserAccessPoints,
  deleteUserAccessPoints,
  updateUserAccessPoint
} from "../../services/user.service";
export default {
  name: "AccessRuleTable",
  components: { ListTable },

  props: {
    rules: Array,
    categoryId: Number,
    userId: Number
  },

  data: () => ({
    accessPoints: []
  }),

  methods: {
    update() {
      return this.$emit("update");
    },

    async createRule(params) {
      try {
        if (this.userId) {
          await createUserAccessPoints(this.userId, params);
        } else {
          await createCategoryAccessRules(this.categoryId, params);
        }

        this.update();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async deleteRule(ruleId) {
      try {
        if (this.userId) {
          await deleteUserAccessPoints(ruleId);
        } else {
          await deleteCategoryAccessRules(ruleId);
        }

        this.update();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateRule(params) {
      try {
        if (this.userId) {
          await updateUserAccessPoint(params);
        } else {
          await updateCategoryAccessRule(params);
        }

        this.update();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
