<template>
  <v-toolbar dense flat class="py-5 mb-4">
    <v-row>
      <v-col>
        <v-menu
          ref="menu"
          :close-on-content-click="false"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="params.date"
              v-bind="attrs"
              v-on="on"
              :clearable="clearableDate"
              prepend-icon="mdi-calendar"
              :label="labelDate"
              readonly
            />
          </template>

          <v-date-picker v-model="params.date" :type="typeDate" />
        </v-menu>
      </v-col>

      <v-col>
        <v-select
          v-model="params.cId"
          :items="dataset"
          item-text="name"
          item-value="id"
          label="Контроллер"
          clearable
        />
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
import { getControllers } from "@/services/device.service";

export default {
  name: "ControllerToolbar",
  props: {
    clearableDate: Boolean,
    selectedType: String,
    params: { type: Object, required: true }
  },

  data() {
    return {
      dataset: []
    };
  },

  computed: {
    labelDate() {
      return this.selectedType === "month" ? "Месяц" : "Дата";
    },

    typeDate() {
      return this.selectedType === "month" ? "month" : "date";
    }
  },

  mounted() {
    this.getControllers();
  },

  watch: {
    params: {
      handler(newParams) {
        this.$emit("update", newParams);
      },
      deep: true
    }
  },

  methods: {
    async getControllers() {
      try {
        const controllers = await getControllers();

        this.dataset = controllers.filter(
          controller => controller.type !== 2 && controller.name
        );
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
