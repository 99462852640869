<template>
  <v-card outlined>
    <v-data-table
      dense
      :headers.sync="headers"
      :items="users"
      :options.sync="tableOptions"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20] }"
      @click:row="editUser"
    >
      <template #item.surname,name,patronymic="{ item }">
        <td>{{ item.surname }} {{ item.name }} {{ item.patronymic }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import {
  STAFF_EDIT_ROUTE,
  USERS_EDIT_ROUTE,
  PPS_EDIT_ROUTE
} from "../../router/names/index";
import { getManagers } from "../../services/user.service";

export default {
  props: {
    category: { type: Object, required: true }
  },

  data: () => ({
    headers: [
      {
        text: "ИД",
        align: "start",
        value: "id"
      },
      {
        text: "ФИО",
        align: "start",
        sortable: true,
        value: "surname,name,patronymic"
      },
      {
        text: "Описание",
        align: "start",
        sortable: true,
        value: "description"
      }
    ],
    tableOptions: {},
    users: []
  }),

  watch: {
    tableOptions: {
      handler() {
        this.loadManagers();
      },
      immediate: true
    }
  },

  methods: {
    async loadManagers() {
      try {
        this.users = await getManagers(this.category.id);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async editUser(item) {
      const [group] = item.groups.map(g => g.toLowerCase());
      const routeName =
        group === "staff"
          ? STAFF_EDIT_ROUTE
          : group === "entrant"
          ? USERS_EDIT_ROUTE
          : PPS_EDIT_ROUTE;

      await this.$router.push({
        name: routeName,
        params: { userId: item.id }
      });
    }
  }
};
</script>
