<template>
  <page>
    <v-tabs v-model="tab">
      <v-tab>Метки</v-tab>

      <v-tab>Точки доступа</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="py-4">
        <tags-list-table
          :group="$route.meta.type"
          :all-tags="allTags"
          @update="getAllTags"
          @archiving="getAllTags"
        />
      </v-tab-item>

      <v-tab-item class="py-4">
        <access-points-table :all-tags="allTags" />
      </v-tab-item>
    </v-tabs-items>
  </page>
</template>

<script>
import Page from "../components/Page";
import TagsListTable from "../components/tags/TagsListTable";
import AccessPointsTable from "../components/tags/AccessPointsTable";
import { getTags } from "../services/tags.service";
export default {
  name: "TagsList",
  components: { AccessPointsTable, TagsListTable, Page },

  data() {
    return {
      tab: null,

      allTags: []
    };
  },

  created() {
    this.getAllTags();
  },

  methods: {
    async getAllTags(archival) {
      try {
        this.allTags = await getTags({ archival: archival || false });

        this.allTags.sort((a, b) => (a.name > b.name ? 1 : -1));
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
