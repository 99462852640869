import { Api } from "./api.service";
import qs from "qs";

export function getControllersArchival() {
  return Api.get("/access/acs/controller", {
    params: { archival: true }
  });
}

export function createRequest(params) {
  return Api.post(
    `/access/acs/controller/${params.controllerId}/request-event`,
    params
  );
}

export function getControllers(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/controller${query && "?" + query}`);
}

export function getControllersEvents(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/event${query && "?" + query}`);
}

export function getDiscipline(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/stat/discipline${query && "?" + query}`);
}

export function getControllersStatistics(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/stat/event/day${query && "?" + query}`);
}

export function getControllersStatisticsMonth(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/stat/event/month${query && "?" + query}`);
}

export function getLostPeriod(params) {
  const query = qs.stringify(params);
  return Api.get(`/access/acs/stat/lost-period${query && "?" + query}`);
}

export function getListControllersTypes() {
  return Api.post(`/access/acs/controller-types`);
}

export function createController(params) {
  return Api.post("/access/acs/controller", params);
}

export function sendToArchive(id) {
  return Api.delete(`/access/acs/controller/${id}`);
}

export function updateController(params) {
  return Api.put("/access/acs/controller", params);
}

export function getAccessPointController() {
  return Api.get("/access/point");
}

export function getTags(id) {
  return Api.get(`/access/point/${id}/tags`);
}

export function updateTags(id, params) {
  return Api.put(`/access/point/${id}/tags`, params);
}
