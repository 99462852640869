<template>
  <div>
    <v-card-title class="justify-space-between align-center d-flex">
      {{ dateFormat }}

      <div class="d-flex align-center">
        <v-switch
          v-model="news.published"
          label="Опубликовано"
          dense
          hide-details
          class="ma-0 ml-4"
          @change="togglePublishNews"
        />

        <v-switch
          v-model="news.archival"
          label="В архиве"
          dense
          class="ma-0 ml-4"
          hide-details
          @change="toggleNewsArchive"
        />
      </div>
    </v-card-title>

    <v-card-text>
      <h3 class="mb-2 font-weight-regular">
        {{ news.text }}
      </h3>

      <v-chip-group multiple class="mb-4" dark>
        <v-chip
          v-for="category in news.categories"
          :key="category.id"
          small
          disabled
        >
          {{ category.name }}
        </v-chip>

        <v-chip v-for="course in news.courses" :key="course" small disabled>
          {{ course }} курс
        </v-chip>
      </v-chip-group>

      <news-editor @update="$emit('update')" :news="news" is-edit />
      <voting-editor
        :news-id="news.id"
        @update="$emit('update')"
        :voting="news.voting"
      />

      <voting v-if="news.voting" :voting="news.voting" class="my-4" />
    </v-card-text>
  </div>
</template>

<script>
import { deleteNews, updateNews } from "../../services/news.service";
import Voting from "./Voting";
import NewsEditor from "./NewsEditor";
import VotingEditor from "./VotingEditor";

export default {
  name: "NewsCard",
  components: { VotingEditor, NewsEditor, Voting },

  props: {
    news: { required: true, type: Object },
    categories: Array
  },

  computed: {
    dateFormat() {
      return new Date(this.news.date).toLocaleString("ru-RU", {
        weekday: "short",
        day: "2-digit",
        month: "long",
        year: "numeric"
      });
    }
  },

  methods: {
    toggleNewsArchive() {
      return this.news.archival
        ? this.sendNewsToArchive()
        : this.removeNewsFromArchive();
    },

    togglePublishNews(value) {
      updateNews({ ...this.news, published: value });
    },

    async sendNewsToArchive() {
      try {
        await deleteNews(this.news.id);

        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async removeNewsFromArchive() {
      try {
        await updateNews(this.news);
        this.news.archival = false;

        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
