<template>
  <v-card outlined class="my-4">
    <v-card-title>Найденные пользователи</v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="foundUsers.content"
        item-key="id"
        :loading="loading"
        :options.sync="foundUsersOptions"
        :server-items-length="foundUsers && foundUsers.totalElements"
        :footer-props="{ itemsPerPageOptions: [5, 10, 20] }"
        @click:row="editUser"
        dense
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import { findUser } from "../../../services/user.service";

export default {
  name: "FoundedUsersListTable",
  props: {
    creatingUser: { required: true, type: Object, default: () => {} }
  },

  data() {
    return {
      headers: [
        { text: "ИД", value: "id" },
        { text: "Фамилия", value: "surname" },
        { text: "Имя", value: "name" },
        { text: "Отчество", value: "patronymic" },
        { text: "Описание", value: "description" }
      ],

      foundUsers: {},
      foundUsersOptions: {},
      pagination: {
        page: 0,
        size: 0
      },

      loading: false
    };
  },

  watch: {
    foundUsersOptions: {
      handler(newOptions) {
        this.pagination.page = newOptions.page - 1;
        this.pagination.size = newOptions.itemsPerPage;

        this.findUser();
      }
    },

    creatingUser: {
      handler() {
        this.findUser();
      }
    }
  },

  methods: {
    findUser: debounce(async function() {
      try {
        let newUser = null;
        if (!!this.creatingUser.surname || !!this.creatingUser.name) {
          newUser = Object.values(this.creatingUser).join(" ");

          this.foundUsers = await findUser({
            search: newUser,
            page: this.pagination.page,
            size: this.pagination.size,
            userGroup: "null"
          });
        } else {
          this.foundUsers = {};
        }
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }, 500),

    async editUser(item) {
      const [group] = item.groups.map(g => g.toUpperCase());

      await this.$router.push({
        name: `${group === "ENTRANT" ? "USERS" : group}_EDIT_ROUTE`,
        params: { userId: item.id }
      });
    }
  }
};
</script>
