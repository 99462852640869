<template>
  <v-dialog v-model="show" @keydown.esc="close" max-width="640">
    <template #activator="{on, attr}">
      <v-btn text color="primary" v-on="on" v-bind="attr">
        <v-icon left>mdi-plus</v-icon> Добавить
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Новая специальность</v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model.trim="specialityModel.code"
                label="Код"
                autofocus
                required
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="specialityModel.category"
                :items="categories"
                item-text="name"
                return-object
                dense
                label="Факультет"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="specialityModel.direction"
                dense
                label="Направление"
                :items="directions"
                :disabled="!specialityModel.category"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="specialityModel.profile"
                dense
                label="Профиль"
                :items="profiles"
                :disabled="!specialityModel.category"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="specialityModel.qualification"
                dense
                label="Квалификация"
                :items="qualifications"
              />
            </v-col>
          </v-row>

          <v-row>
            <div class="col-3 d-flex align-end mb-6">Срок обучения:</div>
            <v-col>
              <v-text-field
                v-model.trim="duration.year"
                label="Годы"
                required
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.trim="duration.month"
                label="Месяцы"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="close">
          Отмена
        </v-btn>
        <v-btn type="submit" color="primary" text @click="create">
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createSpeciality,
  getCategoryByType,
  getSpecialityListByType
} from "../../services/category.service";
import { formatDurationToMonth } from "@/utils/date-format";

export default {
  name: "CreateSpecialityDialog",

  data: () => ({
    show: false,
    valid: false,

    specialityModel: {
      archival: false,
      qualification: null,
      category: null,
      profile: null,
      direction: null,
      duration: null
    },

    duration: {},
    categories: [],
    profiles: [],
    qualifications: [],
    directions: []
  }),

  async mounted() {
    this.categories = await this.getCategoryByType("ENTRANT");
    this.qualifications = await this.getSpecialityListByType("qualification");
  },

  watch: {
    "specialityModel.category": {
      async handler(value) {
        if (value) {
          this.profiles = await this.getSpecialityListByType("profile", {
            categoryId: value.id
          });
          this.directions = await this.getSpecialityListByType("direction", {
            categoryId: value.id
          });
        }
      }
    }
  },

  methods: {
    getCategoryByType,
    getSpecialityListByType,

    async create() {
      try {
        this.specialityModel.duration = formatDurationToMonth(
          this.duration.year,
          this.duration.month
        );

        await createSpeciality(this.specialityModel);
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.close();
      }
    },

    close() {
      this.specialityModel = {};
      this.duration = {};
      this.show = false;
    }
  }
};
</script>
