export const hexToIdentifier = hex => {
  return hexToInt(
    hex
      .match(/.{2}/g)
      .reverse()
      .join("")
  );
};

export const hexToInt = hex => {
  return parseInt("0x" + hex);
};
