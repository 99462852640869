<template>
  <v-card-title class="font-weight-regular">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="exam.date"
          label="Дата"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          class="col-2 mb-3"
        />
      </template>

      <v-date-picker v-model="exam.date" />
    </v-menu>

    <v-select
      v-model="exam.state.name"
      :items="states"
      item-text="description"
      item-value="name"
      label="Статус"
      class="col-10"
      dense
    />

    <v-text-field
      v-model="exam.specialityCode"
      label="Код специальности"
      class="col-2"
      dense
    />

    <v-text-field
      v-model="exam.specialityTitle"
      label="Название специальности"
      class="col-10"
      dense
    />

    <v-text-field
      v-model="exam.discipline"
      label="Дисциплина"
      prepend-icon="mdi-teach"
      class="col-12"
      dense
    />

    <v-select
      v-model="exam.type"
      :items="typeExam"
      item-text="description"
      item-value="name"
      label="Тип"
      prepend-icon="mdi-bookshelf"
      class="col-12"
      dense
    />

    <v-text-field
      v-model="exam.specialityQualification"
      label="Квалификация"
      class="col-2"
      dense
    />

    <v-text-field
      v-model="exam.categoryTitle"
      label="Факультет"
      class="col-10"
      dense
    />

    <v-text-field v-model="exam.courseTitle" label="Курс" class="col-2" dense />

    <v-text-field
      v-model="exam.groupTitle"
      label="Группа"
      class="col-2"
      dense
    />

    <v-spacer />

    <v-btn text @click="$emit('download', exam)">
      Скачать отчёт(.docx)
    </v-btn>

    <v-btn color="primary" text @click="updateExamSheet(exam)">
      Сохранить
    </v-btn>

    <v-btn color="red" text @click="updateExamSheet(exam.id)">
      Удалить
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: "ExamCardTitle",
  props: {
    exam: { type: Object, required: true },
    states: { type: Array, required: true }
  },

  data: () => ({
    typeExam: [
      { name: "credit", description: "Зачёт" },
      { name: "exam", description: "Экзамен" }
    ],

    menu: false
  }),

  methods: {
    updateExamSheet(exam) {
      this.$emit("update", exam);
    }
  }
};
</script>
