<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-dialog v-model="editor" max-width="480">
        <template #activator="{ on, attr }">
          <v-btn
            text
            color="primary"
            v-bind="attr"
            v-on="on"
            @click="onEdit({})"
          >
            <v-icon left>mdi-plus</v-icon>
            <span>Создать</span>
          </v-btn>
        </template>

        <event-editor
          v-model="item"
          @submit="onSubmit"
          @close="onEditorClose"
        ></event-editor>
      </v-dialog>
    </v-toolbar>

    <v-divider />

    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      @click:row="onRowClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn @click.stop="onArchival(item)" icon>
          <v-icon
            v-if="item.archival"
            color="green darken-1"
            title="Восстановить из архива"
          >
            mdi-archive-arrow-up
          </v-icon>
          <v-icon v-else color="red darken-1" title="Отправить в архив">
            mdi-archive-arrow-down
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import EventEditor from "@/components/events/EventEditor.vue";
import { EVENT_ROUTE } from "@/router/names";
import * as EventsService from "@/services/events.service.js";

export default {
  components: { EventEditor },

  data() {
    return {
      headers: [
        { text: "ИД", value: "id" },
        { text: "Название", value: "name" },
        { text: "Начало", value: "beginDate" },
        { text: "Конец", value: "endDate" },
        { text: "Действия", value: "actions" }
      ],
      items: [],

      item: {},

      loading: false,
      editor: false
    };
  },

  created() {
    this.get();
  },

  methods: {
    onRowClick(row) {
      this.$router.push({
        name: EVENT_ROUTE,
        params: {
          eventId: row.id
        }
      });
    },

    onEdit(item) {
      this.item = item;
      this.editor = true;
    },

    onEditorClose() {
      this.editor = false;
    },

    async onSubmit(item) {
      await this.save(item);
      this.get();
    },

    async onArchival(item) {
      await this.save({ ...item, archival: !item.archival });
      this.get();
    },

    async get() {
      try {
        this.loading = true;
        this.items = await EventsService.get();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    async save(item) {
      try {
        await EventsService.save(item);
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    }
  }
};
</script>
