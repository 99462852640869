<template>
  <v-dialog v-model="show" width="640">
    <template #activator="{ attr, on }">
      <v-btn v-bind="attr" v-on="on" :icon="!!controller" text color="primary">
        <v-icon :left="!controller">
          {{ buttonIcon }}
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ controllerCardTitle }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="controllerToken" label="Токен" autofocus />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-combobox
                v-model="controllerAccessPoint"
                :items="accessPoints"
                item-text="name"
                item-value="id"
                label="Точка доступа"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="controllerType"
                :items="controllersTypes"
                item-text="name"
                item-value="id"
                label="Тип"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="red" text @click="close">
          Отмена
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="createOrUpdateController"
          :disabled="!isValid"
        >
          {{ controllerAction }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createController,
  getAccessPointController,
  updateController,
  getListControllersTypes
} from "@/services/device.service";

export default {
  name: "ControllerDialog",
  props: {
    controller: { type: Object }
  },

  data() {
    return {
      show: false,
      params: {
        type: this.controller ? this.controller.type : null,
        token: this.controller ? this.controller.token : "",
        accessPoint: this.controller ? this.controller.accessPoint : null
      },
      accessPoints: [],
      controllersTypes: []
    };
  },

  mounted() {
    this.getAccessPoint();
    this.getControllersTypes();
  },

  computed: {
    controllerCardTitle() {
      return this.controller ? this.controller.name : "Новый контроллер";
    },

    controllerType: {
      get() {
        return this.controller ? this.controller.type : this.params.type;
      },

      set(value) {
        this.params.type = value;
      }
    },

    controllerToken: {
      get() {
        return this.controller ? this.controller.token : this.params.token;
      },

      set(value) {
        this.params.token = value;
      }
    },

    controllerAccessPoint: {
      get() {
        return this.controller
          ? this.controller.accessPoint.name
          : this.params.accessPoint;
      },

      set(value) {
        this.params.accessPoint = value;
      }
    },

    buttonIcon() {
      return this.controller ? "mdi-pencil" : "mdi-plus";
    },

    buttonText() {
      return this.controller ? null : "Добавить";
    },

    controllerAction() {
      return this.controller ? "Обновить" : "Создать";
    },

    isValid() {
      return this.params.token !== "" && this.params.accessPoint !== null;
    }
  },

  methods: {
    async getControllersTypes() {
      try {
        this.controllersTypes = await getListControllersTypes();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async getAccessPoint() {
      try {
        this.accessPoints = await getAccessPointController();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    createOrUpdateController() {
      this.controller ? this.updateController() : this.createController();
    },

    async createController() {
      try {
        await createController(this.params);
        this.params.accessPoint = null;
        this.params.token = "";
        this.params.type = null;

        this.close();
        this.$emit("update");
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    async updateController() {
      try {
        this.params.id = this.controller.id;
        this.params.archival = this.controller.archival;

        await updateController(this.params);

        this.$emit("update");
        this.close();
      } catch (e) {
        this.$notify({
          text: e.response ? e.response.data.message : e,
          type: "error"
        });
      }
    },

    close() {
      this.show = false;
    }
  }
};
</script>
